<template>
    <div @click="navigateToBranch"
        class="relative bg-black rounded-lg shadow-md text-white p-4 flex flex-col min-w-[270px] h-[400px] cursor-pointer hover:shadow-lg transition-shadow duration-300 mb-4 overflow-hidden">
        <div class="absolute inset-x-0 top-0 h-1/2 bg-cover bg-center"
            :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
        <div class="absolute inset-x-0 top-0 h-[60%] bg-gradient-overlay"></div>
        <h3 class="relative z-10 text-2xl font-black font-lato mb-2">{{ branch.name }}</h3>
        <div v-if="branch.data" class="absolute inset-x-4 bottom-4 z-20">
            <div class="grid grid-cols-2 gap-2">
                <div v-for="(stat, index) in branchStats" :key="index"
                    class="flex flex-col bg-neutral-800 bg-opacity-100 p-2 rounded-lg">
                    <span class="text-lg font-black font-lato">
                        <span v-if="stat.label == 'YTD Seeded' || stat.label == 'YTD Outplanted' && stat.value > 0">
                            <span class="mdi mdi-trending-up"> </span>
                            {{ stat.value }}
                        </span>
                        <span v-else>{{ stat.value }}</span>
                    </span>
                    <span class="text-xs text-neutral-400">{{ stat.label }}</span>
                </div>
            </div>
        </div>
        <div v-else class="absolute inset-x-4 bottom-4 z-20 border border-neutral-700 p-1 rounded-lg">
            <p class="text-center text-neutral-400"><span class="mdi mdi-alert-circle-outline mr-1"></span>Data Explorer unreachable</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BranchCard',
    props: ['branch'],
    computed: {
        backgroundImage() {
            try {
                return require(`@/assets/images/branch_${this.branch.id.toLowerCase()}.png`);
            } catch (e) {
                console.warn(`Image not found for branch ${this.branch.id}. Using default.`);
                return require('@/assets/images/branch_moorea.png');
            }
        },
        branchStats() {
            if (!this.branch.data) return [];
            return [
                { label: 'Nurseries', value: this.branch.data.Nurseries },
                { label: 'Outplant Sites', value: this.branch.data.Outplants },
                { label: 'In nurseries', value: this.branch.data.Fragments.toLocaleString() },
                { label: 'Outplanted', value: this.branch.data.Outplanted.toLocaleString() },
                { label: 'YTD Seeded', value: this.branch.data['YTD Seeded'].toLocaleString() },
                { label: 'YTD Outplanted', value: this.branch.data['YTD Outplanted'].toLocaleString() }
            ];
        }
    },
    methods: {
        navigateToBranch() {
            this.$router.push({
                name: 'Branch',
                params: {
                    branchId: this.branch.id,
                }
            });
        }
    }
}
</script>

<style scoped>
.bg-gradient-overlay {
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 50%,
            rgba(0, 0, 0, 0.8) 75%,
            rgba(0, 0, 0, 1) 100%);
}
</style>