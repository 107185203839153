<template>
  <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-[350px]">
    <div class="flex items-center mb-2 justify-between">
      <i class="mdi mdi-volume-high text-2xl"></i>
      <select v-model="selectedAudio" class="bg-neutral-700 text-white py-2 px-4 rounded text-xs ml-auto">
        <option value="audio">{{ formattedTimestamp }}</option>
      </select>
    </div>
    <div class="flex-grow relative overflow-hidden">
      <div class="w-full relative flex flex-col h-full" ref="parentDiv">
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
          <button @click="toggleAudio" class="w-8 h-8 bg-white bg-opacity-60 hover:bg-opacity-100 rounded-full flex items-center justify-center transition-colors duration-300">
            <i :class="['mdi', isPlaying ? 'mdi-restart' : 'mdi-play', 'text-xl']"></i>
          </button>
        </div>
        <canvas ref="canvas" :width="canvasWidth" height="35"></canvas>
        <canvas ref="spectrogramCanvas" :width="canvasWidth" class="rounded flex-grow h-full"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceAudio',
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedAudio: 'audio',
      audioContext: null,
      audioBuffer: null,
      audioSource: null,
      analyser: null,
      animationFrameId: null,
      isPlaying: false,
      canvasWidth: 400,
      duration: 0,
      startTime: 0,
      currentTrackTime: 0,
      spectrogramData: [],
    };
  },
  computed: {
    currentAudioUrl() {
      return this.device.audio;
    },
    formattedTimestamp() {
      return this.formatDate(this.device.audio_time);
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateCanvasWidth);
    this.initAudio();
    this.updateCanvasWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCanvasWidth);
    this.resetAudio();
  },
  methods: {
    async updateCanvasWidth() {
        if (this.$refs.parentDiv) {
            this.canvasWidth = this.$refs.parentDiv.offsetWidth;
        }
        await this.$nextTick();
    },
    initAudio() {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 128;
        this.analyser.connect(this.audioContext.destination);
        this.loadAudio(this.currentAudioUrl);
    },
    async loadAudio(url) {
        this.resetAudio();
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        this.audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
        this.precomputeSpectrogram();
        this.drawInitialWaveform(); // Add this line
    },
    initAudioSource() {
        this.audioSource = this.audioContext.createBufferSource();
        this.audioSource.buffer = this.audioBuffer;
        this.audioSource.connect(this.analyser);
        this.audioSource.onended = () => {
            console.log('onended')
            this.resetAudio();
        };
    },
    resetAudio() {
        if (this.isPlaying) {
            this.audioSource.stop();
            this.isPlaying = false;
        }
        cancelAnimationFrame(this.animationFrameId); // Cancel any pending animation frames
        this.audioContext.suspend();
        this.drawWaveform(this.$refs.canvas.getContext("2d"));
        this.drawSpectrogram(0);
        this.initAudioSource();
    },
    playAudio() {
        if (this.isPlaying) return;
        this.isPlaying = true;
        this.audioSource = this.audioContext.createBufferSource();
        this.audioSource.buffer = this.audioBuffer;
        this.duration = this.audioBuffer.duration;

        this.audioSource.connect(this.analyser);
        this.startTime = this.audioContext.currentTime;
        this.audioSource.start();

        // Resume AudioContext if needed
        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
        }

        // Start animating the waveform
        this.animateWaveform();
    },
    toggleAudio() {
        if (this.isPlaying) {
            this.resetAudio();
        } else {
            this.playAudio();
        }
    },
    drawWaveform(canvasContext) {
        // Initialize canvas dimensions
        const canvasWidth = canvasContext.canvas.width;
        const canvasHeight = canvasContext.canvas.height;

        // Clear previous drawing
        canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);

        // Draw a flat line in the middle of the canvas
        canvasContext.lineWidth = 2;
        canvasContext.strokeStyle = "#27BDF4";
        canvasContext.beginPath();
        canvasContext.moveTo(0, canvasHeight / 2);
        canvasContext.lineTo(canvasWidth, canvasHeight / 2);
        canvasContext.stroke();

        // Reset the vertical line to the start
        canvasContext.strokeStyle = "#FF0000";  // Set the line color to red
        canvasContext.beginPath();
        canvasContext.moveTo(0, 0);
        canvasContext.lineTo(0, canvasHeight);  // Draw line for the entire canvas height
        canvasContext.stroke();
    },
    animateWaveform() {
        const canvasContext = this.$refs.canvas.getContext("2d");

        // Initialize canvas dimensions
        const canvasWidth = canvasContext.canvas.width;
        const canvasHeight = canvasContext.canvas.height;

        // Create a data array
        const dataArray = new Uint8Array(this.analyser.frequencyBinCount);

        // Get frequency data
        // this.analyser.getByteTimeDomainData(dataArray);
        this.analyser.getByteFrequencyData(dataArray);

        // Clear previous drawing
        canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);

        // Setup canvas and line styles
        canvasContext.lineWidth = 2; // Line width
        canvasContext.strokeStyle = "#27BDF4"; // Line color
        canvasContext.beginPath();

        const step = ~~canvasWidth / 2.0 / dataArray.length;
        let x = 0;

        const drawLine = () => {
            let y = 0;
            for (let i = 0; i < dataArray.length; i++) {
                const v = dataArray[i];
                y = (canvasHeight * (255 - v)) / 510;
                if (i % 2) y = canvasHeight - y;

                let nextV = dataArray[i + 1] || dataArray[i];
                let nextY = (canvasHeight * (255 - nextV)) / 510;
                if (i % 2) nextY = canvasHeight - nextY;
                canvasContext.lineTo(x, y);
                x += step;
            }
            return x;
        };

        dataArray.reverse();
        canvasContext.moveTo(x, canvasHeight / 2);
        x = drawLine();

        dataArray.reverse();
        drawLine();
        canvasContext.lineTo(canvasWidth, canvasHeight / 2);
        canvasContext.stroke();

        // Draw progress line
        const progressFraction = (this.audioContext.currentTime - this.startTime) / this.duration;
        this.currentTrackTime = (this.audioContext.currentTime - this.startTime).toFixed(2);
        const progressX = progressFraction * this.canvasWidth;

        canvasContext.strokeStyle = "#FF0000";  // Set the line color to red (or any other color)
        canvasContext.beginPath();
        canvasContext.moveTo(progressX, 0);
        canvasContext.lineTo(progressX, 35);  // Assuming the canvas height is 35 as per your template
        canvasContext.stroke();

        // Draw time counter text
        canvasContext.fillStyle = "#FF0000";  // Set the text color to red
        canvasContext.font = "12px Arial";
        canvasContext.fillText(this.currentTrackTime + "s", progressX + 5, 12);

        // Update spectrogram with progress line
        this.drawSpectrogram(progressX);

        // Continue animation
        this.animationFrameId = requestAnimationFrame(this.animateWaveform);
    },
    async precomputeSpectrogram() {
        const offlineContext = new OfflineAudioContext(1, this.audioBuffer.length, this.audioBuffer.sampleRate);
        const source = offlineContext.createBufferSource();
        source.buffer = this.audioBuffer;

        const analyser = offlineContext.createAnalyser();
        analyser.fftSize = 256;
        source.connect(analyser);
        analyser.connect(offlineContext.destination);

        source.start();

        const renderedBuffer = await offlineContext.startRendering();
        const width = this.$refs.spectrogramCanvas.width;
        const step = Math.floor(renderedBuffer.length / width);
        this.spectrogramData = Array.from({ length: width }, () => new Uint8Array(analyser.frequencyBinCount));

        for (let i = 0; i < width; i++) {
            const slice = renderedBuffer.getChannelData(0).subarray(i * step, (i + 1) * step);
            const tempContext = new OfflineAudioContext(1, slice.length, offlineContext.sampleRate);
            const tempBuffer = tempContext.createBuffer(1, slice.length, tempContext.sampleRate);
            tempBuffer.copyToChannel(slice, 0);

            const tempSource = tempContext.createBufferSource();
            tempSource.buffer = tempBuffer;

            const tempAnalyser = tempContext.createAnalyser();
            tempAnalyser.fftSize = 256;
            tempSource.connect(tempAnalyser);
            tempAnalyser.connect(tempContext.destination);

            tempSource.start();
            await tempContext.startRendering();
            tempAnalyser.getByteFrequencyData(this.spectrogramData[i]);
        }

        this.drawSpectrogram();
    },
    drawSpectrogram(progressX) {
        const canvasContext = this.$refs.spectrogramCanvas.getContext("2d");
        const canvasWidth = canvasContext.canvas.width;
        const canvasHeight = canvasContext.canvas.height;

        // Clear previous drawing
        canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);

        for (let x = 0; x < this.spectrogramData.length; x++) {
            for (let y = 0; y < this.spectrogramData[x].length; y++) {
                const value = this.spectrogramData[x][y];

                // Map value to custom color
                const percent = value / 255;

                let r, g, b;
                if (percent < 0.5) {
                    // Interpolate between black and #27bdf4
                    r = 39 * (percent * 2);
                    g = 189 * (percent * 2);
                    b = 244 * (percent * 2);
                } else {
                    // Interpolate between #27bdf4 and white
                    r = 39 + (216 * ((percent - 0.5) * 2));
                    g = 189 + (66 * ((percent - 0.5) * 2));
                    b = 244 + (11 * ((percent - 0.5) * 2));
                }

                canvasContext.fillStyle = `rgb(${r},${g},${b})`;
                canvasContext.fillRect(x, canvasHeight - y, 1, 1);
            }
        }

        // Draw progress line
        canvasContext.lineWidth = 2;
        canvasContext.strokeStyle = "#FF0000";  // Set the line color to red
        canvasContext.beginPath();
        canvasContext.moveTo(progressX, 0);
        canvasContext.lineTo(progressX, canvasHeight);
        canvasContext.stroke();
    },
    drawInitialWaveform() {
      const canvasContext = this.$refs.canvas.getContext("2d");
      const canvasWidth = canvasContext.canvas.width;
      const canvasHeight = canvasContext.canvas.height;

      // Clear the canvas
      canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);

      // Set up the canvas style
      canvasContext.lineWidth = 2;
      canvasContext.strokeStyle = "#27BDF4";

      // Get the audio data
      const channelData = this.audioBuffer.getChannelData(0);
      const step = Math.ceil(channelData.length / canvasWidth);

      // Start the path
      canvasContext.beginPath();
      canvasContext.moveTo(0, canvasHeight / 2);

      // Draw the waveform
      for (let i = 0; i < canvasWidth; i++) {
        const dataIndex = i * step;
        const value = channelData[dataIndex];
        const y = (value + 1) * canvasHeight / 2;
        canvasContext.lineTo(i, y);
      }

      // Stroke the path
      canvasContext.stroke();
    },
    formatDate(timestamp) {
      if (!timestamp) return 'N/A';
      const date = timestamp.toDate();
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    }
  },
  watch: {
    currentAudioUrl() {
      this.loadAudio(this.currentAudioUrl);
    }
  }
};
</script>

<style scoped>
/* Ensure the spectrogram canvas takes up the available vertical space */
.spectrogramCanvas {
  height: 100%;
}
</style>
