<template>
    <div class="add-new-card" @click="handleClick">
        <div class="plus-button">+</div>
        <span>Add New</span>
    </div>
</template>

<script>
export default {
    name: 'AddNewCard',
    methods: {
        handleClick() {
            this.$emit('card-click');
        }
    }
}
</script>

<style scoped>
.add-new-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dotted #ccc;
    border-radius: 8px;
    background-color: transparent;
    height: 100%;
    cursor: pointer;
    text-align: center;
    min-height: 275px;
}

.plus-button {
    font-size: 2rem;
    background-color: black;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}
</style>