<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col min-w-[200px] min-h-[300px] cursor-pointer hover:shadow-lg transition-shadow duration-300"
        @click="navigateToControlSite">
        <div class="flex justify-between items-start mb-8">
            <h3 class="text-lg font-black font-lato max-w-[150px]">{{ site.name }}</h3>
            <div class="badge-container">
                <div class="badge font-black font-lato text-black text-sm px-2 py-1 rounded-full bg-neutral-300">
                    {{ area }}
                </div>
            </div>
        </div>
        <div v-if="site.data" class="grid grid-cols-2 gap-2">
            
        </div>
        <div v-else class="mt-auto border border-neutral-700 p-1 rounded-lg">
            <p class="text-center text-neutral-400">
                <span class="mdi mdi-alert-circle-outline mr-1"></span>Data Explorer unreachable
            </p>
        </div>
    </div>
</template>

<script>
import { polygon, area } from '@turf/turf';

export default {
    name: 'ControlSiteCard',
    props: {
        branchId: {
            type: String,
            required: true
        },
        site: {
            type: Object,
            required: true
        }
    },
    computed: {
        siteStats() {
            if (!this.site.data) return [];
            return [
                { label: 'Corals', value: this.site.data.fragments.toLocaleString() },
                { label: 'Species', value: this.site.data.species.toLocaleString() },
                { label: 'Cells', value: this.site.data.cells.toLocaleString() },
                { label: 'Corals/Cell', value: Math.ceil(this.site.data.corals_per_cell) },
                { label: 'Species/Cell', value: Math.ceil(this.site.data.species_per_cell) },
                { label: 'Last Survey', value: this.formatDate(this.site.data.last_survey) },
            ];
        },
        area() {
            if (!this.site.perimeter) return '0 m²';
            if (this.site.perimeter.length < 3) return '0 m²';
            const coordinates = this.site.perimeter.map(point => [point.longitude, point.latitude]);

            // Ensure the polygon is closed
            if (coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
                coordinates[0][1] !== coordinates[coordinates.length - 1][1]) {
                coordinates.push(coordinates[0]);
            }

            const poly = polygon([coordinates]);
            const areaInSquareMeters = area(poly);

            return Number(areaInSquareMeters.toFixed(0)).toLocaleString() + ' m²';
        }
    },
    methods: {
        navigateToControlSite() {
            this.$router.push({
                name: 'ControlSite',
                params: {
                    branchId: this.branchId,
                    controlSiteId: this.site.id,
                }
            });
        },
        formatDate(dateString) {
            if (!dateString) return 'N/A';
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        }
    }
};
</script>

<style scoped>
.badge-container {
    position: relative;
    display: inline-block;
}

.badge {
    position: relative;
    z-index: 1;
}
</style>