export default [
    {
        class: 'fish',
        name: 'Fish',
        group: 'Other',
        color: '#FFD60A',
        description: 'General fish',
        image: require('@/assets/fish/Common parrotfish.png')
    },
    {
        class: 'surgeonfish',
        name: 'Surgeonfish',
        group: 'Cropping Herbivore',
        color: '#FFD60A',
        description: 'Surgeonfish have a disc-shaped body and downward pointing mouths, and a very distinctive body shape. Note the end of their tails are concave. This is very useful to distinguish them from tangs, which look similar, but have a different tail.',
        image: require('@/assets/fish/Brown surgeonfish.png')
    },
    {
        class: 'brown_tang',
        name: 'Tang',
        group: 'Concealed Cropping Herbivore',
        color: '#FF60A',
        description: 'Tangs are similar to surgeonfish, but they have a bit of a snout, and their tails are convex. Brown tangs also have a distinctive white spine on the base of their tails, which is very useful to identify them.',
        image: require('@/assets/fish/Brown tang.png')
    },
    {
        class: 'unicornfish',
        name: 'Unicornfish',
        group: 'Invertivores',
        description: 'Unicornfish are similar to surgeons but have mouths that stick out a bit and tails that are shaped differently than the concave tail of the surgeonfish. Unicornfish tails have longer edges which are useful to identify them. Some unicornfish have a spine sticking out between their eyes (like a unicorn).',
        image: require('@/assets/fish/Orangespine unicornfish.png')
    },
    {
        class: 'parrotfish',
        name: 'Parrotfish',
        group: 'Scraping and Excavating Herbivore',
        color: '#FB5607',
        description: 'Parrotfish have a distinct long shape and beak-like mouths.Their heads are rounded (compared to the more pointy face of wrasses which have similar long body shape).',
        image: require('@/assets/fish/Common parrotfish.png')
    },
    {
        class: 'sea-cucumber',
        name: 'Sea cucumbers',
        group: 'Other',
        description: 'Filter sand/sediment and more likely to find on the sand, but interesting to note if we see them with the camera.',
        image: require('@/assets/fish/Sea cucumber.png')
    },
    {
        class: 'black-tip-reef-shark',
        name: 'Black tip reef sharks',
        group: 'Other',
        description: 'May swim by and would be good to see and record.',
        image: require('@/assets/fish/Shark.png')
    },
    {
        class: 'butterflyfish',
        name: 'Butterflyfish',
        group: 'Corallivore',
        description: 'Butterflyfish have mouths specialized to eat corals that are adapted to pluck individual polyps off the reef.',
        image: require('@/assets/fish/Ornate butterflyfish.png')
    },
    {
        class: 'cushion_starfish',
        name: 'Pincushion Stars',
        group: 'Other',
        description: 'Pincushion stars climb on corals and digest the tissue from the surface of the coral. They look round-ish, not very much like other sea stars.',
        image: require('@/assets/fish/Pacific cushion seastar.png')
    },
    {
        class: 'crown-of-thorn-sea-star',
        name: 'Crown-of-thorn sea stars',
        group: 'Other',
        description: 'Crown-of-thorn sea stars also climb on corals and eat their tissues. These sea stars can be very large with many legs with prominent venomous spines. They can move very fast if they want to.',
        image: require('@/assets/fish/Crown of thorns seastar.png')
    },
    {
        class: 'triggerfish',
        name: 'Triggerfish',
        group: 'Invertivores',
        description: 'Triggerfish have a distinctive body shape, and tail, which is either fanned out, or compact and streamlined when moving - as pictured.',
        image: require('@/assets/fish/Orange-lined triggerfish.png')
    },
    {
        class: 'angelfish',
        name: 'Angelfish',
        group: 'Invertivores',
        description: 'Angelfish have disk shaped bodies with a rounded tail. Their dorsal and anal fins extend back beyond their bodies, almost to their tails. Some Angelfish have long showy fins, but the ones we see most often in Moorea have smaller fins like this.',
        image: require('@/assets/fish/Lemonpeel angelfish.png')
    },
    {
        class: 'farmerfish',
        name: 'Farmerfish',
        group: 'Invertivores',
        description: 'Farmer fish are common on lagoon reefs. They have a distinctive body shape that includes a lobed anal fin and rounded bifurcated tail.',
        image: require('@/assets/fish/Dusky farmerfish.png')
    },
    {
        class: 'wrasse',
        name: 'Wrasse',
        group: 'Invertivores',
        description: 'Wrasses are active predators that find and eat invertebrates and other fishes they find in algae, the reef, and the sand. They are curious, fast swimmers who often follow larger fish. Wrasse bodies are long, like parrotfish, but their faces are pointy.',
        image: require('@/assets/fish/Sixbar wrasse.png')
    },
    {
        class: 'pufferfish',
        name: 'Pufferfish',
        group: 'Invertivores',
        description: 'These slow-moving fish swim with pectoral fins that are often difficult to see, so they sometimes look like they are floating along. They have fused teeth adapted to crush hard shells of crustaceans and molluscs. Pufferfish have a distinctive body shape that makes them easy to identify.',
        image: require('@/assets/fish/Golden puffer.png')
    },
    {
        class: 'chromis',
        name: 'Chromis',
        group: 'Invertivores',
        description: 'Usually beautifully colored, these fish are often in groups and actively feed from the water column. They hide in coral branches when threatened, and can often be seen emerging from their coral in a cloud-like group.',
        image: require('@/assets/fish/Bicolor chromis.png')
    },
    {
        class: 'octopus',
        name: 'Octopuses',
        group: 'Other',
        description: 'Octopuses have dens and areas they operate around. May see on our reef sites. Part of a healthy reef.',
        image: require('@/assets/fish/Octopus.png')
    },
    {
        class: 'turtle',
        name: 'Turtles',
        group: 'Other',
        description: 'Turtles are rare, and unlikely to see at our sites, but distinctive to identify and nice to record.',
        image: require('@/assets/fish/Turtle.png')
    },
    {
        class: 'shark',
        name: 'Black tip reef sharks',
        group: 'Other',
        description: 'May swim by and would be good to see and record.',
        image: require('@/assets/fish/Shark.png')
    }
];
