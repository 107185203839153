<template>
    <div v-if="lastData" class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col mb-4">
        <h3 class="font-black font-lato mb-2">
            <div class="gradient-text flex items-center">
                <img :src="require('@/assets/icons/ai_coral.svg')" class="w-5 h-5 mr-2" alt="AI Coral Icon" />
                AI Reefresh
            </div>
        </h3>
        <div v-if="loading" class="animate-pulse">
            <div class="h-4 bg-gray-700 rounded w-3/4 mb-2"></div>
            <div class="h-4 bg-gray-700 rounded w-1/2"></div>
        </div>
        <div v-else>
            {{ summary }}
        </div>
    </div>
</template>

<script>
import { db_resto } from '../main.js';
import { collection, query, where, getDocs } from 'firebase/firestore';

export default {
    name: 'AIReefresh',
    props: ['user', 'currentData'],
    data() {
        return {
            lastData: null,
            summary: null, 
            key: 'sk-proj-8LWsgEpvGdVWNaiqXHhqT3BlbkFJ6P3HOg8yROCNTfljYq9E',
            userName: '',
            loading: true
        };
    },
    mounted() {
        this.getUserLatest();
    },
    methods: {
        async getUserLatest() {
            const usersRef = collection(db_resto, 'Users');

            try {
                const q = query(usersRef, where("email", "==", this.user.email));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    console.error('No matching user document found');
                    this.loading = false;
                    return;
                }
                const userDoc = querySnapshot.docs[0];

                const userDocData = userDoc.data();
                if (!userDocData.lastData) {
                    this.loading = false;
                    return;
                } else {
                    this.lastData = userDocData.lastData;
                    this.userName = this.user.displayName ? this.user.displayName.split(' ')[0] : this.user.email.split('@')[0];
                    this.getSummary();
                }
               

            } catch (error) {
                console.error('Error updating user profile:', error);
            }
        },
        async getSummary() {
            const currentData = JSON.stringify(this.currentData);
            const currentTimestamp = new Date().toLocaleString();

            const lastData = JSON.stringify(this.lastData);
            const lastDataTimestamp = this.lastData.last_data_timestamp.toDate().toLocaleString();

            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.key}`
                },
                body: JSON.stringify({
                    model: "gpt-4o-mini-2024-07-18",
                    messages: [
                        { role: "system", content: `You are a helpful AI model with the goal of understanding the changes in a dataset through time` },
                        { role: "system", content: `First dataset: ${lastData} (last updated: ${lastDataTimestamp})` },
                        { role: "system", content: `Latest dataset: ${currentData} (last updated: ${currentTimestamp})` },
                        { role: "user", content: `Can you summarize what has changed in my data in the style of a professional weather report in one sentence? 
                            Please start the sentence with 'Hi ${this.userName}, since you last logged on' (mention time difference),. 
                            If nothing has changed simply say that nothing has change. Don't mention any of the data if it hasn't changed. Please be as concise as possible.` }
                    ],
                    temperature: 0.2,
                    max_tokens: 1024,
                    frequency_penalty: 0.5,
                    presence_penalty: 0,
                })
            });

            const result = await response.json();
            this.summary = result.choices[0].message.content;
            this.loading = false
        },
    }
}
</script>

<style scoped>
.gradient-text {
    background: linear-gradient(90deg,
            #5EBAF0 0%,
            #B9F6FC 7%,
            #F9F5FE 15%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>