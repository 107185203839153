<template>
    <div class="mx-auto p-4 h-[calc(100vh-70px)] flex flex-col relative">
        <h2 class="text-2xl font-black font-lato mb-4 text-white inline-flex items-center">
            Manage Corals
            <span class="ml-2 px-2 py-1 text-xs bg-primary text-white rounded-full">global</span>
        </h2>
        <button @click="showAddCoralModal = true" class="absolute top-4 right-4 bg-primary hover:bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md transition duration-300 ease-in-out flex items-center justify-center">
            <span class="mr-2">Add New</span>
            <i class="mdi mdi-plus"></i>
        </button>
        <div v-if="coralGenera.length > 0" class="grid grid-cols-1 md:grid-cols-2 gap-4 flex-grow overflow-hidden">
            <div class="bg-black p-4 rounded-lg overflow-auto no-scrollbar">
                <ul>
                    <li v-for="genus in coralGenera" :key="genus.id" class="mb-2">
                        <!-- Genus Header with Collapsible Caret -->
                        <div @click="toggleGenus(genus.id)" class="flex items-center cursor-pointer">
                            <i 
                                :class="[
                                    'mdi',
                                    expandedGenera[genus.id] ? 'mdi-chevron-down' : 'mdi-chevron-right',
                                    'mr-2',
                                    'transition-transform',
                                    'duration-200'
                                ]"
                            ></i>
                            <h4 class="font-semibold text-white inline-flex items-center flex-1">
                                {{ genus.name }}
                                <span class="ml-2 px-2 py-1 text-xs bg-neutral-700 text-white rounded-full">
                                    {{ Object.keys(genus.species).length }}
                                </span>
                            </h4>
                        </div>
                        <!-- Species List -->
                        <ul class="ml-4 mt-2" v-show="expandedGenera[genus.id]">
                            <li 
                                v-for="(species, speciesName) in sortedSpecies(genus.species)" 
                                :key="speciesName" 
                                @click="selectSpecies(genus.name, speciesName, species.morphology)"
                                :class="[
                                    'cursor-pointer hover:text-blue-300 text-gray-300',
                                    isSelected(genus.name, speciesName) ? 'bg-neutral-700 text-white' : ''
                                ]"
                                class="px-2 py-1 rounded transition-colors duration-200"
                            >
                                {{ speciesName }}
                                <span v-if="species.morphology" class="ml-2 px-2 py-1 text-xs bg-gray-700 rounded-full">
                                    {{ species.morphology }}
                                </span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="flex flex-col">
                <div v-if="selectedSpecies" class="bg-black p-4 rounded-lg self-start">
                    <h3 class="text-xl font-semibold mb-2 text-white">Edit Species: {{ selectedSpecies.genus }} {{ selectedSpecies.originalName }}</h3>
                    <div class="mb-4">
                        <label class="block mb-2 text-gray-300">Species Name</label>
                        <input v-model="selectedSpecies.name" class="w-full bg-black text-white rounded p-2 border border-gray-700">
                    </div>
                    <!-- Morphology Selection -->
                    <div class="mb-4">
                        <label class="block mb-2 text-gray-300">Morphology</label>
                        <select v-model="selectedSpecies.morphology" @change="handleMorphologyChange('edit')" class="w-full bg-black text-white rounded p-2 border border-gray-700">
                            <option value="">None</option>
                            <option v-for="morphology in morphologies" :key="morphology" :value="morphology">
                                {{ morphology }}
                            </option>
                            <option value="add_new_morphology">Add New Morphology</option>
                        </select>
                    </div>
                    <!-- New Morphology Input -->
                    <div v-if="showNewMorphologyInput.edit" class="mb-4">
                        <label class="block mb-2 text-gray-300">New Morphology Name</label>
                        <input v-model="newMorphologyName.edit" class="w-full bg-black text-white rounded p-2 border border-gray-700">
                    </div>
                    <div class="flex justify-end space-x-2">
                        <button @click="deleteSpecies" class="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded">
                            Delete
                        </button>
                        <div class="flex-grow"></div>
                        <button @click="cancelEdit" class="bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded">
                            Cancel
                        </button>
                        <button @click="saveSpecies" class="bg-primary hover:bg-blue-600 text-white px-4 py-2 rounded">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add New Coral Modal -->
        <div v-if="showAddCoralModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div class="bg-black p-6 rounded-lg w-96">
                <h3 class="text-xl font-semibold mb-4 text-white">Add New Coral</h3>
                <div class="mb-4">
                    <label class="block mb-2 text-gray-300">Genus</label>
                    <select v-model="newCoral.genus" class="w-full bg-black text-white rounded p-2 border border-gray-700">
                        <option value="">Select Genus</option>
                        <option v-for="genus in coralGenera" :key="genus.id" :value="genus.name">
                            {{ genus.name.charAt(0).toUpperCase() + genus.name.slice(1) }}
                        </option>
                        <option value="new">Add New Genus</option>
                    </select>
                </div>
                <div v-if="newCoral.genus === 'new'" class="mb-4">
                    <label class="block mb-2 text-gray-300">New Genus Name</label>
                    <input v-model="newCoral.newGenus" class="w-full bg-black text-white rounded p-2 border border-gray-700">
                </div>
                <div class="mb-4">
                    <label class="block mb-2 text-gray-300">Species Name</label>
                    <input v-model="newCoral.species" class="w-full bg-black text-white rounded p-2 border border-gray-700">
                </div>
                <!-- Morphology Selection -->
                <div class="mb-4">
                    <label class="block mb-2 text-gray-300">Morphology</label>
                    <select v-model="newCoral.morphology" @change="handleMorphologyChange('add')" class="w-full bg-black text-white rounded p-2 border border-gray-700">
                        <option value="">None</option>
                        <option v-for="morphology in morphologies" :key="morphology" :value="morphology">
                            {{ morphology }}
                        </option>
                        <option value="add_new_morphology">Add New Morphology</option>
                    </select>
                </div>
                <!-- New Morphology Input -->
                <div v-if="showNewMorphologyInput.add" class="mb-4">
                    <label class="block mb-2 text-gray-300">New Morphology Name</label>
                    <input v-model="newMorphologyName.add" class="w-full bg-black text-white rounded p-2 border border-gray-700">
                </div>
                <div class="flex justify-end">
                    <button @click="showAddCoralModal = false" class="mr-2 bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded">
                        Cancel
                    </button>
                    <button @click="addNewCoral" class="bg-primary hover:bg-blue-600 text-white px-4 py-2 rounded">
                        Add Coral
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { collection, getDocs, doc, updateDoc, setDoc, deleteField } from 'firebase/firestore';
import { db_resto } from '../main.js';

export default {
    name: 'ManageCorals',
    data() {
        return {
            coralGenera: [],
            selectedSpecies: null,
            showAddCoralModal: false,
            newCoral: {
                genus: '',
                newGenus: '',
                species: '',
                morphology: ''
            },
            // Track expanded state for each genus
            expandedGenera: {},
            // Morphology Management
            morphologies: [],
            showNewMorphologyInput: {
                edit: false,
                add: false
            },
            newMorphologyName: {
                edit: '',
                add: ''
            }
        };
    },
    mounted() {
        this.fetchCoralGenera();
    },
    methods: {
        async fetchCoralGenera() {
            const coralGeneraRef = collection(db_resto, 'Orgs/coral-gardeners/Corals');
            const querySnapshot = await getDocs(coralGeneraRef);
            this.coralGenera = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            // Initialize all genera as collapsed
            this.coralGenera.forEach(genus => {
                this.$set(this.expandedGenera, genus.id, false);
            });
            this.updateMorphologies();
        },
        updateMorphologies() {
            const morphSet = new Set();
            this.coralGenera.forEach(genus => {
                Object.values(genus.species).forEach(species => {
                    if (species.morphology) {
                        morphSet.add(species.morphology);
                    }
                });
            });
            this.morphologies = Array.from(morphSet).sort();
        },
        toggleGenus(genusId) {
            if (this.expandedGenera.hasOwnProperty(genusId)) {
                this.expandedGenera[genusId] = !this.expandedGenera[genusId];
            } else {
                // If genusId is not present, initialize it as expanded
                this.$set(this.expandedGenera, genusId, true);
            }
        },
        selectSpecies(genus, species, morphology) {
            this.selectedSpecies = {
                genus,
                originalName: species,
                name: species,
                morphology: morphology || ''
            };
            // Reset Morphology Input for Editing
            this.showNewMorphologyInput.edit = false;
            this.newMorphologyName.edit = '';
        },
        cancelEdit() {
            this.selectedSpecies = null;
            this.showNewMorphologyInput.edit = false;
            this.newMorphologyName.edit = '';
        },
        async saveSpecies() {
            if (!this.selectedSpecies) return;

            // Handle new morphology
            if (this.selectedSpecies.morphology === 'add_new_morphology') {
                if (!this.newMorphologyName.edit) {
                    alert('Please enter a new morphology name');
                    return;
                }
                this.selectedSpecies.morphology = this.newMorphologyName.edit.trim();
                this.newMorphologyName.edit = '';
                this.showNewMorphologyInput.edit = false;
            }

            const genusDoc = this.coralGenera.find(g => g.name === this.selectedSpecies.genus);
            if (!genusDoc) return;

            const coralDocRef = doc(db_resto, 'Orgs/coral-gardeners/Corals', genusDoc.id);
            
            const updatedSpecies = { ...genusDoc.species };
            
            if (this.selectedSpecies.originalName !== this.selectedSpecies.name) {
                delete updatedSpecies[this.selectedSpecies.originalName];
            }

            updatedSpecies[this.selectedSpecies.name] = {
                morphology: this.selectedSpecies.morphology
            };

            await updateDoc(coralDocRef, {
                species: updatedSpecies
            });

            await this.fetchCoralGenera();
            this.selectedSpecies = null;
        },
        sortedSpecies(species) {
            return Object.entries(species)
                .sort(([aName], [bName]) => aName.localeCompare(bName))
                .reduce((acc, [name, data]) => {
                    acc[name] = data;
                    return acc;
                }, {});
        },
        async addNewCoral() {
            if (!this.newCoral.species) {
                alert('Please enter a species name');
                return;
            }

            let genusName = this.newCoral.genus;
            let genusDoc;

            if (this.newCoral.genus === 'new') {
                if (!this.newCoral.newGenus) {
                    alert('Please enter a new genus name');
                    return;
                }
                genusName = this.newCoral.newGenus.trim().toLowerCase();
        
                const newGenusRef = doc(collection(db_resto, 'Orgs/coral-gardeners/Corals'));
                await setDoc(newGenusRef, {
                    name: genusName,
                    species: {}
                });
                genusDoc = { id: newGenusRef.id, name: genusName, species: {} };
                // Initialize the new genus as collapsed
                this.$set(this.expandedGenera, genusDoc.id, false);
            } else {
                genusDoc = this.coralGenera.find(g => g.name === genusName);
            }

            if (!genusDoc) {
                alert('Error: Genus not found');
                return;
            }

            // Handle new morphology
            if (this.newCoral.morphology === 'add_new_morphology') {
                if (!this.newMorphologyName.add) {
                    alert('Please enter a new morphology name');
                    return;
                }
                this.newCoral.morphology = this.newMorphologyName.add.trim();
                this.newMorphologyName.add = '';
                this.showNewMorphologyInput.add = false;
            }

            const updatedSpecies = { ...genusDoc.species };
            updatedSpecies[this.newCoral.species.toLowerCase()] = {
                morphology: this.newCoral.morphology
            };

            const coralDocRef = doc(db_resto, 'Orgs/coral-gardeners/Corals', genusDoc.id);
            await updateDoc(coralDocRef, {
                species: updatedSpecies
            });

            await this.fetchCoralGenera();

            this.newCoral = { genus: '', newGenus: '', species: '', morphology: '' };
            this.showAddCoralModal = false;
        },
        async deleteSpecies() {
            if (!this.selectedSpecies) return;

            if (!confirm(`Are you sure you want to delete ${this.selectedSpecies.genus} ${this.selectedSpecies.originalName}?`)) {
                return;
            }

            const genusDoc = this.coralGenera.find(g => g.name === this.selectedSpecies.genus);
            if (!genusDoc) return;

            const coralDocRef = doc(db_resto, 'Orgs/coral-gardeners/Corals', genusDoc.id);
            
            const updatedSpecies = { ...genusDoc.species };
            delete updatedSpecies[this.selectedSpecies.originalName];

            await updateDoc(coralDocRef, {
                [`species.${this.selectedSpecies.originalName}`]: deleteField()
            });

            await this.fetchCoralGenera();
            this.selectedSpecies = null;
        },
        handleMorphologyChange(context) {
            if (context === 'edit') {
                if (this.selectedSpecies.morphology === 'add_new_morphology') {
                    this.showNewMorphologyInput.edit = true;
                } else {
                    this.showNewMorphologyInput.edit = false;
                    this.newMorphologyName.edit = '';
                }
            } else if (context === 'add') {
                if (this.newCoral.morphology === 'add_new_morphology') {
                    this.showNewMorphologyInput.add = true;
                } else {
                    this.showNewMorphologyInput.add = false;
                    this.newMorphologyName.add = '';
                }
            }
        },
        isSelected(genus, species) {
            return this.selectedSpecies 
                && this.selectedSpecies.genus === genus 
                && this.selectedSpecies.originalName === species;
        }
    }
};
</script>

<style scoped>
.grid {
    display: grid;
    gap: 1rem;
    align-items: start;
}

@media (min-width: 768px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Ensure the left side is scrollable without affecting the overall layout */
.bg-black {
    max-height: 100%;
}

/* Optional: Add transition for species list */
ul.ml-4.mt-2 {
    transition: max-height 0.3s ease;
}

/* Highlighted Species Transition */
.li.bg-blue-700 {
    transition: background-color 0.3s;
}
</style>