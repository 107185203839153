<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col space-y-4 mb-4">
        <!-- Header with title and menu -->
        <div class="flex justify-between items-center">
            <div class="flex items-center">
                <h3 class="text-lg font-black font-lato mr-1">Nature's Schedule</h3>
                <!-- Menu button -->
                <div class="relative">
                    <button @click="toggleMenu" class="text-white hover:text-gray-300 transition-colors duration-300">
                        <i class="mdi mdi-dots-vertical text-xl"></i>
                    </button>
                    <!-- Dropdown menu -->
                    <div v-if="showMenu"
                        class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                        <div class="py-1" role="menu">
                            <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800"
                                @click.prevent="toggleEditMode">
                                <i :class="editMode ? 'mdi mdi-pencil-off' : 'mdi mdi-pencil'" class="mr-2"></i>
                                {{ editMode ? 'Disable Edit Schedule' : 'Edit Schedule' }}
                            </a>
                            <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800"
                                @click.prevent="savePDF">
                                <i class="mdi mdi-file-pdf-box mr-2"></i>Save as PDF
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Expand/Collapse button -->
            <button @click="toggleExpanded"
                class="text-white p-1 rounded hover:bg-gray-700 transition-transform duration-300 ease-in-out"
                :class="{ 'transform rotate-180': expanded }">
                <i class="text-lg mdi mdi-chevron-down"></i>
            </button>
        </div>

        <!-- Ongoing events -->
        <div v-if="ongoingEvents.length > 0">
            <h4 class="text-sm font-semibold mb-2">Ongoing events</h4>
            <div class="flex flex-wrap justify-start">
                <div v-for="(event, index) in ongoingEvents" :key="index" class="flex items-center mr-4 mb-2">
                    <div class="w-1 h-4 rounded-full mr-2" :style="{ backgroundColor: getEventColor(event) }"></div>
                    <span class="text-xs">
                        {{ stripTrailingDigits(event.name) }}
                        <strong v-if="event.isWeather"> Weather</strong>
                        <strong v-else-if="event.isCoralSpawning"> Spawning</strong>
                        <strong v-else> {{ event.siteName }}</strong>
                        ({{ formatDuration(event.remainingDuration) }} left)
                    </span>
                </div>
            </div>
        </div>

        <!-- Upcoming events -->
        <div v-if="upcomingEvents.length > 0">
            <h4 class="text-sm font-semibold mb-2">Upcoming events</h4>
            <div class="flex flex-wrap justify-start">
                <div v-for="(event, index) in upcomingEvents" :key="index" class="flex items-center mr-4 mb-2">
                    <div class="w-1 h-4 rounded-full mr-2" :style="{ backgroundColor: getEventColor(event) }"></div>
                    <span class="text-xs">
                        <template v-if="event.isFullMoon">
                            <strong>Full Moon </strong>
                        </template>
                        <template v-else>
                            <strong>{{ stripTrailingDigits(event.name) }} </strong>
                            <strong v-if="event.isWeather"> Weather</strong>
                            <strong v-else-if="event.isCoralSpawning"> Spawning</strong>
                            <strong v-else> {{ event.siteName }}</strong>
                        </template>
                        in {{ event.timeUntil }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Expanded timeline view -->
        <transition name="slide">
            <div v-if="expanded">
                <div class="timeline-container relative" ref="container">
                    <svg :key="svgKey" :width="timelineWidth" :height="totalHeight">
                        <!-- SVG Definitions -->
                        <defs>
                            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                                <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" style="stroke:#888; stroke-width:1" />
                            </pattern>
                            <!-- Sea Surface Temperature Gradient -->
                            <linearGradient id="sst-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop v-for="(month, index) in adjustedMonths" :key="month"
                                    :offset="`${(index / (adjustedMonths.length - 1)) * 100}%`"
                                    :stop-color="getSSTColor(monthlySST[branchId][month])" />
                            </linearGradient>
                            <!-- Wave Height Gradients for Each Direction -->
                            <linearGradient 
                                v-for="direction in ['N', 'E', 'S', 'W']" 
                                :key="`wave-gradient-${direction}`" 
                                :id="`wave-gradient-${direction}`" 
                                x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop 
                                        v-for="(month, index) in adjustedMonths" 
                                        :key="`${direction}-${month}`"
                                        :offset="`${(index / (adjustedMonths.length - 1)) * 100}%`"
                                        :stop-color="getWaveGradient(direction, month)" />
                            </linearGradient>
                            <!-- New Precipitation Gradient -->
                            <linearGradient id="precipitation-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <!-- Start with Black -->
                                <stop offset="0%" stop-color="black" />
                                <stop v-for="(month, index) in adjustedMonths" :key="'precp-stop-' + index" 
                                    :offset="`${(index / (adjustedMonths.length - 1)) * 100}%`" 
                                    :stop-color="getPrecipitationColor(precipitationData[branchId][month].precipitation)" />
                            </linearGradient>
                        </defs>

                        <!-- Vertical Month Lines -->
                        <g>
                        <line v-for="(month, index) in adjustedMonths" :key="'grid-' + month"
                            :x1="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)"
                            :y1="topPadding"
                            :x2="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)"
                            :y2="totalHeight - bottomPadding" stroke="#333333" stroke-width="1" />
                        </g>

                        <!-- Gradient Backgrounds -->
                        <g>
                            <!-- Sea Surface Temperature Row -->
                            <rect :x="labelWidth" :y="topPadding + rowHeight"
                                  :width="Math.max(timelineWidth - labelWidth - padding, 0)" :height="rowHeight"
                                  fill="url(#sst-gradient)" />

                            <!-- Wave Rows -->
                            <g v-for="(direction, dirIndex) in ['N', 'W', 'S', 'E']" :key="direction">
                                <rect 
                                    :x="labelWidth" 
                                    :y="topPadding + rowHeight * (2 + dirIndex)"
                                    :width="Math.max(timelineWidth - labelWidth - padding, 0)" 
                                    :height="rowHeight"
                                    :fill="`url(#wave-gradient-${direction})`" 
                                />
                            </g>

                            <!-- Precipitation Row -->
                            <rect :x="labelWidth" 
                                  :y="topPadding + rowHeight * 6"
                                  :width="Math.max(timelineWidth - labelWidth - padding, 0)" 
                                  :height="rowHeight"
                                  fill="url(#precipitation-gradient)" />
                        </g>

                        <!-- Horizontal Lines Between Rows -->
                        <g>
                            <!-- Line between Full Moon Row and SST Row -->
                            <line
                                :x1="labelWidth"
                                :y1="topPadding + rowHeight"
                                :x2="Math.max(timelineWidth - padding, labelWidth)"
                                :y2="topPadding + rowHeight"
                                stroke="#333333"
                                stroke-width="1"
                                opacity="0.5"
                            />

                            <!-- Line between SST Row and Wave Rows -->
                            <line
                                :x1="labelWidth"
                                :y1="topPadding + rowHeight * 2"
                                :x2="Math.max(timelineWidth - padding, labelWidth)"
                                :y2="topPadding + rowHeight * 2"
                                stroke="#333333"
                                stroke-width="1"
                                opacity="0.5"
                            />

                            <!-- Lines between Wave Rows -->
                            <line v-for="index in 3" :key="'wave-divider-' + index"
                                :x1="labelWidth"
                                :y1="topPadding + rowHeight * (2 + index)"
                                :x2="Math.max(timelineWidth - padding, labelWidth)"
                                :y2="topPadding + rowHeight * (2 + index)"
                                stroke="#333333"
                                stroke-width="1"
                                opacity="0.5"
                            />

                            <!-- Line between Wave Rows and Precipitation Row -->
                            <line
                                :x1="labelWidth"
                                :y1="topPadding + rowHeight * 6"
                                :x2="Math.max(timelineWidth - padding, labelWidth)"
                                :y2="topPadding + rowHeight * 6"
                                stroke="#333333"
                                stroke-width="1"
                                opacity="0.5"
                            />

                            <!-- Line between Precipitation Row and Coral Spawning Row -->
                            <line
                                :x1="labelWidth"
                                :y1="topPadding + rowHeight * 7"
                                :x2="Math.max(timelineWidth - padding, labelWidth)"
                                :y2="topPadding + rowHeight * 7"
                                stroke="#333333"
                                stroke-width="1"
                                opacity="0.5"
                            />

                            <!-- Line between Coral Spawning Row and Weather Section -->
                            <line
                                :x1="labelWidth"
                                :y1="topPadding + rowHeight * (7 + maxCoralSpawningLevels)"
                                :x2="Math.max(timelineWidth - padding, labelWidth)"
                                :y2="topPadding + rowHeight * (7 + maxCoralSpawningLevels)"
                                stroke="#333333"
                                stroke-width="1"
                                opacity="0.5"
                            />

                            <!-- Line above the Timeline Axis -->
                            <line
                                :x1="labelWidth"
                                :y1="totalHeight - bottomPadding"
                                :x2="Math.max(timelineWidth - padding, labelWidth)"
                                :y2="totalHeight - bottomPadding"
                                stroke="white"
                                stroke-width="1"
                            />

                        </g>

                        <!-- Full Moon Row -->
                        <g>
                            <!-- Color Bar -->
                            <rect :x="labelWidth - 10" :y="topPadding" width="3" :height="rowHeight"
                                :fill="fullMoonColor" rx="1.5" />
                            
                            <!-- Full Moon Circles -->
                            <circle v-for="(date, index) in moonPhases.full" :key="'full-' + index" 
                                :cx="getFullMoonX(date)"
                                :cy="topPadding + rowHeight / 2" 
                                r="5" 
                                fill="white"
                                @mouseover="showMoonPhaseTooltip($event, date, 'Full Moon')" 
                                @mouseout="hideTooltip" 
                            />

                            <!-- Only render these on non-mobile devices -->
                            <template v-if="!isMobile">
                                <!-- New Moon -->
                                <g v-for="(date, index) in moonPhases.new" :key="'new-' + index"
                                    :transform="`translate(${getFullMoonX(date)}, ${topPadding + rowHeight / 2})`"
                                    @mouseover="showMoonPhaseTooltip($event, date, 'New Moon')" 
                                    @mouseout="hideTooltip"
                                >
                                    <circle r="5" fill="black" stroke="white" stroke-width="1" />
                                </g>

                                <!-- First Quarter -->
                                <g v-for="(date, index) in moonPhases.first" :key="'first-' + index"
                                    :transform="`translate(${getFullMoonX(date)}, ${topPadding + rowHeight / 2})`"
                                    @mouseover="showMoonPhaseTooltip($event, date, 'First Quarter')" 
                                    @mouseout="hideTooltip"
                                >
                                    <circle r="5" fill="none" stroke="white" stroke-width="1" />
                                    <path d="M0,-5 A5,5 0 0,1 0,5 Z" fill="white" />
                                </g>

                                <!-- Third Quarter -->
                                <g v-for="(date, index) in moonPhases.third" :key="'third-' + index"
                                    :transform="`translate(${getFullMoonX(date)}, ${topPadding + rowHeight / 2})`"
                                    @mouseover="showMoonPhaseTooltip($event, date, 'Third Quarter')" 
                                    @mouseout="hideTooltip"
                                >
                                    <circle r="5" fill="none" stroke="white" stroke-width="1" />
                                    <path d="M0,-5 A5,5 0 0,0 0,5 Z" fill="white" />
                                </g>
                            </template>
                        </g>

                        <!-- Sea Surface Temperature Row -->
                        <g>
                            <!-- Color Bar -->
                            <rect :x="labelWidth - 10" :y="topPadding + rowHeight" width="3" :height="rowHeight"
                                fill="red" rx="1.5" />
                            <!-- SST Labels -->
                            <g v-for="(month, index) in adjustedMonths" :key="month">
                                <text :x="labelWidth +
                                    ((index + 0.5) * (timelineWidth - labelWidth - padding)) / 12
                                    " :y="topPadding + rowHeight + rowHeight / 2" font-size="10" fill="white"
                                    text-anchor="middle" dominant-baseline="middle">
                                    {{ monthlySST[branchId][month] }}
                                </text>
                            </g>
                            <!-- Moon Phases Row -->
                            <g>
                                <image 
                                    :x="labelWidth - 22.5 - 5"
                                    :y="topPadding + rowHeight / 2 - 7.5"
                                    width="15"
                                    height="15"
                                    :href="require('@/assets/icons/nature-schedule/ns-moonPhase.svg')"
                                />
                            </g>
                            <!-- Sea Surface Temperature Row -->
                            <g>
                                <image 
                                    :x="labelWidth - 22.5 - 5"
                                    :y="topPadding + rowHeight + rowHeight / 2 - 7.5"
                                    width="15"
                                    height="15"
                                    :href="require('@/assets/icons/nature-schedule/ns-seaSurfaceTemp.svg')"
                                />
                            </g>
                            <!-- Precipitation Row -->
                            <g>
                                <image 
                                    :x="labelWidth - 22.5 - 5"
                                    :y="topPadding + rowHeight * 6 + rowHeight / 2 - 7.5"
                                    width="15"
                                    height="15"
                                    :href="require('@/assets/icons/nature-schedule/ns-precipitation.svg')"
                                />
                            </g>
                            <!-- Coral Spawning Row -->
<g>
    <image 
                                    :x="labelWidth - 22.5 - 5"
                                    :y="topPadding + rowHeight * 7 + rowHeight / 2 - 7.5"
                                    width="15"
                                    height="15"
                                    :href="require('@/assets/icons/nature-schedule/ns-coralSpawning.svg')"
                                />
                            </g>
                            <!-- General Row -->
                            <g>
                                <image 
                                    :x="labelWidth - 22.5 - 5"
                                    :y="topPadding + rowHeight * (7 + maxCoralSpawningLevels) + rowHeight / 2 - 7.5"
                                    width="15"
                                    height="15"
                                    :href="require('@/assets/icons/nature-schedule/ns-general.svg')"
                                />
                            </g>
                        </g>

                        <!-- Wave Rows with Icons -->
                        <g v-for="(direction, dirIndex) in ['N', 'W', 'S', 'E']" :key="direction">
                            <!-- Wave Height Bar -->
                            <rect 
                                :x="labelWidth - 10" 
                                :y="topPadding + rowHeight * (2 + dirIndex) + rowHeight / 2 - 8"
                                width="3" 
                                height="15" 
                                fill="#27bdf4" 
                                rx="2"
                            />

                            <!-- Wave Height Labels for Each Month -->
                            <g v-for="(month, monthIndex) in adjustedMonths" :key="`${direction}-${month}`">
                                <text v-if="waveData[branchId][month][direction].probability >= 0.1"
                                    :x="labelWidth + ((monthIndex + 0.5) * (timelineWidth - labelWidth - padding)) / 12"
                                    :y="topPadding + rowHeight * (2 + dirIndex) + rowHeight / 2"
                                    font-size="10"
                                    fill="white"
                                    text-anchor="middle"
                                    dominant-baseline="middle"
                                >
                                    {{ waveData[branchId][month][direction].height.toFixed(1) }}
                                </text>
                            </g>

                            <!-- Direction Icon -->
                            <image 
                            :x="labelWidth - 22.5 - 5"
                                :y="topPadding + rowHeight * (2 + dirIndex) + rowHeight / 2 - 7.5"
                                width="15"
                                height="15"
                                :href="require(`@/assets/icons/nature-schedule/ns-arrow-${direction.toLowerCase().charAt(0)}.svg`)"
                            />
                        </g>

                        <!-- Coral Spawning Row -->
                        <g>
                            <!-- Color Bar -->
                            <rect :x="labelWidth - 10" :y="topPadding + rowHeight * 7" width="3" :height="maxCoralSpawningLevels * rowHeight"
                                fill="#FF7F50" rx="1.5" />
                            <!-- Coral Spawning Events -->
                            <g v-for="(event, eventIndex) in coralSpawningEvents" :key="'coral-' + eventIndex"
                                :class="{ 'coral-event': true, 'edit-mode': editMode }"
                                @mousedown="onDragStart($event, event, false, true)"
                                @click="selectEvent(event, false, true)">
                                <!-- Event Rectangles and Labels -->
                                <rect v-if="isEventVisible(event)" :x="getEventX(event)" :y="topPadding + rowHeight * 7 + event.level * rowHeight + 2"
                                    :width="Math.max(getEventWidth(event), 2)" :height="rowHeight - 4"
                                    fill="#FF7F50" rx="2"
                                    :class="{ 'stroke-white stroke-2': event === selectedEvent && editMode }" />
                                <text v-if="isEventVisible(event) && getEventWidth(event) > 50"
                                    :x="getEventX(event) + Math.max(getEventWidth(event), 2) / 2"
                                    :y="topPadding + rowHeight * 7 + event.level * rowHeight + rowHeight / 2 - 2" fill="white"
                                    font-size="10" text-anchor="middle" dominant-baseline="middle" class="no-highlight">
                                    {{ event.name }}
                                </text>
                                <rect v-if="isEventVisible(event) && editMode"
                                    :x="getEventX(event) + Math.max(getEventWidth(event), 2) - 5"
                                    :y="topPadding + rowHeight * 7 + event.level * rowHeight + 2" width="5" :height="rowHeight - 4"
                                    fill="white" rx="2" @mousedown.stop="onResizeStart($event, event, false, true)"
                                    class="resize-handle" />
                            </g>
                        </g>

                        <!-- General Events -->
                        <g>
                            <!-- Color Bar -->
                            <rect :x="labelWidth - 10" 
                                  :y="topPadding + rowHeight * (7 + maxCoralSpawningLevels)" 
                                  width="3"
                                  :height="maxWeatherLevels * rowHeight" 
                                  fill="#808080" rx="1.5" />
                            <!-- General Events -->
                            <g v-for="(event, eventIndex) in weatherEvents" :key="'weather-' + eventIndex"
                                :class="{ 'weather-event': true, 'edit-mode': editMode }"
                                @mousedown="onDragStart($event, event, true)" @click="selectEvent(event, true)">
                                <!-- Event Rectangles and Labels -->
                                <rect v-if="isEventVisible(event)" :x="getEventX(event)" :y="topPadding + rowHeight * (7 + maxCoralSpawningLevels) + event.level * rowHeight + 2"
                                    :width="Math.max(getEventWidth(event), 2)" :height="rowHeight - 4"
                                    fill="#808080" rx="2"
                                    :class="{ 'stroke-white stroke-2': event === selectedEvent && editMode }" />
                                <text v-if="isEventVisible(event) && getEventWidth(event) > 30"
                                    :x="getEventX(event) + getEventWidth(event) / 2"
                                    :y="topPadding + rowHeight * (7 + maxCoralSpawningLevels) + event.level * rowHeight + rowHeight / 2"
                                    font-size="10"
                                    fill="white"
                                    text-anchor="middle"
                                    dominant-baseline="central">
                                    {{ event.name }}
                                </text>
                                <rect v-if="isEventVisible(event) && editMode"
                                    :x="getEventX(event) + Math.max(getEventWidth(event), 2) - 5"
                                    :y="topPadding + rowHeight * (7 + maxCoralSpawningLevels) + event.level * rowHeight + 2" width="5" :height="rowHeight - 4" fill="white"
                                    rx="2" @mousedown.stop="onResizeStart($event, event, true)" class="resize-handle" />
                            </g>
                        </g>

                        <!-- Timeline Line -->
                        <line :x1="labelWidth" :y1="totalHeight - bottomPadding" :x2="Math.max(timelineWidth - padding, labelWidth)"
                            :y2="totalHeight - bottomPadding" stroke="white" stroke-width="1" />

                        <!-- Month Ticks -->
                        <line v-for="(month, index) in adjustedMonths" :key="'tick-' + month"
                            :x1="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)"
                            :y1="totalHeight - bottomPadding"
                            :x2="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)"
                            :y2="totalHeight - bottomPadding - 5" stroke="white" stroke-width="1" />

                        <!-- Month Labels -->
                        <text v-for="(month, index) in adjustedMonths" :key="month"
                            :x="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)" :y="totalHeight - 20"
                            text-anchor="middle" font-size="12" fill="white">
                            {{ month }}
                        </text>

                        <!-- Gray Overlay with Diagonal Lines -->
                        <rect v-if="currentDateX !== null" :x="labelWidth" :y="topPadding"
                            :width="currentDateX - labelWidth" :height="totalHeight - topPadding - bottomPadding"
                            fill="url(#diagonalHatch)" fill-opacity="0.4" />


                        <!-- Add Weather Event Button -->
                        <g v-if="editMode">
                            <rect :x="timelineWidth - 23.5" :y="totalHeight - bottomPadding - (maxWeatherLevels * rowHeight) / 2 - 8.5"
                                width="17" height="17" rx="4" fill="#212936" class="cursor-pointer hover:fill-opacity-80"
                                @click="addWeatherEvent" />
                            <text :x="timelineWidth - 15" :y="totalHeight - bottomPadding - (maxWeatherLevels * rowHeight) / 2"
                                text-anchor="middle" dominant-baseline="central" font-size="16" fill="white" class="cursor-pointer select-none"
                                @click="addWeatherEvent">
                                +
                            </text>
                        </g>

                        <!-- Add Coral Spawning Event Button -->
                        <g v-if="editMode">
                            <rect :x="timelineWidth - 23.5" :y="topPadding + rowHeight * 7 + rowHeight / 2 - 8.5"
                                width="17" height="17" rx="4" fill="#212936" class="cursor-pointer hover:fill-opacity-80"
                                @click="addCoralSpawningEvent" />
                            <text :x="timelineWidth - 15" :y="topPadding + rowHeight * 7 + rowHeight / 2"
                                text-anchor="middle" dominant-baseline="central" font-size="16" fill="white" class="cursor-pointer select-none"
                                @click="addCoralSpawningEvent">
                                +
                            </text>
                        </g>

                        <!-- Current Date Line -->
                        <g v-if="currentDateX !== null">
                            <line :x1="currentDateX" :y1="topPadding" :x2="currentDateX" :y2="totalHeight - bottomPadding"
                                stroke="red" stroke-width="1" />
                            <polygon
                                :points="`${currentDateX - 5},${totalHeight - bottomPadding} ${currentDateX + 5},${totalHeight - bottomPadding} ${currentDateX},${totalHeight - bottomPadding - 5}`"
                                fill="red" />
                        </g>
                    </svg>

                    <!-- Tooltip -->
                    <div v-if="tooltipVisible" class="custom-tooltip" :style="tooltipStyle" v-html="tooltipContent"></div>
                </div>

                <!-- Legend -->
                <div class="flex justify-between items-end" ref="legendContainer">
                    <div class="flex flex-wrap flex-grow">
                        <!-- Legend Items -->
                        <div class="flex items-center mb-2 mr-3">
                            <div class="w-3 h-3 rounded-full mr-1"
                                :style="{ backgroundColor: fullMoonColor }">
                            </div>
                            <span class="text-xs">Moon Phase</span>
                        </div>
                        <div class="flex items-center mb-2 mr-3">
                            <div class="w-3 h-3 rounded-full mr-1" style="background-color: red"></div>
                            <span class="text-xs">Sea Surface Temp (°C)</span>
                        </div>
                        <div class="flex items-center mb-2 mr-3">
                            <div class="w-3 h-3 rounded-full mr-1" style="background-color: #27bdf4"></div>
                            <span class="text-xs">Waves (m)</span>
                        </div>
                        <div class="flex items-center mb-2 mr-3">
                            <div class="w-3 h-3 rounded-full mr-1" style="background-color: #ffa500"></div>
                            <span class="text-xs">Precipitation (m)</span>
                        </div>
                        <div class="flex items-center mb-2 mr-3">
                            <div class="w-3 h-3 rounded-full mr-1" style="background-color: #FF7F50"></div>
                            <span class="text-xs">Coral Spawning</span>
                        </div>
                        <div class="flex items-center mb-2 mr-3">
                            <div class="w-3 h-3 rounded-full mr-1" style="background-color: #808080"></div>
                            <span class="text-xs">General</span>
                        </div>
                    </div>
                </div>

                <!-- Event Editing Card -->
                <div v-if="selectedEvent" class="bg-gray-800 p-2 mt-4 rounded-lg text-sm max-w-[200px]">
                    <!-- Editing Controls -->
                    <div class="flex items-center mb-2">
                        <h3 v-if="!isEditing" class="text-md font-black font-lato mr-2">{{ selectedEvent.name }}</h3>
                        <input v-else v-model="editedName"
                            class="text-md font-black font-lato mr-2 bg-gray-700 text-white px-2 py-1 rounded" />
                        <button @click="toggleEdit" v-if="!isEditing" class="text-white hover:text-gray-300">
                            <span class="mdi mdi-pencil"></span>
                        </button>
                        <button @click="confirmEdit" v-else class="text-white hover:text-gray-300">
                            <span class="mdi mdi-check"></span>
                        </button>
                    </div>
                    <p>
                        {{ formatDate(getEventStartDate(selectedEvent)) }} to
                        {{ formatDate(getEventEndDate(selectedEvent)) }}
                    </p>
                    <p>({{ formatDuration(selectedEvent.duration) }})</p>
                    <div class="mt-2 flex justify-end">
                        <button @click="saveChanges" class="bg-green-500 hover:bg-green-600 text-white px-2 rounded mr-2">
                            ✓
                        </button>
                        <button @click="deleteSelectedEvent" class="bg-red-500 hover:bg-red-600 text-white px-2 rounded">
                            <span class="mdi mdi-trash-can-outline"></span>
                        </button>
                    </div>
                </div>
            </div>
        </transition>

        <!-- Edit Mode "Done" Button -->
        <div v-if="editMode" class="mt-4 flex justify-end">
            <button @click="toggleEditMode" class="bg-primary text-white px-3 py-1 rounded text-sm">
                Done
            </button>
        </div>
    </div>
</template>

<script>
import { db_resto } from '../main';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
    name: 'BranchNature',
    props: {
        branchId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            /* Time and Date Variables */
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            currentYear: new Date().getFullYear(),
            adjustedYear: new Date().getFullYear(),
            adjustedStartMonth: 0,
            adjustedMonths: [],
            moonPhases: {
                full: [],
                new: [],
                first: [],
                third: []
            },
            fullMoonDates: [
                new Date(2024, 0, 25),  // Jan 25 2024
                new Date(2024, 1, 24),  // Feb 24 2024
                new Date(2024, 2, 24),  // Mar 24 2024
                new Date(2024, 3, 23),  // Apr 23 2024
                new Date(2024, 4, 23),  // May 23 2024
                new Date(2024, 5, 21),  // Jun 21 2024
                new Date(2024, 6, 21),  // Jul 21 2024
                new Date(2024, 7, 19),  // Aug 19 2024
                new Date(2024, 8, 17),  // Sep 17 2024
                new Date(2024, 9, 17),  // Oct 17 2024
                new Date(2024, 10, 15), // Nov 15 2024
                new Date(2024, 11, 15), // Dec 15 2024
                new Date(2025, 0, 13),  // Jan 13 2025
                new Date(2025, 1, 12),  // Feb 12 2025
                new Date(2025, 2, 13),  // Mar 13 2025
                new Date(2025, 3, 12),  // Apr 12 2025
                new Date(2025, 4, 12),  // May 12 2025
                new Date(2025, 5, 10),  // Jun 10 2025
                new Date(2025, 6, 10),  // Jul 10 2025
                new Date(2025, 7, 8),   // Aug 8 2025
                new Date(2025, 8, 7),   // Sep 7 2025
                new Date(2025, 9, 6),   // Oct 6 2025
                new Date(2025, 10, 5),  // Nov 5 2025
                new Date(2025, 11, 4),  // Dec 4 2025
            ],
            monthlySST: {
                'moorea': { 'Jan': 28.2, 'Feb': 28.6, 'Mar': 29.0, 'Apr': 29.0, 'May': 28.5, 'Jun': 27.7, 'Jul': 27.2, 'Aug': 26.7, 'Sep': 26.3, 'Oct': 26.6, 'Nov': 27.1, 'Dec': 28.0 },
                'fiji': { 'Jan': 29.2, 'Feb': 29.5, 'Mar': 29.3, 'Apr': 29.5, 'May': 28.7, 'Jun': 27.6, 'Jul': 26.6, 'Aug': 26.2, 'Sep': 26.6, 'Oct': 26.7, 'Nov': 27.8, 'Dec': 28.7 },
                'thailand': { 'Jan': 27.9, 'Feb': 28.4, 'Mar': 28.9, 'Apr': 30.4, 'May': 30.9, 'Jun': 29.8, 'Jul': 29.6, 'Aug': 29.4, 'Sep': 29.2, 'Oct': 29.8, 'Nov': 30.0, 'Dec': 29.5 }
            },
            waveData: {
                'moorea': {
                    'Jan': { 'N': { 'height': 1.82, 'probability': 0.19 }, 'E': { 'height': 1.79, 'probability': 0.3 }, 'S': { 'height': 1.8, 'probability': 0.29 }, 'W': { 'height': 1.75, 'probability': 0.23 } },
                    'Feb': { 'N': { 'height': 1.99, 'probability': 0.11 }, 'E': { 'height': 1.76, 'probability': 0.29 }, 'S': { 'height': 1.89, 'probability': 0.39 }, 'W': { 'height': 1.81, 'probability': 0.21 } },
                    'Mar': { 'N': { 'height': 1.92, 'probability': 0.04 }, 'E': { 'height': 1.79, 'probability': 0.24 }, 'S': { 'height': 1.93, 'probability': 0.62 }, 'W': { 'height': 1.81, 'probability': 0.11 } },
                    'Apr': { 'N': { 'height': 1.47, 'probability': 0.0 }, 'E': { 'height': 1.97, 'probability': 0.16 }, 'S': { 'height': 2.01, 'probability': 0.8 }, 'W': { 'height': 1.78, 'probability': 0.04 } },
                    'May': { 'N': { 'height': 0.0, 'probability': 0.0 }, 'E': { 'height': 1.97, 'probability': 0.12 }, 'S': { 'height': 2.08, 'probability': 0.86 }, 'W': { 'height': 1.94, 'probability': 0.02 } },
                    'Jun': { 'N': { 'height': 2.23, 'probability': 0.0 }, 'E': { 'height': 1.99, 'probability': 0.14 }, 'S': { 'height': 2.18, 'probability': 0.85 }, 'W': { 'height': 1.94, 'probability': 0.01 } },
                    'Jul': { 'N': { 'height': 0.0, 'probability': 0.0 }, 'E': { 'height': 2.13, 'probability': 0.17 }, 'S': { 'height': 2.12, 'probability': 0.82 }, 'W': { 'height': 1.83, 'probability': 0.01 } },
                    'Aug': { 'N': { 'height': 1.44, 'probability': 0.0 }, 'E': { 'height': 2.04, 'probability': 0.18 }, 'S': { 'height': 2.01, 'probability': 0.81 }, 'W': { 'height': 2.11, 'probability': 0.01 } },
                    'Sep': { 'N': { 'height': 0.0, 'probability': 0.0 }, 'E': { 'height': 2.09, 'probability': 0.2 }, 'S': { 'height': 2.09, 'probability': 0.79 }, 'W': { 'height': 1.98, 'probability': 0.0 } },
                    'Oct': { 'N': { 'height': 2.04, 'probability': 0.0 }, 'E': { 'height': 1.98, 'probability': 0.21 }, 'S': { 'height': 1.95, 'probability': 0.77 }, 'W': { 'height': 1.84, 'probability': 0.01 } },
                    'Nov': { 'N': { 'height': 1.74, 'probability': 0.02 }, 'E': { 'height': 1.78, 'probability': 0.31 }, 'S': { 'height': 1.82, 'probability': 0.62 }, 'W': { 'height': 1.68, 'probability': 0.05 } },
                    'Dec': { 'N': { 'height': 1.68, 'probability': 0.14 }, 'E': { 'height': 1.76, 'probability': 0.35 }, 'S': { 'height': 1.84, 'probability': 0.37 }, 'W': { 'height': 1.73, 'probability': 0.15 } }
                },
                'fiji': {
                    'Jan': { 'N': { 'height': 1.71, 'probability': 0.1 }, 'E': { 'height': 1.53, 'probability': 0.11 }, 'S': { 'height': 1.64, 'probability': 0.53 }, 'W': { 'height': 1.66, 'probability': 0.26 } },
                    'Feb': { 'N': { 'height': 1.74, 'probability': 0.1 }, 'E': { 'height': 1.49, 'probability': 0.09 }, 'S': { 'height': 1.63, 'probability': 0.55 }, 'W': { 'height': 1.7, 'probability': 0.26 } },
                    'Mar': { 'N': { 'height': 2.27, 'probability': 0.04 }, 'E': { 'height': 1.41, 'probability': 0.06 }, 'S': { 'height': 1.67, 'probability': 0.73 }, 'W': { 'height': 1.87, 'probability': 0.18 } },
                    'Apr': { 'N': { 'height': 2.21, 'probability': 0.01 }, 'E': { 'height': 1.65, 'probability': 0.07 }, 'S': { 'height': 1.72, 'probability': 0.87 }, 'W': { 'height': 1.94, 'probability': 0.05 } },
                    'May': { 'N': { 'height': 1.28, 'probability': 0.0 }, 'E': { 'height': 1.71, 'probability': 0.09 }, 'S': { 'height': 1.83, 'probability': 0.9 }, 'W': { 'height': 1.79, 'probability': 0.01 } },
                    'Jun': { 'N': { 'height': 0.0, 'probability': 0.0 }, 'E': { 'height': 1.61, 'probability': 0.09 }, 'S': { 'height': 1.86, 'probability': 0.9 }, 'W': { 'height': 1.81, 'probability': 0.01 } },
                    'Jul': { 'N': { 'height': 1.47, 'probability': 0.0 }, 'E': { 'height': 1.7, 'probability': 0.07 }, 'S': { 'height': 1.88, 'probability': 0.92 }, 'W': { 'height': 1.69, 'probability': 0.01 } },
                    'Aug': { 'N': { 'height': 1.13, 'probability': 0.0 }, 'E': { 'height': 1.67, 'probability': 0.09 }, 'S': { 'height': 1.83, 'probability': 0.9 }, 'W': { 'height': 1.39, 'probability': 0.01 } },
                    'Sep': { 'N': { 'height': 1.21, 'probability': 0.0 }, 'E': { 'height': 1.62, 'probability': 0.09 }, 'S': { 'height': 1.76, 'probability': 0.9 }, 'W': { 'height': 1.29, 'probability': 0.01 } },
                    'Oct': { 'N': { 'height': 2.3, 'probability': 0.0 }, 'E': { 'height': 1.66, 'probability': 0.11 }, 'S': { 'height': 1.71, 'probability': 0.88 }, 'W': { 'height': 1.66, 'probability': 0.01 } },
                    'Nov': { 'N': { 'height': 1.61, 'probability': 0.01 }, 'E': { 'height': 1.51, 'probability': 0.11 }, 'S': { 'height': 1.61, 'probability': 0.82 }, 'W': { 'height': 1.42, 'probability': 0.06 } },
                    'Dec': { 'N': { 'height': 1.53, 'probability': 0.03 }, 'E': { 'height': 1.47, 'probability': 0.23 }, 'S': { 'height': 1.57, 'probability': 0.64 }, 'W': { 'height': 1.54, 'probability': 0.09 } }
                },
                'thailand': {
                    'Jan': { 'N': { 'height': 0.43, 'probability': 0.12 }, 'E': { 'height': 0.4, 'probability': 0.03 }, 'S': { 'height': 0.33, 'probability': 0.58 }, 'W': { 'height': 0.28, 'probability': 0.26 } },
                    'Feb': { 'N': { 'height': 0.43, 'probability': 0.04 }, 'E': { 'height': 0.41, 'probability': 0.02 }, 'S': { 'height': 0.35, 'probability': 0.82 }, 'W': { 'height': 0.31, 'probability': 0.11 } },
                    'Mar': { 'N': { 'height': 0.6, 'probability': 0.01 }, 'E': { 'height': 0.38, 'probability': 0.02 }, 'S': { 'height': 0.34, 'probability': 0.9 }, 'W': { 'height': 0.32, 'probability': 0.07 } },
                    'Apr': { 'N': { 'height': 0.47, 'probability': 0.01 }, 'E': { 'height': 0.31, 'probability': 0.01 }, 'S': { 'height': 0.28, 'probability': 0.82 }, 'W': { 'height': 0.42, 'probability': 0.17 } },
                    'May': { 'N': { 'height': 0.0, 'probability': 0.0 }, 'E': { 'height': 0.31, 'probability': 0.01 }, 'S': { 'height': 0.32, 'probability': 0.39 }, 'W': { 'height': 0.64, 'probability': 0.6 } },
                    'Jun': { 'N': { 'height': 0.0, 'probability': 0.0 }, 'E': { 'height': 0.0, 'probability': 0.0 }, 'S': { 'height': 0.39, 'probability': 0.11 }, 'W': { 'height': 0.74, 'probability': 0.89 } },
                    'Jul': { 'N': { 'height': 0.0, 'probability': 0.0 }, 'E': { 'height': 0.0, 'probability': 0.0 }, 'S': { 'height': 0.39, 'probability': 0.13 }, 'W': { 'height': 0.89, 'probability': 0.87 } },
                    'Aug': { 'N': { 'height': 0.22, 'probability': 0.0 }, 'E': { 'height': 0.0, 'probability': 0.0 }, 'S': { 'height': 0.39, 'probability': 0.09 }, 'W': { 'height': 0.88, 'probability': 0.91 } },
                    'Sep': { 'N': { 'height': 0.24, 'probability': 0.0 }, 'E': { 'height': 0.13, 'probability': 0.0 }, 'S': { 'height': 0.33, 'probability': 0.16 }, 'W': { 'height': 0.74, 'probability': 0.84 } },
                    'Oct': { 'N': { 'height': 0.35, 'probability': 0.14 }, 'E': { 'height': 0.25, 'probability': 0.06 }, 'S': { 'height': 0.26, 'probability': 0.36 }, 'W': { 'height': 0.45, 'probability': 0.45 } },
                    'Nov': { 'N': { 'height': 0.4, 'probability': 0.37 }, 'E': { 'height': 0.3, 'probability': 0.11 }, 'S': { 'height': 0.25, 'probability': 0.34 }, 'W': { 'height': 0.23, 'probability': 0.18 } },
                    'Dec': { 'N': { 'height': 0.45, 'probability': 0.41 }, 'E': { 'height': 0.34, 'probability': 0.08 }, 'S': { 'height': 0.27, 'probability': 0.27 }, 'W': { 'height': 0.27, 'probability': 0.25 } }
                }
            },
            precipitationData: {
                'moorea': { 'Jan': { 'precipitation': 0.26 }, 'Feb': { 'precipitation': 0.23 }, 'Mar': { 'precipitation': 0.18 }, 'Apr': { 'precipitation': 0.15 }, 'May': { 'precipitation': 0.11 }, 'Jun': { 'precipitation': 0.08 }, 'Jul': { 'precipitation': 0.06 }, 'Aug': { 'precipitation': 0.06 }, 'Sep': { 'precipitation': 0.07 }, 'Oct': { 'precipitation': 0.12 }, 'Nov': { 'precipitation': 0.13 }, 'Dec': { 'precipitation': 0.26 } },
                'fiji': { 'Jan': { 'precipitation': 0.25 }, 'Feb': { 'precipitation': 0.27 }, 'Mar': { 'precipitation': 0.27 }, 'Apr': { 'precipitation': 0.16 }, 'May': { 'precipitation': 0.09 }, 'Jun': { 'precipitation': 0.06 }, 'Jul': { 'precipitation': 0.05 }, 'Aug': { 'precipitation': 0.06 }, 'Sep': { 'precipitation': 0.08 }, 'Oct': { 'precipitation': 0.08 }, 'Nov': { 'precipitation': 0.12 }, 'Dec': { 'precipitation': 0.15 } },
                'thailand': { 'Jan': { 'precipitation': 0.01 }, 'Feb': { 'precipitation': 0.01 }, 'Mar': { 'precipitation': 0.02 }, 'Apr': { 'precipitation': 0.04 }, 'May': { 'precipitation': 0.2 }, 'Jun': { 'precipitation': 0.24 }, 'Jul': { 'precipitation': 0.32 }, 'Aug': { 'precipitation': 0.26 }, 'Sep': { 'precipitation': 0.26 }, 'Oct': { 'precipitation': 0.2 }, 'Nov': { 'precipitation': 0.05 }, 'Dec': { 'precipitation': 0.02 } }
            },

            /* Timeline Dimensions */
            timelineWidth: 500, 
            labelWidth: 30,
            padding: 0,
            topPadding: 0,
            bottomPadding: 35,

            /* Event Data */
            ongoingEvents: [],
            upcomingEvents: [],
            weatherEvents: [],
            coralSpawningEvents: [],
            rowHeight: 17,

            /* UI State */
            expanded: true,
            showMenu: false,
            editMode: false,
            isEditing: false,
            selectedEvent: null,
            tooltipVisible: false,
            tooltipContent: '',
            tooltipStyle: {},
            svgKey: 0,

            /* Drag and Resize State */
            draggingEvent: null,
            resizingEvent: null,
            dragStartX: 0,
            isDragging: false,
            isResizing: false,

            /* Event Styles and Colors */
            eventColors: ['#00f1a0', '#27bdf4', '#ffffff', '#ee756f', '#ffa500', '#4A4A4A'],
            fullMoonColor: '#ffffff',

            /* Other Variables */
            maxWeatherLevels: 1,
            maxCoralSpawningLevels: 1,
            tempEvent: null,
            editedName: '',
            isWeatherEvent: false,
            isCoralSpawningEvent: false,
            currentDateX: null,
            nextFullMoon: null,
            
        };
    },
    computed: {
        totalHeight() {
            return (
                this.topPadding +
                this.bottomPadding +
                (this.maxWeatherLevels * this.rowHeight) +
                (this.maxCoralSpawningLevels * this.rowHeight) +
                (this.rowHeight * 7)
            );
        },
        minSST() {
            return Math.min(...Object.values(this.monthlySST[this.branchId]));
        },
        maxSST() {
            return Math.max(...Object.values(this.monthlySST[this.branchId]));
        },
        isMobile() {
            return window.innerWidth <= 768;
        },
        maxProbs() {
            const directions = ['N', 'E', 'S', 'W'];
            const maxProbs = {};
            directions.forEach(direction => {
                let maxProb = 0;
                this.adjustedMonths.forEach(month => {
                    const prob = this.waveData[this.branchId][month][direction].probability;
                    if (prob >= 0.1 && prob > maxProb) {
                        maxProb = prob;
                    }
                });
                maxProbs[direction] = maxProb;
            });
            return maxProbs;
        },
        maxPrecipitation() {
            return Math.max(...Object.values(this.precipitationData[this.branchId]).map(d => d.precipitation));
        },
    },
    watch: {
        branchId() {
            this.initializeData();
        },
        currentYear() {
            this.initializeData();
        },
    },
    mounted() {
        this.initializeData();   
       
        window.addEventListener('resize', this.updateTimelineWidth);
        window.addEventListener('mousemove', this.onDragMove);
        window.addEventListener('mousemove', this.onResizeMove);
        window.addEventListener('mouseup', this.onDragEnd);
        window.addEventListener('mouseup', this.onResizeEnd);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateTimelineWidth);
        window.removeEventListener('mousemove', this.onDragMove);
        window.removeEventListener('mousemove', this.onResizeMove);
        window.removeEventListener('mouseup', this.onDragEnd);
        window.removeEventListener('mouseup', this.onResizeEnd);
    },
    methods: {
        /* Initialization Methods */
        initializeData() {
            // Initialize months and adjusted months
            this.updateTimelineWidth();
            this.adjustTimelineMonths();
            this.calculateCurrentDatePosition();
            this.initializeMoonPhases();
             this.fetchAllSchedules();
        },
        async fetchAllSchedules() {
            // Fetch events from the database
            const branchRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}`);
            const branchDoc = await getDoc(branchRef);
            if (branchDoc.exists()) {
                const data = branchDoc.data();
                this.weatherEvents = data.weatherEvents?.[this.currentYear] || [];
                this.coralSpawningEvents = data.coralSpawningEvents?.[this.currentYear] || [];
            }
            this.calculateOngoingAndUpcomingEvents();
            this.calculateWeatherLevels();
        },

        /* UI Update Methods */
        updateTimelineWidth() {
            if (this.$refs.container) {
                this.timelineWidth = this.$refs.container.clientWidth || 500; // Fallback to default if undefined
                this.calculateCurrentDatePosition();
            }
        },
        calculateCurrentDatePosition() {
            // Calculate the current date position on the timeline
            const today = new Date();
            const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
            const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);

            if (today >= startOfTimeline && today <= endOfTimeline) {
                const daysSinceStart = (today - startOfTimeline) / (24 * 60 * 60 * 1000);
                const totalDays = (endOfTimeline - startOfTimeline) / (24 * 60 * 60 * 1000);
                const timelineStart = this.labelWidth;
                const timelineWidth = this.timelineWidth - this.labelWidth;
                this.currentDateX = timelineStart + (daysSinceStart / totalDays) * timelineWidth;
            } else if (today < startOfTimeline) {
                this.currentDateX = this.labelWidth;
            } else {
                this.currentDateX = this.timelineWidth;
            }
        },

        /* Event Handling Methods */
        onDragStart(event, draggedEvent, isWeatherEvent = false, isCoralSpawningEvent = false) {
            // Handle the start of a drag event
            if (!this.editMode && (isWeatherEvent || isCoralSpawningEvent)) return;
            this.draggingEvent = draggedEvent;
            this.dragStartX = event.clientX;
            this.originalStart = new Date(this.currentYear, draggedEvent.start - 1, draggedEvent.day || 1);
            this.isDragging = true;
            if (this.editMode) {
                document.body.style.userSelect = 'none';
            }
            this.selectEvent(draggedEvent, isWeatherEvent, isCoralSpawningEvent);
        },
        onDragMove(event) {
            // Handle dragging an event
            if (!this.draggingEvent) return;
            const deltaX = event.clientX - this.dragStartX;
            const dayWidth = (this.timelineWidth - this.labelWidth) / 365;
            const dayDelta = Math.round(deltaX / dayWidth);

            const newStartDate = new Date(this.originalStart.getTime() + dayDelta * 24 * 60 * 60 * 1000);

            const minDate = new Date(this.currentYear, 0, 1);
            const maxDate = new Date(this.currentYear + 1, 11, 31);

            const eventDuration = this.draggingEvent.duration * 30 * 24 * 60 * 60 * 1000;
            const newEndDate = new Date(newStartDate.getTime() + eventDuration);

            if (newStartDate < minDate) {
                newStartDate.setTime(minDate.getTime());
            } else if (newEndDate > maxDate) {
                newStartDate.setTime(maxDate.getTime() - eventDuration);
            }

            this.draggingEvent.start = newStartDate.getMonth() + 1;
            this.draggingEvent.day = newStartDate.getDate();
            this.draggingEvent.year = newStartDate.getFullYear();

            this.calculateWeatherLevels();
            this.calculateCoralSpawningLevels();
            this.$forceUpdate();
        },
        onDragEnd() {
            // Handle the end of a drag event
            if (this.draggingEvent) {
                this.draggingEvent = null;
                this.isDragging = false;
                document.body.style.userSelect = '';
                this.calculateOngoingAndUpcomingEvents();
                this.calculateWeatherLevels();
                this.calculateCoralSpawningLevels();
                this.svgKey += 1;
            }
        },
        onResizeStart(event, resizedEvent, isWeatherEvent = false, isCoralSpawningEvent = false) {
            // Handle the start of a resize event
            if (!this.editMode && (isWeatherEvent || isCoralSpawningEvent)) return;
            this.resizingEvent = resizedEvent;
            this.dragStartX = event.clientX;
            this.originalDuration = resizedEvent.duration;
            this.isResizing = true;
            if (this.editMode) {
                document.body.style.userSelect = 'none';
            }
        },
        onResizeMove(event) {
            // Handle resizing an event
            if (!this.resizingEvent) return;
            const deltaX = event.clientX - this.dragStartX;
            const dayWidth = (this.timelineWidth - this.labelWidth) / 365;
            const dayDelta = Math.round(deltaX / dayWidth);

            const startDate = this.getEventStartDate(this.resizingEvent);
            const maxEndDate = new Date(this.currentYear + 1, 11, 31);
            const maxDuration = (maxEndDate - startDate) / (30 * 24 * 60 * 60 * 1000);

            const newDuration = Math.max(0.1, Math.min(this.originalDuration + dayDelta / 30, maxDuration));
            this.resizingEvent.duration = parseFloat(newDuration.toFixed(1)); // Round to 1 decimal place

            this.calculateWeatherLevels();
            this.$forceUpdate();
        },
        onResizeEnd() {
            // Handle the end of a resize event
            if (this.resizingEvent) {
                this.resizingEvent = null;
                this.isResizing = false;
                document.body.style.userSelect = '';
                this.calculateOngoingAndUpcomingEvents();
                this.calculateWeatherLevels();
                this.calculateCoralSpawningLevels();
                this.svgKey += 1;
            }
        },

        /* Event Data Methods */
        calculateOngoingAndUpcomingEvents() {
            const today = new Date();
            const allEvents = [
                ...this.weatherEvents.map((event) => ({
                    ...event,
                    isWeather: true,
                })),
                ...this.coralSpawningEvents.map((event) => ({
                    ...event,
                    isCoralSpawning: true,
                })),
            ];

            this.ongoingEvents = [];
            this.upcomingEvents = [];

            // Calculate next full moon
            this.calculateNextFullMoon(today);

            const moonPhases = {
                'Full Moon': this.moonPhases.full,
                'First Quarter Moon': this.moonPhases.first,
                'Third Quarter Moon': this.moonPhases.third,
                'New Moon': this.moonPhases.new
            };

            Object.entries(moonPhases).forEach(([phaseName, dates]) => {
                const isMoonPhaseToday = dates.some(date =>
                    date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear()
                );

                if (isMoonPhaseToday) {
                    this.ongoingEvents.push({
                        name: phaseName,
                        isMoonPhase: true,
                        remainingDuration: 1 / 30, 
                    });
                }
            });

            // Add next full moon to upcoming events if it's within the next 3 months
            if (this.nextFullMoon && (this.nextFullMoon.date - today) / (1000 * 60 * 60 * 24) <= 29) {
                this.upcomingEvents.push({
                    name: 'Full Moon',
                    isFullMoon: true,
                    timeUntil: this.nextFullMoon.timeUntil,
                    date: this.nextFullMoon.date,
                });
            }

            allEvents.forEach((event) => {
                const eventStartDate = new Date(this.currentYear, event.start - 1, event.day || 1);
                const eventEndDate = new Date(eventStartDate);
                eventEndDate.setMonth(eventEndDate.getMonth() + Math.floor(event.duration));
                eventEndDate.setDate(eventEndDate.getDate() + Math.round((event.duration % 1) * 30) - 1);

                if (today >= eventStartDate && today <= eventEndDate) {
                    // Ongoing event
                    const remainingDuration = (eventEndDate - today) / (1000 * 60 * 60 * 24 * 30); // Convert to months
                    this.ongoingEvents.push({
                        ...event,
                        remainingDuration,
                    });
                } else if (today < eventStartDate) {
                    // Upcoming event
                    const timeDiff = eventStartDate - today;
                    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

                    let timeUntil = this.formatTimeUntil(daysDiff);

                    this.upcomingEvents.push({
                        ...event,
                        timeUntil,
                        date: eventStartDate,
                    });
                }
            });

            // Sort ongoing events by remaining duration
            this.ongoingEvents.sort((a, b) => a.remainingDuration - b.remainingDuration);

            // Sort upcoming events by start date and limit to 3
            this.upcomingEvents.sort((a, b) => a.date - b.date);
            this.upcomingEvents = this.upcomingEvents.slice(0, 3);
        },
        formatTimeUntil(daysDiff) {
            if (daysDiff === 0) {
                return 'Today';
            } else if (daysDiff === 1) {
                return 'Tomorrow';
            } else if (daysDiff < 7) {
                return `${daysDiff} day${daysDiff > 1 ? 's' : ''}`;
            } else if (daysDiff < 30) {
                const weeks = Math.floor(daysDiff / 7);
                return `${weeks} week${weeks > 1 ? 's' : ''}`;
            } else {
                const months = Math.floor(daysDiff / 30);
                const remainingDays = daysDiff % 30;
                if (remainingDays > 0) {
                    return `${months} month${months > 1 ? 's' : ''} ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
                } else {
                    return `${months} month${months > 1 ? 's' : ''}`;
                }
            }
        },
        calculateWeatherLevels() {
            // Calculate levels for overlapping weather events
            if (this.weatherEvents.length === 0) {
                this.maxWeatherLevels = 1;
                return;
            }

            const sortedEvents = [...this.weatherEvents].sort((a, b) => {
                const aStart = new Date(this.currentYear, a.start - 1, a.day || 1);
                const bStart = new Date(this.currentYear, b.start - 1, b.day || 1);
                return aStart - bStart;
            });

            const levels = [[]];

            sortedEvents.forEach((event) => {
                const eventStart = new Date(this.currentYear, event.start - 1, event.day || 1);
                const eventEnd = new Date(eventStart);
                eventEnd.setMonth(eventEnd.getMonth() + Math.floor(event.duration));
                eventEnd.setDate(eventEnd.getDate() + Math.round((event.duration % 1) * 30) - 1);

                let levelFound = false;
                for (let i = 0; i < levels.length; i++) {
                    if (
                        levels[i].every((existingEvent) => {
                            const existingStart = new Date(
                                this.currentYear,
                                existingEvent.start - 1,
                                existingEvent.day || 1
                            );
                            const existingEnd = new Date(existingStart);
                            existingEnd.setMonth(existingEnd.getMonth() + Math.floor(existingEvent.duration));
                            existingEnd.setDate(existingEnd.getDate() + Math.round((existingEvent.duration % 1) * 30) - 1);
                            return eventEnd <= existingStart || eventStart >= existingEnd;
                        })
                    ) {
                        levels[i].push(event);
                        event.level = i;
                        levelFound = true;
                        break;
                    }
                }

                if (!levelFound) {
                    levels.push([event]);
                    event.level = levels.length - 1;
                }
            });

            this.maxWeatherLevels = levels.length;
        },
        calculateCoralSpawningLevels() {
            if (this.coralSpawningEvents.length === 0) {
                this.maxCoralSpawningLevels = 1;
                return;
            }

            const sortedEvents = [...this.coralSpawningEvents].sort((a, b) => {
                const aStart = new Date(this.currentYear, a.start - 1, a.day || 1);
                const bStart = new Date(this.currentYear, b.start - 1, b.day || 1);
                return aStart - bStart;
            });

            const levels = [[]];

            sortedEvents.forEach((event) => {
                const eventStart = new Date(this.currentYear, event.start - 1, event.day || 1);
                const eventEnd = new Date(eventStart);
                eventEnd.setMonth(eventEnd.getMonth() + Math.floor(event.duration));
                eventEnd.setDate(eventEnd.getDate() + Math.round((event.duration % 1) * 30) - 1);

                let levelFound = false;
                for (let i = 0; i < levels.length; i++) {
                    if (
                        levels[i].every((existingEvent) => {
                            const existingStart = new Date(
                                this.currentYear,
                                existingEvent.start - 1,
                                existingEvent.day || 1
                            );
                            const existingEnd = new Date(existingStart);
                            existingEnd.setMonth(existingEnd.getMonth() + Math.floor(existingEvent.duration));
                            existingEnd.setDate(existingEnd.getDate() + Math.round((existingEvent.duration % 1) * 30) - 1);
                            return eventEnd <= existingStart || eventStart >= existingEnd;
                        })
                    ) {
                        levels[i].push(event);
                        event.level = i;
                        levelFound = true;
                        break;
                    }
                }

                if (!levelFound) {
                    levels.push([event]);
                    event.level = levels.length - 1;
                }
            });

            this.maxCoralSpawningLevels = levels.length;
        },
        calculateNextFullMoon(today) {
            // Calculate the next full moon date
            const nextFullMoon = this.fullMoonDates.find((date) => date > today);
            if (nextFullMoon) {
                const daysDiff = Math.ceil((nextFullMoon - today) / (1000 * 60 * 60 * 24));
                let timeUntil;
                if (daysDiff === 0) {
                    timeUntil = 'Today';
                } else if (daysDiff === 1) {
                    timeUntil = 'Tomorrow';
                } else if (daysDiff < 7) {
                    timeUntil = `${daysDiff} days`;
                } else if (daysDiff < 30) {
                    const weeks = Math.floor(daysDiff / 7);
                    timeUntil = `${weeks} week${weeks > 1 ? 's' : ''}`;
                } else {
                    const months = Math.floor(daysDiff / 30);
                    timeUntil = `${months} month${months > 1 ? 's' : ''}`;
                }
                this.nextFullMoon = { date: nextFullMoon, timeUntil };
            } else {
                this.nextFullMoon = null;
            }
        },

        /* Helper Methods */
        getEventX(event) {
            // Calculate the X position of an event on the timeline
            const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
            const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);

            const eventStart = new Date(event.year, event.start - 1, event.day || 1);
            const visibleStart = new Date(Math.max(eventStart, startOfTimeline));

            if (visibleStart > endOfTimeline) {
                return null;
            }

            const daysSinceStart = (visibleStart - startOfTimeline) / (1000 * 60 * 60 * 24);
            const totalDays = (endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24);
            return this.labelWidth + (daysSinceStart / totalDays) * (this.timelineWidth - this.labelWidth);
        },
        getEventWidth(event) {
            // Calculate the width of an event on the timeline
            if (!this.timelineWidth || this.timelineWidth <= this.labelWidth) {
                return 0;
            }

            const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
            const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);

            const eventStart = new Date(event.year, event.start - 1, event.day || 1);
            const eventEnd = this.getEventEndDate(event);

            const visibleStart = new Date(Math.max(eventStart, startOfTimeline));
            const visibleEnd = new Date(Math.min(eventEnd, endOfTimeline));

            if (visibleStart >= endOfTimeline || visibleEnd <= startOfTimeline) {
                return 0;
            }

            const totalDays = (endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24);
            const visibleDays = (visibleEnd - visibleStart) / (1000 * 60 * 60 * 24);

            return Math.max((visibleDays / totalDays) * (this.timelineWidth - this.labelWidth), 2);
        },
        getFullMoonX(date) {
            // Calculate the X position of a full moon on the timeline
            const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
            const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
            const totalDays = (endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24);
            const daysSinceStart = (date - startOfTimeline) / (1000 * 60 * 60 * 24);
            return this.labelWidth + (daysSinceStart / totalDays) * (this.timelineWidth - this.labelWidth);
        },
        formatDate(date) {
            // Format a date into a readable string
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
        },
        formatDuration(duration) {
            // Format a duration into a readable string
            if (duration >= 1) {
                const months = Math.floor(duration);
                const days = Math.round((duration - months) * 30);
                if (days > 0) {
                    return `${months} month${months > 1 ? 's' : ''} ${days} day${days > 1 ? 's' : ''}`;
                } else {
                    return `${months} month${months > 1 ? 's' : ''}`;
                }
            } else {
                const days = Math.round(duration * 30);
                return `${days} day${days > 1 ? 's' : ''}`;
            }
        },
        getSSTColor(temperature) {
            // Get color based on sea surface temperature
            const minSST = Math.min(...Object.values(this.monthlySST[this.branchId]));
            const maxSST = Math.max(...Object.values(this.monthlySST[this.branchId]));
            const ratio = (temperature - minSST) / (maxSST - minSST);
            const hue = (1 - ratio) * 240; // 240 for blue, 0 for red
            return `hsl(${hue}, 100%, 50%)`;
        },
        isEventVisible(event) {
            // Check if an event is visible within the timeline
            const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
            const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);

            const eventStart = this.getEventStartDate(event);
            const eventEnd = this.getEventEndDate(event);

            return eventStart < endOfTimeline && eventEnd > startOfTimeline;
        },
        getEventStartDate(event) {
            const year = event.year || this.currentYear;
            return new Date(year, event.start - 1, event.day || 1);
        },
        getEventEndDate(event) {
            const startDate = this.getEventStartDate(event);
            const endDate = new Date(startDate);
            endDate.setMonth(endDate.getMonth() + Math.floor(event.duration));
            endDate.setDate(endDate.getDate() + Math.round((event.duration % 1) * 30) - 1);
            return endDate;
        },
        getEventColor(event) {
            // Get the color for an event
            const colorMap = {
                'Seeding': '#00f1a0',
                'Full Monitoring': '#27bdf4',
                'Bleach Monitoring': '#ffffff',
                'Outplanting': '#ee756f',
                'Surveys': '#00f1a0',
                'Photogrammetry': '#27bdf4',
                'Cell Monitoring': '#ffffff',
                'Water Quality': '#ee756f',
                'General': '#808080',
                'Weather': '#808080',
                'Full Moon': this.fullMoonColor,
                'Coral Spawning': '#FF7F50',
            };

            if (event.isFullMoon || event.isMoonPhase) {
                return colorMap['Full Moon'];
            }

            if (event.isWeather) {
                return colorMap['Weather'];
            }

            if (event.isCoralSpawning) {
                return colorMap['Coral Spawning'];
            }

            const eventType = event.name.split(' (')[0];
            return colorMap[eventType] || this.eventColors[event.level % this.eventColors.length];
        },
        stripTrailingDigits(name) {
            return name.replace(/\s+\d+$/, '');
        },
        getWaveGradient(direction, month) {
            const prob = this.waveData[this.branchId][month][direction].probability;
            if (prob < 0.1) {
                return 'rgb(0, 0, 0)'; // Black
            }
            const maxProb = this.maxProbs[direction];
            if (maxProb === 0) {
                return 'rgb(0, 0, 0)'; // Avoid division by zero, default to black
            }
            const adjustedProb = prob / maxProb;
            return this.getWaveColor(adjustedProb);
        },

        getWaveColor(prob) {
            const startColor = { r: 0, g: 0, b: 0 }; // Black
            const endColor = { r: 39, g: 189, b: 244 }; // #27bdf4

            const r = Math.round(startColor.r + (endColor.r - startColor.r) * prob);
            const g = Math.round(startColor.g + (endColor.g - startColor.g) * prob);
            const b = Math.round(startColor.b + (endColor.b - startColor.b) * prob);

            return `rgb(${r}, ${g}, ${b})`;
        },

        getPrecipitationColor(precipitation) {
            const ratio = precipitation / this.maxPrecipitation;

            if (precipitation <= 0.1) {
                return 'black'; // Black for 0 and values less than 0.1
            }

            // Adjust ratio to start from 0 after 0.1
            const adjustedRatio = (ratio - 0.1) / 0.9;
            const startColor = { r: 0, g: 0, b: 0 }; // Black
            const endColor = { r: 255, g: 165, b: 0 }; // Orange (#FFA500)

            const r = Math.round(startColor.r + (endColor.r - startColor.r) * adjustedRatio);
            const g = Math.round(startColor.g + (endColor.g - startColor.g) * adjustedRatio);
            const b = Math.round(startColor.b + (endColor.b - startColor.b) * adjustedRatio);

            return `rgb(${r}, ${g}, ${b})`;
        },

        /* UI Interaction Methods */
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        toggleEditMode() {
            this.editMode = !this.editMode;
            this.showMenu = false;
            if (!this.editMode) {
                this.saveWeatherEvents();
            }
        },
        toggleExpanded() {
            this.expanded = !this.expanded;
            if (this.expanded) {
                this.$nextTick(this.updateTimelineWidth);
            }
        },
        addWeatherEvent() {
            // Add a new weather event
            if (!this.editMode) return;
            const today = new Date();
            const newEvent = {
                name: 'New Event',
                start: today.getMonth() + 1,
                day: today.getDate(),
                duration: 1,
                year: this.currentYear,
            };
            this.weatherEvents.push(newEvent);
            this.selectEvent(newEvent, true);
            this.calculateWeatherLevels();
            this.svgKey += 1;
        },
        addCoralSpawningEvent() {
            // Add a new coral spawning event
            if (!this.editMode) return;
            const today = new Date();
            const newEvent = {
                name: 'New Coral Spawning',
                start: today.getMonth() + 1,
                day: today.getDate(),
                duration: 1,
                year: this.currentYear,
            };
            this.coralSpawningEvents.push(newEvent);
            this.selectEvent(newEvent, false, true);
            this.calculateCoralSpawningLevels();
            this.svgKey += 1;
        },

        /* Tooltip Methods */
        showTooltip(event, eventData) {
            // Show tooltip with event information
            const rect = event.target.getBoundingClientRect();
            const containerRect = this.$refs.container.getBoundingClientRect();

            this.tooltipContent = this.getEventTooltip(eventData);

            this.tooltipStyle = {
                top: `${rect.top - containerRect.top - 5}px`,
                left: `${rect.left - containerRect.left + rect.width / 2}px`,
            };
            this.tooltipVisible = true;
        },
        hideTooltip() {
            this.tooltipVisible = false;
        },
        getEventTooltip(event) {
            const startDate = new Date(this.currentYear, event.start - 1, event.day || 1);
            const endDate = new Date(startDate);
            endDate.setMonth(endDate.getMonth() + Math.floor(event.duration));
            endDate.setDate(endDate.getDate() + Math.round((event.duration % 1) * 30) - 1); // Assuming duration is in months
            const formattedStartDate = this.formatDate(startDate);
            const formattedEndDate = this.formatDate(endDate);
            const duration = this.formatDuration(event.duration);

            return `
        <strong>${event.name}</strong><br>
        ${formattedStartDate} to ${formattedEndDate}<br>
        (${duration})
      `;
        },
        showMoonPhaseTooltip(event, date, phase) {
            const formattedDate = this.formatDate(date);
            const rect = event.target.getBoundingClientRect();
            const containerRect = this.$refs.container.getBoundingClientRect();

            this.tooltipContent = `<strong>${phase}</strong><br>${formattedDate}`;

            this.tooltipStyle = {
                top: `${rect.top - containerRect.top - 5}px`,
                left: `${rect.left - containerRect.left + rect.width / 2}px`,
            };
            this.tooltipVisible = true;
        },

        /* Event Selection and Editing */
        selectEvent(event, isWeatherEvent = false, isCoralSpawningEvent = false) {
            // Select an event for editing
            if (!this.editMode && (isWeatherEvent || isCoralSpawningEvent)) return;
            this.selectedEvent = event;
            this.isWeatherEvent = isWeatherEvent;
            this.isCoralSpawningEvent = isCoralSpawningEvent;
            this.tempEvent = { ...event };
            this.isEditing = false;
        },
        toggleEdit() {
            this.isEditing = !this.isEditing;
            if (this.isEditing) {
                this.editedName = this.selectedEvent.name;
            }
        },
        confirmEdit() {
            if (this.editedName.trim() !== '') {
                this.selectedEvent.name = this.editedName.trim();
            }
            this.isEditing = false;
        },
        saveChanges() {
            this.saveWeatherEvents();
            this.calculateWeatherLevels();
            this.calculateCoralSpawningLevels();
            this.calculateOngoingAndUpcomingEvents();
            this.selectedEvent = null;
            this.isEditing = false;
            this.svgKey += 1;
        },
        deleteSelectedEvent() {
            const events = this.isWeatherEvent ? this.weatherEvents : this.coralSpawningEvents;
            const index = events.indexOf(this.selectedEvent);
            if (index > -1) {
                events.splice(index, 1);
            }
            this.selectedEvent = null;
            this.calculateWeatherLevels();
            this.calculateCoralSpawningLevels();
            this.calculateOngoingAndUpcomingEvents();
            this.svgKey += 1;
            this.saveWeatherEvents();
        },

        /* Save and Export Methods */
        async saveWeatherEvents() {
            // Save events to the database
            const branchRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}`);
            const branchDoc = await getDoc(branchRef);
            const currentData = branchDoc.data() || {};
            const weatherEvents = { ...currentData.weatherEvents, [this.currentYear]: this.weatherEvents };
            const coralSpawningEvents = {
                ...currentData.coralSpawningEvents,
                [this.currentYear]: this.coralSpawningEvents,
            };
            await updateDoc(branchRef, { weatherEvents, coralSpawningEvents });
            this.calculateOngoingAndUpcomingEvents();
            this.calculateWeatherLevels();
            this.calculateCoralSpawningLevels();
        },
        async savePDF() {
            // Save the timeline as a PDF
            this.showMenu = false;

            // Hide the dropdown menu
            if (this.$refs.dropdownMenu) this.$refs.dropdownMenu.style.display = 'none';

            // Create a wrapper div to hold the timeline
            const wrapper = document.createElement('div');
            wrapper.style.backgroundColor = 'white';
            wrapper.style.padding = '20px';

            // Clone the timeline container
            const timelineContainer = this.$refs.container.cloneNode(true);

            // Adjust styles for white background
            this.adjustStylesForPDF(timelineContainer);

            // Append the container to the wrapper
            wrapper.appendChild(timelineContainer);

            // Temporarily append the wrapper to the document body
            document.body.appendChild(wrapper);

            try {
                const canvas = await html2canvas(wrapper, {
                    backgroundColor: 'white',
                    scale: 2, // Increase resolution
                });

                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'px',
                    format: [canvas.width, canvas.height],
                });

                pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
                pdf.save('natural_schedule.pdf');
            } catch (error) {
                console.error('Error generating PDF:', error);
            } finally {
                // Remove the temporary wrapper
                document.body.removeChild(wrapper);

                // Show the dropdown menu again
                if (this.$refs.dropdownMenu) this.$refs.dropdownMenu.style.display = '';
            }
        },
        adjustStylesForPDF(element) {
            // Set the background to white
            element.style.backgroundColor = 'white';

            // Change text color to black
            element.querySelectorAll('text, span').forEach((textElement) => {
                textElement.style.fill = 'black';
                textElement.style.color = 'black';
            });

            // Change line colors to light gray
            element.querySelectorAll('line').forEach((line) => {
                if (line.getAttribute('stroke') === '#333333') {
                    line.setAttribute('stroke', '#e0e0e0');
                }
            });

            // Adjust select and button styles
            element.querySelectorAll('select, button').forEach((el) => {
                el.style.backgroundColor = '#f0f0f0';
                el.style.color = 'black';
            });

            // Remove the dropdown menu from the cloned element
            const dropdownMenu = element.querySelector('[ref="dropdownMenu"]');
            if (dropdownMenu) dropdownMenu.remove();
        },

        /* Other Helper Methods */
        adjustTimelineMonths() {
            // Adjust the timeline to start from the current month
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            const currentYear = currentDate.getFullYear();

            if (this.currentYear === currentYear && currentMonth >= 2) {
                this.adjustedStartMonth = Math.max(0, currentMonth - 2);
                this.adjustedYear = currentYear;
            } else {
                this.adjustedStartMonth = 0;
                this.adjustedYear = this.currentYear;
            }

            const monthsThisYear = this.months.slice(this.adjustedStartMonth);
            const monthsNextYear = this.months.slice(0, this.adjustedStartMonth);
            this.adjustedMonths = [...monthsThisYear, ...monthsNextYear];
        },
        initializeMoonPhases() {
            const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
            const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);

            for (let i = 0; i < this.fullMoonDates.length - 1; i++) {
                const fullMoon1 = this.fullMoonDates[i];
                const fullMoon2 = this.fullMoonDates[i + 1];

                if (fullMoon1 >= startOfTimeline && fullMoon1 <= endOfTimeline) {
                    this.moonPhases.full.push(fullMoon1);
                }

                const newMoon = new Date((fullMoon1.getTime() + fullMoon2.getTime()) / 2);
                if (newMoon >= startOfTimeline && newMoon <= endOfTimeline) {
                    this.moonPhases.new.push(newMoon);
                }

                const firstQuarter = new Date((newMoon.getTime() + fullMoon2.getTime()) / 2);
                if (firstQuarter >= startOfTimeline && firstQuarter <= endOfTimeline) {
                    this.moonPhases.first.push(firstQuarter);
                }

                const thirdQuarter = new Date((fullMoon1.getTime() + newMoon.getTime()) / 2);
                if (thirdQuarter >= startOfTimeline && thirdQuarter <= endOfTimeline) {
                    this.moonPhases.third.push(thirdQuarter);
                }
            }
        },
    },
};
</script>

<style scoped>
/* Scoped styles for the component */
.timeline-container {
    position: relative;
    width: 100%;
    overflow: visible;
}

.custom-tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    pointer-events: none;
    transform: translate(-50%, -100%);
    z-index: 1000;
    white-space: nowrap;
}

.custom-tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    max-height: 2000px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    max-height: 0;
    opacity: 0;
}

.edit-mode .draggable {
    cursor: move;
}

.draggable:active {
    cursor: grabbing;
}

.resize-handle {
    cursor: ew-resize;
}

.no-highlight {
    user-select: none;
}

.stroke-white {
    stroke: white;
}

.stroke-2 {
    stroke-width: 2px;
}

.weather-event {
    cursor: default;
}

.weather-event.edit-mode {
    cursor: move;
}

.weather-event.edit-mode:active {
    cursor: grabbing;
}

.coral-event {
    cursor: default;
}

.coral-event.edit-mode {
    cursor: move;
}

.coral-event.edit-mode:active {
    cursor: grabbing;
}

.resize-handle {
    cursor: ew-resize;
}
</style>
