<template>
    <div v-if="device" class="flex flex-col">
        <div class="flex items-center mb-4">
            <h1 class="text-2xl font-black font-lato">{{ device.id }}</h1>
            <!-- Badge -->
            <div class="ml-4 bg-black px-3 py-2 rounded-lg flex items-center text-xs">
                 <div :class="getStatusColorClass(latestTimestamp)" class="w-2 h-2 rounded-full mx-2"></div>
                <span>{{ formattedLatestTimestamp }}</span>
                <span class="ml-1">({{ formattedAge }})</span>
                <div class="ml-2 flex space-x-2">
                    <span v-if="device.sensors.internalTemperature">
                        <i class="mdi mdi-home-thermometer mr-1"></i>
                        {{ device.sensors.internalTemperature.toFixed(1) }}°C
                    </span>
                    <span v-if="device.sensors.internalHumidity">
                        <i class="mdi mdi-home-percent mr-1"></i>
                        {{ device.sensors.internalHumidity.toFixed(1) }}%
                    </span>
                    <span v-if="device.sensors.externalTemperature">
                            <i class="mdi mdi-water-thermometer mr-1"></i>
                        {{ device.sensors.externalTemperature.toFixed(1) }}°C
                    </span>
                </div>
            </div>
        </div>
        <!-- location card -->
        <div v-if="device.location" class="mb-4 bg-black p-3 rounded-lg">
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <i class="mdi mdi-map-marker text-xl text-neutral-400 mr-2"></i>
                    <div>
                        <p class="text-sm font-semibold">{{ locationName || 'Loading...' }}</p>
                        <p class="text-xs text-neutral-400">
                            {{ device.location._lat.toFixed(6) }}, {{ device.location._long.toFixed(6) }}
                        </p>
                    </div>
                </div>
                <button @click="toggleLocationMap" class="text-white p-1 rounded hover:bg-gray-700 transition-transform duration-300 ease-in-out" :class="{ 'transform rotate-180': showLocationMap }">
                    <i class="text-lg mdi mdi-chevron-down"></i>
                </button>
            </div>
            <div class="mt-3" :style="{ height: showLocationMap ? '200px' : '0', overflow: 'hidden', transition: 'height 0.5s ease-in-out' }">
                <div ref="locationMap" style="height: 100%;"></div>
            </div>
        </div>
        <div class="flex flex-col md:flex-row mb-4">
            <div class="w-full md:w-1/2 md:pr-2 mb-4 md:mb-0">
                <DeviceImages :device="device" />
            </div>
            <div class="w-full md:w-1/2 md:pl-2">
                <DeviceAudio :device="device" />
            </div>
        </div>
        <div class="flex flex-col md:flex-row mb-4">
            <div class="w-full md:w-1/2 md:pr-2 mb-4 md:mb-0">
                <DeviceTemp :deviceId="deviceId" />
            </div>
            <div class="w-full md:w-1/2 md:pl-2">
                <DeviceFish :deviceId="deviceId" />
            </div>
        </div>
        <!-- <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
            <div v-for="(url, index) in axisUrls" :key="'axis-'+index" class="w-full relative">
                <img :src="url" alt="Axis Image" class="w-full h-auto object-cover rounded">
                <div class="absolute bottom-1 right-1 flex items-center text-xs bg-black/50 px-2 py-1 rounded-full text-white">
                    <i class="mdi mdi-clock-outline mr-1"></i>
                    <span>{{ formatUrlTimestamp(url) }}</span>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { db_map } from '../main';
import { doc, getDoc } from 'firebase/firestore';
import DeviceTemp from './DeviceTemp.vue';
import DeviceImages from './DeviceImages.vue';
import DeviceAudio from './DeviceAudio.vue';
import DeviceFish from './DeviceFish.vue';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
    name: 'Device',
    components: {
        DeviceTemp,
        DeviceImages,
        DeviceAudio,
        DeviceFish
    },
    props: {
        deviceId: String
    },
    data() {
        return {
            device: null,
            axisUrls: [],
            zedUrls: [],
            audioUrls: [],
            locationName: null,
            showLocationMap: false,
            locationMap: null,
            deviceMarker: null,
        }
    },
    mounted() {
        this.getDevice();
        // this.getPublicUrls();
    },
    methods: {
        async getDevice() {
            const docRef = doc(db_map, 'Cameras', this.deviceId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                this.device = docSnap.data();
                if (this.device.location) {
                    this.getLocationName();
                }
            } else {
                console.log('No such document!');
            }
        },
        async getLocationName() {
            const { _lat, _long } = this.device.location;
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${_lat}&lon=${_long}`);
                const data = await response.json(); 
                this.locationName = data.display_name;
            } catch (error) {
                console.error('Error fetching location name:', error);
                this.locationName = 'Location unavailable';
            }
        },
        async getPublicUrls() {
            try {
                const response = await fetch(`https://us-central1-cg-reef-manager.cloudfunctions.net/getPublicUrls?deviceID=${this.deviceId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                
                this.axisUrls = this.sortUrlsByTimestamp(data.axis)
                this.zedUrls = this.sortUrlsByTimestamp(data.zed)
                this.audioUrls = this.sortUrlsByTimestamp(data.audio)
            } catch (error) {
                console.error('Error fetching public URLs:', error);
                this.axisUrls = [];
                this.zedUrls = [];
                this.audioUrls = []; 
            }
        },
        sortUrlsByTimestamp(urls) {
            return urls.sort((a, b) => {
                const timestampA = this.extractTimestamp(a);
                const timestampB = this.extractTimestamp(b);
                return timestampB - timestampA; 
            });
        },
        extractTimestamp(url) {
            const timestamp = url.split('/').pop().split('.')[0];
            const parts = timestamp.split('_');
            if (parts.length !== 6) {
                return NaN;
            }
            const [month, day, year, hour, minute, second] = parts;
            if (parseInt(month) < 1 || parseInt(month) > 12) {
                return NaN;
            }
            const dateString = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
                return NaN;
            }
            return date.getTime();
        },
        formatLabel(name) {
            return name
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, str => str.toUpperCase());
        },
        formatUrlTimestamp(url) {
            const timestamp = url.split('/').pop().split('.')[0];
            const [day, month, year, hour, minute, second] = timestamp.split('_');
            return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
        },
        getStatusColorClass(timestamp) {
            if (!timestamp) return 'bg-gray-400';
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            const twoDaysAgo = new Date(today);
            twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

            if (timestamp >= today) return 'bg-green-500';
            if (timestamp >= yesterday) return 'bg-yellow-500';
            if (timestamp >= twoDaysAgo) return 'bg-red-500';
            return 'bg-red-500';
        },
        formatTimestamp(timestamp) {
            if (!timestamp) return 'N/A';
            return new Date(timestamp).toLocaleString();
        },
        toggleLocationMap() {
            this.showLocationMap = !this.showLocationMap;
            if (this.showLocationMap) {
                this.$nextTick(() => {
                    if (!this.locationMap) {
                        this.initLocationMap();
                    } else {
                        this.locationMap.invalidateSize();
                    }
                    setTimeout(() => {
                        this.centerMap();
                    }, 500);
                });
            }
        },
        initLocationMap() {
            if (!this.locationMap) {
                this.locationMap = L.map(this.$refs.locationMap, {
                    zoomControl: false,
                    attributionControl: false
                });
                
                const mapboxAccessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
                L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + mapboxAccessToken, {
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.locationMap);

                this.addDeviceMarker();
            }
        },
        centerMap() {
            if (this.locationMap && this.device.location) {
                this.locationMap.invalidateSize();
                this.locationMap.setView([this.device.location._lat, this.device.location._long], 15);
            }
        },
        createPulsingIcon() {
            return L.divIcon({
                className: 'pulsing-icon',
                iconSize: [5, 5],
                iconAnchor: [2.5, 2.5]
            });
        },
        addDeviceMarker() {
            if (this.deviceMarker) {
                this.deviceMarker.remove();
            }

            this.deviceMarker = L.marker([this.device.location._lat, this.device.location._long], {
                icon: this.createPulsingIcon(),
                zIndexOffset: 1000
            }).addTo(this.locationMap);

            this.deviceMarker.bindPopup(`<h3>${this.device.id || 'Device'}</h3>`);
        },
    },
    computed: {
        formattedSensors() {
            if (!this.device || !this.device.sensors) return [];

            return Object.entries(this.device.sensors)
                .filter(([name]) => name.toLowerCase() !== 'time')
                .map(([name, value]) => {
                    let formattedValue = value;
                    let label = this.formatLabel(name);

                    if (typeof value === 'number') {
                        formattedValue = value.toFixed(2);
                    } else if (value && value.seconds) {
                        formattedValue = this.formatTimestamp(value.seconds * 1000);
                    }

                    return { name, label, formattedValue };
                });
        },
        latestTimestamp() {
            if (!this.device || !this.device.sensors) return null;
            const timestamps = Object.values(this.device.sensors)
                .filter(value => value && value.seconds)
                .map(value => value.seconds * 1000);
            return Math.max(...timestamps);
        },
        formattedLatestTimestamp() {
            return this.formatTimestamp(this.latestTimestamp);
        },
        formattedAge() {
            if (!this.latestTimestamp) return 'N/A';
            const now = new Date();
            const timestamp = new Date(this.latestTimestamp);
            const diffInHours = Math.floor((now - timestamp) / (1000 * 60 * 60));
            const diffInDays = Math.floor(diffInHours / 24);

            if (diffInHours < 1) return '<1h';
            if (diffInHours < 24) return `${diffInHours}h`;
            if (diffInDays < 7) return `${diffInDays}d`;
            if (diffInDays < 30) return `${Math.floor(diffInDays / 7)}w`;
            return `${Math.floor(diffInDays / 30)}m`;
        }
    }
}
</script>

<style scoped>
.pulsing-icon {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 0, 0, 1);
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 0 rgba(255, 0, 0, 1);
    animation: pulse 5s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }

    50% {
        box-shadow: 0 0 15px 15px rgba(255, 0, 0, 0);
        opacity: 0.7;
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }
}
</style>