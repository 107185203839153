<template>
    <div :class="['bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-80']">
        <!-- Header Section -->
        <div class="flex justify-between items-center mb-1">
            <h1 class="title text-left text-lg font-black font-lato">{{ chartTitle }}</h1>
            <div class="flex items-center">
                <!-- Updated Dropdown for Species Selection -->
                <select v-if="allOptions.length > 0" v-model="selectedOption" @change="createChart"
                    :class="['bg-neutral-800 hover:bg-primary text-white font py-2 px-4 rounded text-xs', isExpanded ? 'w-48' : 'w-32']">
                    <option value="average">Average</option>
                    <option value="all">All Species</option>
                    <option value="allGenera">All Genera</option>
                    <optgroup label="Genera">
                        <option v-for="genus in generaList" :key="genus" :value="`genus:${genus}`">
                            {{ genus }}
                        </option>
                    </optgroup>
                    <optgroup label="Species">
                        <option v-for="species in speciesList" :key="species" :value="species">
                            {{ species }}
                        </option>
                    </optgroup>
                </select>
                <!-- Toggle Expand/Close Button -->
                <button @click="toggleExpand" class="ml-2 text-white hover:text-primary">
                   <span :class="['cursor-pointer', isExpanded ? 'mdi mdi-unfold-less-vertical text-xl' : 'mdi mdi-unfold-more-vertical']"></span>
                </button>
            </div>
        </div>

        <!-- Value Display -->
        <div class="text-3xl font-black font-lato">
            {{ displayValue }}<span v-if="unit" class="font-normal text-sm"> {{ unit }}</span>
        </div>

        <!-- Trend Analysis Display -->
        <div v-if="trendAnalysis" :class="[
            'mb-1 text-sm',
            { 'text-green-500': isTrendPositive },
            { 'text-red-500': !isTrendPositive }
        ]">
            {{ trendAnalysis.percentChange > 0 ? '+' : '' }}{{ trendAnalysis.percentChange.toFixed(1) }}%
            <span class="text-neutral-400 ml-1">since {{ startDate }}</span>
        </div>

        <!-- Chart Container -->
        <div class="chart-container flex-grow relative flex justify-around items-center h-1/2">
            <canvas ref="chartRef" class="max-w-full max-h-full"></canvas>
        </div>
    </div>
</template>

<script>
import {
    Chart, LineController, LineElement, PointElement, CategoryScale, LinearScale,
    Tooltip, Legend, Filler
} from 'chart.js';
import { calculateTrendAnalysis, createTrendlineDataset } from '@/assets/js/utils/trendAnalysis';

Chart.register(LineController, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend, Filler);

export default {
    name: 'NurseryChart',
    props: {
        data: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true,
            validator(value) {
                return ['alive', 'EVI', 'bleach', 'health'].includes(value);
            }
        },
        unit: {
            type: String,
            default: ''
        },
        chartTitle: {
            type: String,
            required: true
        },
        percent: {
            type: Boolean,
            default: false
        },
        flipTrend: {
            type: Boolean,
            default: false
        },
        maxY: {
            type: Number,
            default: null
        },
        isExpanded: { 
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chart: null,
            selectedOption: 'average', // Changed from selectedSpecies to selectedOption
            trendAnalysis: null,
            startDate: '',
            isExpandedInternal: false,
        };
    },
    computed: {
        // Extract history data based on the type prop
        historyData() {
            return this.data.nursery.history[this.type] || {};
        },
        // Generate a list of unique species from spp_history
        speciesList() {
            const sppHistory = this.data.nursery.spp_history || [];
            const speciesSet = new Set();
            sppHistory.forEach(entry => {
                if (entry.Species) {
                    speciesSet.add(entry.Species);
                }
            });
            return Array.from(speciesSet).sort();
        },
        // New computed property for all options
        allOptions() {
            return ['average', 'all', 'allGenera', ...this.generaList.map(g => `genus:${g}`), ...this.speciesList];
        },
        // New computed property for genera list
        generaList() {
            const generaSet = new Set();
            this.data.nursery.spp_history.forEach(entry => {
                if (entry.Species) {
                    const genus = entry.Species.split(' ')[0];
                    generaSet.add(genus);
                }
            });
            return Array.from(generaSet).sort();
        },
        // Updated displayValue computed property
        displayValue() {
            if (['average', 'all', 'allGenera'].includes(this.selectedOption)) {
                const values = Object.values(this.historyData).filter(v => v !== null);
                if (values.length === 0) return 'N/A';
                const latest = values[values.length - 1];
                return this.percent ? (latest * 100).toFixed(1) : latest.toFixed(1);
            } else if (this.selectedOption.startsWith('genus:')) {
                const genus = this.selectedOption.split(':')[1];
                const genusData = this.genusData[genus];
                if (!genusData || genusData.length === 0) return 'N/A';
                const latest = genusData[genusData.length - 1].value;
                return this.percent ? (latest * 100).toFixed(1) : latest.toFixed(1);
            } else {
                const speciesHistory = this.filteredSpeciesHistory;
                if (speciesHistory.length === 0) return 'N/A';
                const latestEntry = speciesHistory[speciesHistory.length - 1];
                let value = latestEntry.value;
                return this.percent ? (value * 100).toFixed(1) : value.toFixed(1);
            }
        },
        // Determine if trend is positive based on trendAnalysis
        isTrendPositive() {
            if (!this.trendAnalysis) return false;
            const trend = this.trendAnalysis.percentChange;
            return this.flipTrend ? trend < 0 : trend >= 0;
        },
        // Filter spp_history based on selected species and type
        filteredSpeciesHistory() {
            const sppHistory = this.data.nursery.spp_history || [];
            return sppHistory
                .filter(entry => {
                    if (['health', 'EVI', 'alive'].includes(this.type) && entry.MonitoringType !== 'full') {
                        return false;
                    }
                    return entry.Species === this.selectedOption && entry[this.type] !== null;
                })
                .map(entry => ({
                    date: entry.Date,
                    value: entry[this.type]
                }))
                .sort((a, b) => new Date(a.date) - new Date(b.date));
        },
        // Add a new computed property for all species data
        allSpeciesData() {
            const sppHistory = this.data.nursery.spp_history || [];
            const speciesData = {};

            sppHistory.forEach(entry => {
                if (['health', 'EVI', 'alive'].includes(this.type) && entry.MonitoringType !== 'full') {
                    return;
                }
                if (entry[this.type] !== null) {
                    if (!speciesData[entry.Species]) {
                        speciesData[entry.Species] = [];
                    }
                    speciesData[entry.Species].push({
                        date: entry.Date,
                        value: this.percent ? entry[this.type] * 100 : entry[this.type]
                    });
                }
            });

            // Sort data for each species
            Object.values(speciesData).forEach(data => {
                data.sort((a, b) => new Date(a.date) - new Date(b.date));
            });

            return speciesData;
        },
        // New computed property for genus data
        genusData() {
            const genusData = {};
            this.data.nursery.spp_history.forEach(entry => {
                if (['health', 'EVI', 'alive'].includes(this.type) && entry.MonitoringType !== 'full') {
                    return;
                }
                if (entry[this.type] !== null && entry.Species) {
                    const genus = entry.Species.split(' ')[0];
                    if (!genusData[genus]) {
                        genusData[genus] = [];
                    }
                    genusData[genus].push({
                        date: entry.Date,
                        value: this.percent ? entry[this.type] * 100 : entry[this.type]
                    });
                }
            });

            // Sort and average data for each genus
            Object.keys(genusData).forEach(genus => {
                genusData[genus].sort((a, b) => new Date(a.date) - new Date(b.date));
                const averagedData = {};
                genusData[genus].forEach(entry => {
                    if (!averagedData[entry.date]) {
                        averagedData[entry.date] = { sum: 0, count: 0 };
                    }
                    averagedData[entry.date].sum += entry.value;
                    averagedData[entry.date].count++;
                });
                genusData[genus] = Object.entries(averagedData).map(([date, data]) => ({
                    date,
                    value: data.sum / data.count
                }));
            });

            return genusData;
        }
    },
    watch: {
        data: {
            handler() {
                this.createChart();
            },
            deep: true
        },
        type() {
            this.createChart();
        },
        selectedOption() {
            this.createChart();
        }
    },
    mounted() {
        this.createChart();
    },
    methods: {
        // Toggle Expand/Close
        toggleExpand() {
            if (this.isExpanded) {
                this.$emit('close-chart');
            } else {
                this.$emit('expand-chart', {
                    type: this.type,
                    data: this.data,
                    chartTitle: this.chartTitle,
                    unit: this.unit,
                    percent: this.percent,
                    flipTrend: this.flipTrend,
                    maxY: this.maxY
                });
            }
        },
        // Updated createChart method
        createChart() {
            if (this.chart) this.chart.destroy();

            const ctx = this.$refs.chartRef.getContext('2d');

            if (this.selectedOption === 'average') {
                this.createHistoryChart(ctx);
            } else if (this.selectedOption === 'all') {
                this.createAllSpeciesChart(ctx);
            } else if (this.selectedOption === 'allGenera') {
                this.createAllGeneraChart(ctx);
            } else if (this.selectedOption.startsWith('genus:')) {
                this.createGenusChart(ctx);
            } else {
                this.createSpeciesChart(ctx);
            }
        },
        // Create chart for All Species (History Data)
        createHistoryChart(ctx) {
            const dataPoints = Object.entries(this.historyData)
                .filter(([_, value]) => value !== null)
                .map(([date, value]) => ({
                    x: new Date(date),
                    y: this.percent ? value * 100 : value
                }))
                .sort((a, b) => a.x - b.x);  // Ensure data is sorted by date

            if (dataPoints.length === 0) {
                this.chart = null;
                return;
            }

            this.startDate = dataPoints[0].x.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' });

            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: [{
                        label: this.chartTitle,
                        data: dataPoints,
                        borderColor: '#27bdf4',
                        backgroundColor: 'rgba(39, 189, 244, 0.2)',
                        borderWidth: 2,
                        fill: true,
                        pointRadius: 3,
                        pointBackgroundColor: '#27bdf4',
                        pointBorderColor: '#fff',
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: '#27bdf4',
                        tension: 0.4,
                        spanGaps: true 
                    }]
                },
                options: this.getHistoryChartOptions()
            });

            // Add trendline
            if (dataPoints.length < 2) return;
            this.trendAnalysis = calculateTrendAnalysis(dataPoints);
            const trendlineDataset = createTrendlineDataset('Trend', this.trendAnalysis.trendlineData);
            this.chart.data.datasets.push(trendlineDataset);
            this.chart.update();
        },
        // Create chart for a specific species
        createSpeciesChart(ctx) {
            const speciesHistory = this.filteredSpeciesHistory;

            if (speciesHistory.length === 0) {
                this.chart = null;
                return;
            }

            const dataPoints = speciesHistory.map(entry => ({
                x: new Date(entry.date),
                y: this.percent ? entry.value * 100 : entry.value
            }));

            this.startDate = dataPoints[0].x.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' });

            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: [{
                        label: this.chartTitle,
                        data: dataPoints,
                        borderColor: '#1e95c2',
                        backgroundColor: 'rgba(30, 149, 194, 0.2)',
                        borderWidth: 2,
                        fill: true,
                        pointRadius: 3,
                        pointBackgroundColor: '#1e95c2',
                        pointBorderColor: '#fff',
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: '#1e95c2',
                        tension: 0.4,
                        spanGaps: true
                    }]
                },
                options: this.getHistoryChartOptions()
            });

            // Add trendline
            if (speciesHistory.length < 2) return;
            this.trendAnalysis = calculateTrendAnalysis(dataPoints);
            const trendlineDataset = createTrendlineDataset('Trend', this.trendAnalysis.trendlineData);
            this.chart.data.datasets.push(trendlineDataset);

            this.chart.update();
        },
        // Add new method for creating All Species chart
        createAllSpeciesChart(ctx) {
            const allSpeciesData = this.allSpeciesData;
            const datasets = [];
            const colors = ['#27bdf4', '#f4276b', '#27f48f', '#f4c927', '#8f27f4', '#f49327', '#27f4e0', '#d927f4'];

            Object.entries(allSpeciesData).forEach(([species, data], index) => {
                datasets.push({
                    label: species,
                    data: data.map(d => ({ x: new Date(d.date), y: d.value })),
                    borderColor: colors[index % colors.length],
                    backgroundColor: `${colors[index % colors.length]}33`,
                    borderWidth: 2,
                    fill: false,
                    pointRadius: 3,
                    pointBackgroundColor: colors[index % colors.length],
                    pointBorderColor: '#fff',
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: colors[index % colors.length],
                    tension: 0.4,
                    spanGaps: true
                });
            });

            this.chart = new Chart(ctx, {
                type: 'line',
                data: { datasets },
                options: this.getAllSpeciesChartOptions()
            });

            // Set startDate to the earliest date across all species
            const allDates = datasets.flatMap(d => d.data.map(point => point.x));
            this.startDate = new Date(Math.min(...allDates)).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' });
        },
        // New method for creating All Genera chart
        createAllGeneraChart(ctx) {
            const datasets = [];
            const colors = ['#27bdf4', '#f4276b', '#27f48f', '#f4c927', '#8f27f4', '#f49327', '#27f4e0', '#d927f4'];

            Object.entries(this.genusData).forEach(([genus, data], index) => {
                datasets.push({
                    label: genus,
                    data: data.map(d => ({ x: new Date(d.date), y: d.value })),
                    borderColor: colors[index % colors.length],
                    backgroundColor: `${colors[index % colors.length]}33`,
                    borderWidth: 2,
                    fill: false,
                    pointRadius: 3,
                    pointBackgroundColor: colors[index % colors.length],
                    pointBorderColor: '#fff',
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: colors[index % colors.length],
                    tension: 0.4,
                    spanGaps: true
                });
            });

            this.chart = new Chart(ctx, {
                type: 'line',
                data: { datasets },
                options: this.getAllSpeciesChartOptions()
            });

            // Set startDate to the earliest date across all genera
            const allDates = datasets.flatMap(d => d.data.map(point => point.x));
            this.startDate = new Date(Math.min(...allDates)).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' });
        },
        // New method for creating Genus chart
        createGenusChart(ctx) {
            const genus = this.selectedOption.split(':')[1];
            const genusData = this.genusData[genus];

            if (!genusData || genusData.length === 0) {
                this.chart = null;
                return;
            }

            const dataPoints = genusData.map(entry => ({
                x: new Date(entry.date),
                y: entry.value
            }));

            this.startDate = dataPoints[0].x.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' });

            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: [{
                        label: `${genus} Average`,
                        data: dataPoints,
                        borderColor: '#1e95c2',
                        backgroundColor: 'rgba(30, 149, 194, 0.2)',
                        borderWidth: 2,
                        fill: true,
                        pointRadius: 3,
                        pointBackgroundColor: '#1e95c2',
                        pointBorderColor: '#fff',
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: '#1e95c2',
                        tension: 0.4,
                        spanGaps: true
                    }]
                },
                options: this.getHistoryChartOptions()
            });

            // Add trendline
            if (genusData.length < 2) return;
            this.trendAnalysis = calculateTrendAnalysis(dataPoints);
            const trendlineDataset = createTrendlineDataset('Trend', this.trendAnalysis.trendlineData);
            this.chart.data.datasets.push(trendlineDataset);

            this.chart.update();
        },
        // Chart options for both history and species charts
        getHistoryChartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                plugins: {
                    legend: { display: false },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            title: () => '',
                            label: (context) => {
                                if (this.selectedOption === 'all' || this.selectedOption === 'allGenera') {
                                    const label = context.dataset.label || '';
                                    const value = context.formattedValue;
                                    return `${label}: ${value}`;
                                } else {
                                    const value = context.formattedValue;
                                    return `${value}`;
                                }
                            }
                        }
                    },
                    filler: { propagate: true }
                },
                scales: {
                    x: {
                        type: 'time',  
                        time: {
                            unit: 'month', 
                            displayFormats: {
                                month: 'MMM yy'
                            }
                        },
                        title: { display: false },
                        ticks: {
                            color: '#FFFFFF',
                            maxRotation: 0,
                            minRotation: 0,
                            maxTicksLimit: 5,
                        },
                        grid: this.getGridOptions()
                    },
                    y: this.getYAxisOptions()
                }
            };
        },
        // Add new method for All Species chart options
        getAllSpeciesChartOptions() {
            const options = this.getHistoryChartOptions();
            options.scales.x.type = 'time';
            options.scales.x.time = {
                unit: 'month',
                displayFormats: {
                    month: 'MMM yy'
                }
            };
            options.plugins.legend.display = false;
            options.plugins.tooltip.callbacks.label = (context) => {
                const label = context.dataset.label || '';
                const value = context.formattedValue;
                return `${label}: ${value}`;
            };
            return options;
        },
        // Y-Axis configuration
        getYAxisOptions() {
            return {
                beginAtZero: true,
                min: 0,
                max: this.maxY,
                title: {
                    display: false,
                    text: this.chartTitle,
                    color: '#FFFFFF',
                },
                ticks: { color: '#FFFFFF' },
                grid: this.getGridOptions()
            };
        },
        // Grid configuration for both axes
        getGridOptions() {
            return {
                color: 'rgba(255, 255, 255, 0.2)',
                borderColor: '#FFFFFF',
                borderWidth: 1,
                tickColor: '#FFFFFF',
                tickBorderDash: [5, 5]
            };
        }
    }
};
</script>

<style scoped>
</style>