<template>
  <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col space-y-4 mb-4">
    <!-- Title, Ongoing events, Upcoming events, and Expand/Collapse toggle -->
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <h3 class="text-lg font-black font-lato mr-1">{{ title }} Schedule</h3>
        <!-- Add the three-dot menu -->
        <div class="relative">
          <button @click="toggleMenu" class="text-white hover:text-gray-300 transition-colors duration-300">
            <i class="mdi mdi-dots-vertical text-xl"></i>
          </button>
          <div v-if="showMenu" class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem" @click.prevent="toggleEditMode">
                <i :class="editMode ? 'mdi mdi-pencil-off' : 'mdi mdi-pencil'" class="mr-2"></i>
                {{ editMode ? 'Disable Edit Weather' : 'Edit Weather' }}
              </a>
              <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem" @click.prevent="savePDF">
                <i class="mdi mdi-file-pdf-box mr-2"></i>Save as PDF
              </a>
            </div>
          </div>
        </div>
      </div>
      <button @click="toggleExpanded" class="text-white p-1 rounded hover:bg-gray-700 transition-transform duration-300 ease-in-out" :class="{ 'transform rotate-180': expanded }">
        <i class="text-lg mdi mdi-chevron-down"></i>
      </button>
    </div>
    
    <!-- Ongoing events -->
    <div v-if="ongoingEvents.length > 0">
      <h4 class="text-sm font-semibold mb-2">Ongoing events</h4>
      <div class="flex flex-wrap justify-start">
        <div v-for="(event, index) in ongoingEvents" :key="index" class="flex items-center mr-4 mb-2">
          <div class="w-1 h-4 rounded-full mr-2" :style="{ backgroundColor: getEventColor(event) }"></div>
          <span class="text-xs">
            {{ stripTrailingDigits(event.name) }} 
            <strong>{{ event.isWeather ? 'Weather' : event.siteName }}</strong> 
            ({{ formatDuration(event.remainingDuration) }} left)
          </span>
        </div>
      </div>
    </div>

    <!-- Upcoming events -->
    <div v-if="upcomingEvents.length > 0">
      <h4 class="text-sm font-semibold mb-2">Upcoming events</h4>
      <div class="flex flex-wrap justify-start">
        <div v-for="(event, index) in upcomingEvents" :key="index" class="flex items-center mr-4 mb-2">
          <div class="w-1 h-4 rounded-full mr-2" :style="{ backgroundColor: getEventColor(event) }"></div>
          <span class="text-xs">
            {{ stripTrailingDigits(event.name) }} 
            <strong>{{ event.isWeather ? 'Weather' : event.siteName }}</strong> 
            in {{ event.timeUntil }}
          </span>
        </div>
      </div>
    </div>

    <!-- Expanded view with slide transition -->
    <transition name="slide">
      <div v-if="expanded">
        <div class="timeline-container relative" :style="{ height: `${totalHeight}px` }" ref="container">
          <svg :key="svgKey" :width="timelineWidth" :height="svgHeight">
            <!-- Add this inside the <svg> element, just after the opening tag -->
            <defs>
              <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                <path d="M-1,1 l2,-2
                         M0,4 l4,-4
                         M3,5 l2,-2" 
                  style="stroke:#888; stroke-width:1" />
              </pattern>
            </defs>

            <!-- Grid lines -->
            <g>
              <!-- Horizontal site lines -->
              <line v-for="index in siteSchedules.length" :key="'row-line-' + (index - 1)"
                  :x1="labelWidth" :y1="topPadding + index * siteHeight"
                  :x2="timelineWidth - padding" :y2="topPadding + index * siteHeight"
                  stroke="#333333" stroke-width="1" opacity="0.5" />
              <!-- Top line -->
              <line :x1="labelWidth" :y1="topPadding" :x2="timelineWidth - padding" :y2="topPadding"
                  stroke="#333333" stroke-width="1" opacity="0.5" />

              <!-- Vertical month lines -->
              <line v-for="(month, index) in adjustedMonths" :key="'grid-' + month"
                  :x1="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)" :y1="topPadding"
                  :x2="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)" :y2="totalHeight - bottomPadding"
                  stroke="#333333" stroke-width="1" />
            </g>

            <!-- Timeline -->
            <line :x1="labelWidth" :y1="totalHeight - bottomPadding" :x2="timelineWidth - padding" :y2="totalHeight - bottomPadding"
                stroke="white" stroke-width="1" />

            <!-- Month ticks -->
            <line v-for="(month, index) in adjustedMonths" :key="'tick-' + month"
                :x1="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)" :y1="totalHeight - bottomPadding"
                :x2="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)" :y2="totalHeight - bottomPadding - 5"
                stroke="white" stroke-width="1" />

            <!-- Month labels -->
            <text v-for="(month, index) in adjustedMonths" :key="month"
                :x="labelWidth + (index * (timelineWidth - labelWidth - padding) / 12)" :y="totalHeight - 20"
                text-anchor="middle" font-size="12" fill="white">
                {{ month }}
            </text>

            <!-- Add this rectangle for the gray overlay with diagonal lines, just before the "Regular Events" group -->
            <rect v-if="currentDateX !== null"
                  :x="labelWidth" :y="topPadding" 
                  :width="currentDateX - labelWidth" 
                  :height="totalHeight - topPadding - bottomPadding" 
                  fill="url(#diagonalHatch)" 
                  fill-opacity="0.4" />

            <!-- Site schedules -->
            <g v-for="(siteSchedule, siteIndex) in siteSchedules" :key="siteSchedule.siteId">
                <!-- Site label -->
                <text :x="0" :y="getSiteY(siteIndex) + siteHeight / 2 + topPadding + 5" font-size="12" fill="white"
                    text-anchor="middle" transform="rotate(-90, 0, 0)" :transform-origin="`0 ${getSiteY(siteIndex) + siteHeight / 2 + topPadding}`">
                    {{ getShortSiteName(siteSchedule.siteName) }}
                </text>

                <!-- Vertical color bar for site -->
                <rect :x="labelWidth - 10" :y="getSiteY(siteIndex) + topPadding" :width="3" :height="siteHeight"
                    :fill="getSiteColor(siteIndex)" rx="1.5" />

                <!-- Events for each site -->
                <g v-for="(event, eventIndex) in siteSchedule.visibleEvents" :key="event.name + eventIndex">
                    <rect v-if="isEventVisible(event)" :x="getEventX(event)" :y="getEventY(siteIndex, event)"
                        :width="Math.max(getEventWidth(event), 2)" :height="10" :fill="getEventColor(event)" rx="2"
                        @mouseenter="showTooltip($event, event, siteIndex)"
                        @mouseleave="hideTooltip" />
                </g>
            </g>

            <!-- Weather events -->
            <g>
              <rect :x="labelWidth" :y="totalHeight - bottomPadding - weatherSectionHeight" 
                    :width="timelineWidth - labelWidth - padding" :height="weatherSectionHeight"
                    fill="rgba(169, 169, 169, 0.1)" rx="2" />
              <!-- Add this line for the color bar -->
              <rect :x="labelWidth - 10" :y="totalHeight - bottomPadding - weatherSectionHeight" :width="3" :height="weatherSectionHeight"
                    fill="#4A4A4A" rx="1.5" />
              <g v-for="(event, eventIndex) in weatherEvents" :key="'weather-' + eventIndex"
                 :class="{ 'weather-event': true, 'edit-mode': editMode }"
                 @mousedown="onDragStart($event, event, true)" @click="selectEvent(event, true)">
                <rect v-if="isEventVisible(event)" :x="getEventX(event)" :y="getWeatherEventY(event)"
                    :width="Math.max(getEventWidth(event), 2)" :height="weatherEventHeight - 4" fill="#4A4A4A" rx="2" 
                    :class="{ 'stroke-white stroke-2': event === selectedEvent && editMode }" />
                <text v-if="isEventVisible(event) && getEventWidth(event) > 50"
                      :x="getEventX(event) + Math.max(getEventWidth(event), 2) / 2" 
                      :y="getWeatherEventY(event) + weatherEventHeight / 2 - 2"
                      fill="white" font-size="10" text-anchor="middle" dominant-baseline="middle" class="no-highlight">
                  {{ event.name }}
                </text>
                <rect v-if="isEventVisible(event) && editMode" :x="getEventX(event) + Math.max(getEventWidth(event), 2) - 5"
                    :y="getWeatherEventY(event)" width="5" :height="weatherEventHeight - 4" fill="white" rx="2"
                    @mousedown.stop="onResizeStart($event, event, true)" class="resize-handle" />
              </g>
            </g>

            <!-- Add weather event button -->
            <g v-if="editMode">
              <rect :x="timelineWidth - 25" :y="totalHeight - bottomPadding - weatherSectionHeight / 2  - 10" width="20" height="20" rx="4" 
                    fill="#212936" class="cursor-pointer hover:fill-opacity-80" @click="addWeatherEvent" />
              <text :x="timelineWidth - 15" :y="totalHeight - bottomPadding - weatherSectionHeight / 2" text-anchor="middle" 
                    font-size="16" fill="white" class="cursor-pointer select-none" @click="addWeatherEvent">+</text>
            </g>

            <!-- Move the current date line and triangle to the end of the SVG content -->
            <!-- Current date line and triangle -->
            <g v-if="currentDateX !== null">
              <line :x1="currentDateX" :y1="topPadding" :x2="currentDateX" :y2="totalHeight - bottomPadding" stroke="red"
                  stroke-width="1" />
              <polygon
                  :points="`${currentDateX - 5},${totalHeight - bottomPadding} ${currentDateX + 5},${totalHeight - bottomPadding} ${currentDateX},${totalHeight - bottomPadding - 5}`"
                  fill="red" />
            </g>
          </svg>
          <!-- Custom Tooltip -->
          <div v-if="tooltipVisible" class="custom-tooltip" :style="tooltipStyle" v-html="tooltipContent"></div>
        </div>

        <!-- Color-coded legend -->
        <div class="flex justify-between items-end" ref="legendContainer">
          <div class="flex flex-wrap flex-grow">
            <div v-for="(row, index) in rows" :key="index" class="flex items-center mb-2 mr-3">
              <div class="w-3 h-3 rounded-full mr-1"
                :style="{ backgroundColor: eventColors[index % eventColors.length] }"></div>
              <span class="text-xs">{{ row }}</span>
            </div>
            <div class="flex items-center mb-2 mr-3">
              <div class="w-3 h-3 rounded-full mr-1" style="backgroundColor: #4A4A4A"></div>
              <span class="text-xs">Weather</span>
            </div>
          </div>
        </div>

        <!-- Event editing card -->
        <div v-if="selectedEvent" class="bg-gray-800 p-2 mt-4 rounded-lg text-sm max-w-[200px]">
          <div class="flex items-center mb-2">
            <h3 v-if="!isEditing" class="text-md font-black font-lato mr-2">{{ selectedEvent.name }}</h3>
            <input
              v-else
              v-model="editedName"
              class="text-md font-black font-lato mr-2 bg-gray-700 text-white px-2 py-1 rounded"
            >
            <button @click="toggleEdit" v-if="!isEditing" class="text-white hover:text-gray-300">
              <span class="mdi mdi-pencil"></span>
            </button>
            <button @click="confirmEdit" v-else class="text-white hover:text-gray-300">
              <span class="mdi mdi-check"></span>
            </button>
          </div>
          <p>{{ formatDate(getEventStartDate(selectedEvent)) }} to {{ formatDate(getEventEndDate(selectedEvent)) }}</p>
          <p>({{ formatDuration(selectedEvent.duration) }})</p>
          <div class="mt-2 flex justify-end">
            <button @click="saveChanges" class="bg-green-500 hover:bg-green-600 text-white px-2 rounded mr-2">
              ✓
            </button>
            <!-- <button @click="cancelChanges" class="bg-gray-500 hover:bg-gray-600 text-white px-2 rounded mr-2">
              ✗
            </button> -->
            <button @click="deleteSelectedEvent" class="bg-red-500 hover:bg-red-600 text-white px-2 rounded">
              <span class="mdi mdi-trash-can-outline"></span>
            </button>
          </div>
        </div>
      </div>
    </transition>

    <!-- Add the "Done" button for edit mode -->
    <div v-if="editMode" class="mt-4 flex justify-end">
      <button @click="toggleEditMode" class="bg-gray-500 hover:bg-gray-600 text-white px-3 py-1 rounded text-sm">
        Done
      </button>
    </div>
  </div>
</template>

<script>
import { db_resto } from '../main';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
  name: 'BranchSchedule',
  props: {
    branchId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['nursery', 'outplant'].includes(value)
    },
  },
  data() {
    return {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      siteSchedules: [],
      eventColors: ['#00f1a0', '#27bdf4', '#ffffff', '#ee756f', '#ffa500', '#4A4A4A'],
      timelineWidth: 0,
      labelWidth: 30,
      padding: 20,
      topPadding: 10, 
      bottomPadding: 30,
      currentDateX: null,
      rows: [],
      currentYear: new Date().getFullYear(),
      siteHeight: 64,
      upcomingEvents: [],
      expanded: false,
      tooltipVisible: false,
      tooltipContent: '',
      tooltipStyle: {
        top: '0px',
        left: '0px',
      },
      showMenu: false,
      weatherEvents: [],
      selectedEvent: null,
      isEditing: false,
      editedName: '',
      weatherSectionHeight: 24,
      weatherEventHeight: 20,
      maxWeatherLevels: 1,
      draggingEvent: null,
      resizingEvent: null,
      dragStartX: 0,
      originalStart: 0,
      originalDuration: 0,
      isDragging: false,
      isResizing: false,
      svgKey: 0,
      tempEvent: null,
      editMode: false,
      ongoingEvents: [],
      adjustedStartMonth: 0,
      adjustedMonths: [],
      adjustedYear: null,
    };
  },
  computed: {
    title() {
      return this.type === 'nursery' ? 'Nursery' : 'Outplant Site';
    },
    totalHeight() {
      return (this.siteSchedules.length * this.siteHeight) + this.topPadding + this.bottomPadding + this.weatherSectionHeight;
    },
    svgHeight() {
      return this.totalHeight + 20; 
    },
  },
  watch: {
    branchId() {
      this.init();
    },
    currentYear() {
      this.adjustTimelineMonths();
      this.calculateCurrentDatePosition();
    },
  },
  mounted() {
    this.init();
    window.addEventListener('resize', this.updateTimelineWidth);
    window.addEventListener('mousemove', this.onDragMove);
    window.addEventListener('mousemove', this.onResizeMove);
    window.addEventListener('mouseup', this.onDragEnd);
    window.addEventListener('mouseup', this.onResizeEnd);
  },
  methods: {
    init() {
      this.initializeRows();
      this.updateTimelineWidth();
      this.calculateCurrentDatePosition();
      this.fetchAllSchedules();
      this.adjustTimelineMonths();
    },
    initializeRows() {
      if (this.type === 'nursery') {
        this.rows = ['Seeding', 'Full Monitoring', 'Bleach Monitoring', 'Outplanting', 'General'];
      } else if (this.type === 'outplant') {
        this.rows = ['Surveys', 'Photogrammetry', 'Cell Monitoring', 'Water Quality', 'General'];
      }
    },
    async fetchAllSchedules() {
      const collectionName = this.type === 'nursery' ? 'Nurseries' : 'OutplantSites';
      const sitesRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/${collectionName}`);
      try {
        const querySnapshot = await getDocs(sitesRef);
        this.siteSchedules = [];
        for (const doc of querySnapshot.docs) {
          const siteData = doc.data();
          const events = siteData.schedules?.[this.currentYear] || [];
          const eventsNextYear = siteData.schedules?.[this.currentYear + 1] || [];
          if (events.length > 0 || eventsNextYear.length > 0) {
            this.siteSchedules.push({
              siteId: doc.id,
              siteName: siteData.name,
              events: events,
              eventsNextYear: eventsNextYear,
              visibleEvents: [...events, ...eventsNextYear].filter(event => this.isEventVisible(event))
            });
          }
        }
        this.siteSchedules.sort((a, b) => a.siteName.localeCompare(b.siteName));
        this.$nextTick(() => {
          this.updateTimelineWidth();
        });
      } catch (error) {
        console.error("Error fetching schedules:", error);
      }
      
      // Fetch weather events
      const branchRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}`);
      const branchDoc = await getDoc(branchRef);
      if (branchDoc.exists() && branchDoc.data().weatherEvents) {
        this.weatherEvents = branchDoc.data().weatherEvents[this.currentYear] || [];
        this.weatherEventsNextYear = branchDoc.data().weatherEvents[this.currentYear + 1] || [];
      }
      
      // After fetching schedules, calculate upcoming events
      this.calculateUpcomingEvents();
      this.calculateWeatherLevels();
    },
    getShortSiteName(siteName) {
      if (!siteName) return '';
      const words = siteName.trim().split(/\s+/);
      if (words.length === 1) {
        return words[0].length > 10 ? words[0].substring(0, 10) : words[0];
      } else {
        return words.map(word => word.length > 5 ? word.substring(0, 5) : word).join(' ');
      }
    },
    updateTimelineWidth() {
      if (this.$refs.container) {
        this.timelineWidth = this.$refs.container.clientWidth;
        this.calculateCurrentDatePosition();
      }
    },
    calculateCurrentDatePosition() {
      const today = new Date();
      const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);

      if (today >= startOfTimeline && today <= endOfTimeline) {
        const daysSinceStart = Math.floor((today - startOfTimeline) / (24 * 60 * 60 * 1000));
        const totalDays = Math.floor((endOfTimeline - startOfTimeline) / (24 * 60 * 60 * 1000));
        const timelineStart = this.labelWidth;
        const timelineWidth = this.timelineWidth - this.labelWidth - this.padding;
        this.currentDateX = timelineStart + (daysSinceStart / totalDays) * timelineWidth;
      } else if (today < startOfTimeline) {
        this.currentDateX = this.labelWidth;
      } else {
        this.currentDateX = this.timelineWidth - this.padding;
      }
    },
    getEventX(event) {
      const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
      
      const eventStart = new Date(event.year, event.start - 1, event.day || 1);
      const visibleStart = new Date(Math.max(eventStart, startOfTimeline));

      if (visibleStart > endOfTimeline) {
        return null;
      }
      
      const daysSinceStart = (visibleStart - startOfTimeline) / (1000 * 60 * 60 * 24);
      const totalDays = (endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24);
      return this.labelWidth + (daysSinceStart / totalDays) * (this.timelineWidth - this.labelWidth - this.padding);
    },
    getEventWidth(event) {
      const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
      
      const eventStart = new Date(event.year, event.start - 1, event.day || 1);
      const eventEnd = this.getEventEndDate(event);
      
      const visibleStart = new Date(Math.max(eventStart, startOfTimeline));
      const visibleEnd = new Date(Math.min(eventEnd, endOfTimeline));
      
      if (visibleStart >= endOfTimeline || visibleEnd <= startOfTimeline) {
        return 0;
      }
      
      const totalDays = (endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24);
      const visibleDays = (visibleEnd - visibleStart) / (1000 * 60 * 60 * 24);
      
      return Math.max((visibleDays / totalDays) * (this.timelineWidth - this.labelWidth - this.padding), 2);
    },
    getSiteY(siteIndex) {
      return siteIndex * this.siteHeight;
    },
    getEventY(siteIndex, event) {
      return this.getSiteY(siteIndex) + 5 + (event.level * 12) + this.topPadding;
    },
    getEventColor(event) {
      const colorMap = {
        'Seeding': '#00f1a0',
        'Full Monitoring': '#27bdf4',
        'Bleach Monitoring': '#ffffff',
        'Outplanting': '#ee756f',
        'Surveys': '#00f1a0',
        'Photogrammetry': '#27bdf4',
        'Cell Monitoring': '#ffffff',
        'Water Quality': '#ee756f',
        'General': '#ffa500',
        'Weather': '#4A4A4A'
      };
      
      if (event.isWeather) {
        return colorMap['Weather'];
      }
      
      const eventType = event.name.split(' (')[0];
      return colorMap[eventType] || this.eventColors[event.level % this.eventColors.length];
    },
    getSiteColor(index) {
      const colors = ['#27bdf4', '#1e95c2', '#166d8f', '#0e465d', '#06202b', '#051c25', '#041720', '#03131a', '#333333'];
      return colors[index % colors.length];
    },
    isEventVisible(event) {
      const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);

      const eventStart = this.getEventStartDate(event);
      const eventEnd = this.getEventEndDate(event);

      return eventStart < endOfTimeline && eventEnd > startOfTimeline;
    },
    calculateOngoingAndUpcomingEvents() {
      const today = new Date();
      const allEvents = [
        ...this.siteSchedules.flatMap(site => 
          site.events.map(event => ({
            ...event,
            siteName: site.siteName,
            isWeather: false
          }))
        ),
        ...this.weatherEvents.map(event => ({
          ...event,
          isWeather: true
        }))
      ];
      
      this.ongoingEvents = [];
      this.upcomingEvents = [];

      allEvents.forEach(event => {
        const eventStartDate = new Date(this.currentYear, event.start - 1, event.day || 1);
        const eventEndDate = new Date(eventStartDate);
        eventEndDate.setMonth(eventEndDate.getMonth() + Math.floor(event.duration));
        eventEndDate.setDate(eventEndDate.getDate() + Math.round((event.duration % 1) * 30) - 1);

        if (today >= eventStartDate && today <= eventEndDate) {
          // Ongoing event
          const remainingDuration = (eventEndDate - today) / (1000 * 60 * 60 * 24 * 30); // Convert to months
          this.ongoingEvents.push({
            ...event,
            remainingDuration,
          });
        } else if (today < eventStartDate) {
          // Upcoming event
          const timeDiff = eventStartDate - today;
          const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
          
          let timeUntil;
          if (daysDiff === 0) {
            timeUntil = 'Today';
          } else if (daysDiff < 7) {
            timeUntil = `${daysDiff} day${daysDiff > 1 ? 's' : ''}`;
          } else if (daysDiff < 30) {
            const weeks = Math.floor(daysDiff / 7);
            timeUntil = `${weeks} week${weeks > 1 ? 's' : ''}`;
          } else {
            const months = Math.floor(daysDiff / 30);
            timeUntil = `${months} month${months > 1 ? 's' : ''}`;
          }

          this.upcomingEvents.push({
            ...event,
            timeUntil,
            date: eventStartDate
          });
        }
      });

      // Sort ongoing events by remaining duration
      this.ongoingEvents.sort((a, b) => a.remainingDuration - b.remainingDuration);

      // Sort upcoming events by start date and limit to 3
      this.upcomingEvents.sort((a, b) => a.date - b.date);
      this.upcomingEvents = this.upcomingEvents.slice(0, 3);
    },

    // Replace the existing calculateUpcomingEvents method with this one
    calculateUpcomingEvents() {
      this.calculateOngoingAndUpcomingEvents();
    },

    toggleExpanded() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.$nextTick(() => {
          this.updateTimelineWidth();
        });
      }
    },
    showTooltip(event, eventData, siteIndex) {
      const rect = event.target.getBoundingClientRect();
      const containerRect = this.$refs.container.getBoundingClientRect();

      this.tooltipContent = this.getEventTooltip(eventData);

      // Calculate the vertical position
      let top = rect.top - containerRect.top - 5;
      const tooltipHeight = 60; // Approximate height of the tooltip

      this.tooltipStyle = {
        top: `${top}px`,
        left: `${rect.left - containerRect.left + rect.width / 2}px`,
      };
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    getEventTooltip(event) {
      const startDate = new Date(this.currentYear, event.start - 1, event.day || 1);
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + Math.floor(event.duration));
      endDate.setDate(endDate.getDate() + Math.round((event.duration % 1) * 30) - 1); // Assuming duration is in months
      const formattedStartDate = this.formatDate(startDate);
      const formattedEndDate = this.formatDate(endDate);
      const duration = this.formatDuration(event.duration);

      return `
        <strong>${event.name}</strong><br>
        ${formattedStartDate} to ${formattedEndDate}<br>
        (${duration})
      `;
    },
    formatDate(date) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return `${months[date.getMonth()]} ${date.getDate()}`;
    },
    formatDuration(duration) {
      if (duration >= 1) {
        const months = Math.floor(duration);
        const days = Math.round((duration - months) * 30);
        if (days > 0) {
          return `${months} month${months > 1 ? 's' : ''} ${days} day${days > 1 ? 's' : ''}`;
        } else {
          return `${months} month${months > 1 ? 's' : ''}`;
        }
      } else {
        const days = Math.round(duration * 30);
        return `${days} day${days > 1 ? 's' : ''}`;
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async savePDF() {
      this.showMenu = false;

      // Hide the dropdown menu and year selector
      if (this.$refs.dropdownMenu) this.$refs.dropdownMenu.style.display = 'none';
      if (this.$refs.yearSelector) this.$refs.yearSelector.style.display = 'none';

      // Create a wrapper div to hold both the timeline and legend
      const wrapper = document.createElement('div');
      wrapper.style.backgroundColor = 'white';
      wrapper.style.padding = '20px';

      // Clone the timeline container
      const timelineContainer = this.$refs.container.cloneNode(true);
      
      // Clone the legend container
      const legendContainer = this.$refs.legendContainer.cloneNode(true);

      // Adjust styles for white background
      this.adjustStylesForPDF(timelineContainer);
      this.adjustStylesForPDF(legendContainer);

      // Append both containers to the wrapper
      wrapper.appendChild(timelineContainer);
      wrapper.appendChild(legendContainer);

      // Temporarily append the wrapper to the document body
      document.body.appendChild(wrapper);
      
      try {
        const canvas = await html2canvas(wrapper, {
          backgroundColor: 'white',
          scale: 2, // Increase resolution
        });
        
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });
        
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
        pdf.save('nursery_schedule.pdf');
      } catch (error) {
        console.error('Error generating PDF:', error);
      } finally {
        // Remove the temporary wrapper
        document.body.removeChild(wrapper);

        // Show the dropdown menu and year selector again
        if (this.$refs.dropdownMenu) this.$refs.dropdownMenu.style.display = '';
        if (this.$refs.yearSelector) this.$refs.yearSelector.style.display = '';
      }
    },

    adjustStylesForPDF(element) {
      // Set the background to white
      element.style.backgroundColor = 'white';
      
      // Change text color to black
      element.querySelectorAll('text, span').forEach(textElement => {
        textElement.style.fill = 'black';
        textElement.style.color = 'black';
      });
      
      // Change line colors to light gray
      element.querySelectorAll('line').forEach(line => {
        if (line.getAttribute('stroke') === '#333333') {
          line.setAttribute('stroke', '#e0e0e0');
        }
      });

      // Adjust select and button styles
      element.querySelectorAll('select, button').forEach(el => {
        el.style.backgroundColor = '#f0f0f0';
        el.style.color = 'black';
      });

      // Remove the dropdown menu and year selector from the cloned element
      const dropdownMenu = element.querySelector('[ref="dropdownMenu"]');
      if (dropdownMenu) dropdownMenu.remove();

      const yearSelector = element.querySelector('[ref="yearSelector"]');
      if (yearSelector) yearSelector.remove();
    },

    // Add new methods for handling weather events
    addWeatherEvent() {
      if (!this.editMode) return;
      const today = new Date();
      const newEvent = {
        name: 'New Event',
        start: today.getMonth() + 1,
        day: today.getDate(),
        duration: 1,
        year: this.currentYear
      };
      this.weatherEvents.push(newEvent);
      this.selectEvent(newEvent, true);
      this.calculateWeatherLevels();
      this.svgKey += 1;
    },

    selectEvent(event, isWeatherEvent = false) {
      if (!this.editMode && isWeatherEvent) return;
      this.selectedEvent = event;
      this.isWeatherEvent = isWeatherEvent;
      this.tempEvent = { ...event };
      this.isEditing = false;
    },

    toggleEdit() {
      this.isEditing = true;
      this.editedName = this.selectedEvent.name;
    },

    confirmEdit() {
      if (this.editedName.trim() !== '') {
        this.selectedEvent.name = this.editedName.trim();
      }
      this.isEditing = false;
    },

    saveChanges() {
      this.saveWeatherEvents();
      this.calculateWeatherLevels();
      this.selectedEvent = null;
      this.tempEvent = null;
      this.isEditing = false;
      this.svgKey += 1;
    },

    cancelChanges() {
      if (this.tempEvent) {
        const events = this.weatherEvents;
        const index = events.findIndex(e => e === this.selectedEvent);
        if (index !== -1) {
          events[index] = { ...this.tempEvent };
        }
      }
      this.selectedEvent = null;
      this.tempEvent = null;
      this.isEditing = false;
      this.svgKey += 1;
    },

    deleteSelectedEvent() {
      const events = this.weatherEvents;
      const index = events.indexOf(this.selectedEvent);
      if (index > -1) {
        events.splice(index, 1);
      }
      this.selectedEvent = null;
      this.tempEvent = null;
      this.calculateWeatherLevels();
      this.svgKey += 1;
      this.saveWeatherEvents();
    },

   getEventStartDate(event) {
      const year = event.year || this.selectedYear;
      return new Date(year, event.start - 1, event.day || 1);
    },

    getEventEndDate(event) {
      const startDate = this.getEventStartDate(event);
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + Math.floor(event.duration));
      endDate.setDate(endDate.getDate() + Math.round((event.duration % 1) * 30) - 1);
      return endDate;
    },

    onDragStart(event, draggedEvent, isWeatherEvent = false) {
      if (!this.editMode && isWeatherEvent) return;
      this.draggingEvent = draggedEvent;
      this.dragStartX = event.clientX;
      this.originalStart = new Date(this.currentYear, draggedEvent.start - 1, draggedEvent.day || 1);
      this.isDragging = true;
      if (this.editMode) {
        document.body.style.userSelect = 'none';
      }
      this.selectEvent(draggedEvent, isWeatherEvent);
    },

    onResizeStart(event, resizedEvent, isWeatherEvent = false) {
      if (!this.editMode && isWeatherEvent) return;
      this.resizingEvent = resizedEvent;
      this.dragStartX = event.clientX;
      this.originalDuration = resizedEvent.duration;
      this.isResizing = true;
      if (this.editMode) {
        document.body.style.userSelect = 'none';
      }
    },

    onDragMove(event) {
      if (!this.draggingEvent) return;
      const deltaX = event.clientX - this.dragStartX;
      const dayWidth = (this.timelineWidth - this.labelWidth - this.padding) / 365;
      const dayDelta = Math.round(deltaX / dayWidth);
      
      const newStartDate = new Date(this.originalStart.getTime() + dayDelta * 24 * 60 * 60 * 1000);
      
      const minDate = new Date(this.currentYear, 0, 1);
      const maxDate = new Date(this.currentYear + 1, 11, 31);
      
      const eventDuration = this.draggingEvent.duration * 30 * 24 * 60 * 60 * 1000;
      const newEndDate = new Date(newStartDate.getTime() + eventDuration);
      
      if (newStartDate < minDate) {
        newStartDate.setTime(minDate.getTime());
      } else if (newEndDate > maxDate) {
        newStartDate.setTime(maxDate.getTime() - eventDuration);
      }
      
      this.draggingEvent.start = newStartDate.getMonth() + 1;
      this.draggingEvent.day = newStartDate.getDate();
      this.draggingEvent.year = newStartDate.getFullYear();
      
      this.calculateWeatherLevels();
      this.$forceUpdate();
    },

    onResizeMove(event) {
      if (!this.resizingEvent) return;
      const deltaX = event.clientX - this.dragStartX;
      const dayWidth = (this.timelineWidth - this.labelWidth - this.padding) / 365;
      const dayDelta = Math.round(deltaX / dayWidth);
      
      const startDate = this.getEventStartDate(this.resizingEvent);
      const maxEndDate = new Date(this.currentYear + 1, 11, 31);
      const maxDuration = (maxEndDate - startDate) / (30 * 24 * 60 * 60 * 1000);
      
      const newDuration = Math.max(0.1, Math.min(this.originalDuration + dayDelta / 30, maxDuration));
      this.resizingEvent.duration = parseFloat(newDuration.toFixed(1)); // Round to 1 decimal place
      
      this.calculateWeatherLevels();
      this.$forceUpdate();
    },

    onDragEnd() {
      if (this.draggingEvent) {
        this.draggingEvent = null;
        this.isDragging = false;
        document.body.style.userSelect = '';
      }
    },

    onResizeEnd() {
      if (this.resizingEvent) {
        this.resizingEvent = null;
        this.isResizing = false;
        document.body.style.userSelect = '';
      }
    },

    async saveWeatherEvents() {
      const branchRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}`);
      const branchDoc = await getDoc(branchRef);
      const currentWeatherEvents = branchDoc.data().weatherEvents || {};
      currentWeatherEvents[this.currentYear] = this.weatherEvents;
      await updateDoc(branchRef, { weatherEvents: currentWeatherEvents });
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    toggleEditMode() {
      this.editMode = !this.editMode;
      this.showMenu = false;
      if (!this.editMode) {
        // Save changes when exiting edit mode
        this.saveWeatherEvents();
      }
    },

    calculateWeatherLevels() {
      if (this.weatherEvents.length === 0) {
        this.maxWeatherLevels = 1;
        return;
      }

      const sortedEvents = [...this.weatherEvents].sort((a, b) => {
        const aStart = new Date(this.currentYear, a.start - 1, a.day || 1);
        const bStart = new Date(this.currentYear, b.start - 1, b.day || 1);
        return aStart - bStart;
      });

      const levels = [[]];

      sortedEvents.forEach(event => {
        const eventStart = new Date(this.currentYear, event.start - 1, event.day || 1);
        const eventEnd = new Date(eventStart);
        eventEnd.setMonth(eventEnd.getMonth() + Math.floor(event.duration));
        eventEnd.setDate(eventEnd.getDate() + Math.round((event.duration % 1) * 30) - 1);

        let levelFound = false;
        for (let i = 0; i < levels.length; i++) {
          if (levels[i].every(existingEvent => {
            const existingStart = new Date(this.currentYear, existingEvent.start - 1, existingEvent.day || 1);
            const existingEnd = new Date(existingStart);
            existingEnd.setMonth(existingEnd.getMonth() + Math.floor(existingEvent.duration));
            existingEnd.setDate(existingEnd.getDate() + Math.round((existingEvent.duration % 1) * 30) - 1);
            return eventEnd <= existingStart || eventStart >= existingEnd;
          })) {
            levels[i].push(event);
            event.level = i;
            levelFound = true;
            break;
          }
        }

        if (!levelFound) {
          levels.push([event]);
          event.level = levels.length - 1;
        }
      });

      this.maxWeatherLevels = levels.length;
      this.weatherSectionHeight = this.maxWeatherLevels * this.weatherEventHeight + 4;
    },

    getWeatherEventY(event) {
      return this.totalHeight - this.bottomPadding - this.weatherSectionHeight + (event.level * this.weatherEventHeight) + 2;
    },

    stripTrailingDigits(name) {
      return name.replace(/\s+\d+$/, '');
    },

    adjustTimelineMonths() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      if (this.currentYear === currentYear && currentMonth >= 2) {
        this.adjustedStartMonth = Math.max(0, currentMonth - 2);
        this.adjustedYear = currentYear;
      } else {
        this.adjustedStartMonth = 0;
        this.adjustedYear = this.currentYear;
      }

      const monthsThisYear = this.months.slice(this.adjustedStartMonth);
      const monthsNextYear = this.months.slice(0, this.adjustedStartMonth);
      this.adjustedMonths = [...monthsThisYear, ...monthsNextYear];
    },
  },
};
</script>

<style scoped>
.timeline-container {
  position: relative;
  width: 100%;
  overflow: visible;
}

svg text {
  font-family: Arial, sans-serif;
  dominant-baseline: central; /* eslint-disable-line */
}

.transform {
  transform: rotate(180deg);
}

.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  max-height: 2000px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
}

.custom-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  transform: translate(-50%, -100%);
  z-index: 1000;
  white-space: nowrap;
}

.custom-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.resize-handle {
  cursor: ew-resize;
}

.draggable text {
  pointer-events: none;
}

.no-highlight {
  user-select: none;
}

.stroke-white {
  stroke: white;
}

.stroke-2 {
  stroke-width: 2px;
}

.weather-event {
  cursor: default;
}

.weather-event.edit-mode {
  cursor: move;
}

.weather-event.edit-mode:active {
  cursor: grabbing;
}

.resize-handle {
  cursor: ew-resize;
}

/* Remove or comment out this style as it's no longer needed */
/* .draggable:not(.editMode) {
  cursor: default;
} */
</style>