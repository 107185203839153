<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-[350px] max-w-[900px]">
        <div class="flex justify-between items-center mb-4">
            <h3 class="text-2xl font-black font-lato">TEMPERATURE</h3>
        </div>
        <div class="flex-grow relative">
            <canvas ref="chartCanvas"></canvas>
        </div>
        <div class="flex justify-start mt-2.5 text-xs">
            <span class="flex items-center mr-4">
                <span class="w-3 h-3 rounded-full bg-[#1B8AC4] inline-block mr-1.5"></span>
                French Polynesia
            </span>
            <span class="flex items-center mr-4">
                <span class="w-3 h-3 rounded-full bg-[#5BD9F8] inline-block mr-1.5"></span>
                Fiji
            </span>
            <span class="flex items-center">
                <span class="w-3 h-3 rounded-full bg-[#A8F8FD] inline-block mr-1.5"></span>
                Thailand
            </span>
        </div>
    </div>
</template>


<script>
import Chart from 'chart.js/auto';
import { InfluxDB, FluxQuery, flux } from '@influxdata/influxdb-client';
import 'chartjs-adapter-date-fns';
const token = '1Mb6JnkdlVyRrVb7JaccrOOgt-7jWgkQNIrpU_iJtGm_6CNeLt_WlABvfMfKR1JHzSC7nZb75HJraBRxAkYhpQ==';
const client = new InfluxDB({ url: 'https://us-central1-1.gcp.cloud2.influxdata.com', token: token });
const queryApi = client.getQueryApi('ReefOS');

export default {
    name: 'BleachCard',
    data() {
        return {
            branches: [
                { id: 'reefos-01', name: 'French Polynesia', threshold: 29.79 },
                { id: 'reefos-fiji-ssr', name: 'Fiji', threshold: 29.85 },
                { id: 'reefos-thai-hq', name: 'Thailand', threshold: 31.27 },
            ],
            chart: null,
            temp_bias: 0.55,
        };
    },
    mounted() {
        this.updateChart();
    },
    methods: {
        async fetchData(bucketId) {
            const query = `
                from(bucket: "${bucketId}")
                |> range(start: -8mo)
                |> filter(fn: (r) => r._measurement == "sensors" and r._field == "externalTemperature")
                |> aggregateWindow(every: 1d, fn: mean, createEmpty: false)
                |> sort(columns: ["_time"])
                |> yield(name: "mean")
            `;

            return new Promise((resolve, reject) => {
                const result = [];
                queryApi.queryRows(query, {
                    next: (row, tableMeta) => {
                        const o = tableMeta.toObject(row);
                        result.push({
                            temp: (parseFloat(o._value.toFixed(2)) - parseFloat(this.temp_bias)).toFixed(2),
                            timestamp: new Date(o._time),
                        });
                    },
                    error: (error) => reject(error),
                    complete: () => resolve(result),
                });
            });
        },
        async updateChart() {
            try {
                const dataPromises = this.branches.map(branch => this.fetchData(branch.id));
                const allData = await Promise.all(dataPromises);

                const datasets = allData.map((data, index) => ({
                    label: this.branches[index].name,
                    data: data.map(d => ({ x: d.timestamp, y: d.temp })),
                    borderWidth: 1.5,
                    borderColor: this.getColor(index),
                    backgroundColor: this.getGradient(index),
                    fill: true,
                    tension: 0.4,
                    pointRadius: 0,
                    pointHoverRadius: 0
                }));

                // Add threshold datasets
                this.branches.forEach((branch, index) => {
                    datasets.push({
                        data: [
                            { x: allData[index][0].timestamp, y: branch.threshold },
                            { x: allData[index][allData[index].length - 1].timestamp, y: branch.threshold }
                        ],
                        borderColor: this.getColor(index),
                        borderWidth: 1,
                        borderDash: [5, 5],
                        fill: false,
                        pointRadius: 0
                    });
                });

                this.createChart(datasets);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        createChart(datasets) {
            const ctx = this.$refs.chartCanvas.getContext('2d');

            if (this.chart) {
                this.chart.destroy();
            }

            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        title: {
                            display: false,
                        },
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'month',
                                displayFormats: {
                                    month: 'MMM'
                                }
                            },
                            ticks: {
                                color: 'white',
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                                color: 'rgba(255, 255, 255, 0.2)',
                                borderDash: [4, 4],
                            },
                            ticks: {
                                color: 'white',
                            },
                        }
                    }
                }
            });
        },
        getColor(index) {
            const colors = ['#1B8AC4', '#5BD9F8', '#A8F8FD'];
            return colors[index];
        },
        getGradient(index) {
            const ctx = this.$refs.chartCanvas.getContext('2d');
            const gradient = ctx.createLinearGradient(0, 0, 0, 250);
            const colors = [
                { start: '#1B8AC4', end: 'rgba(27, 138, 196, 0.1)' },
                { start: '#5BD9F8', end: 'rgba(91, 217, 248, 0.1)' },
                { start: '#A8F8FD', end: 'rgba(168, 248, 253, 0.1)' }
            ];
            gradient.addColorStop(0, colors[index].start);
            gradient.addColorStop(1, colors[index].end);
            return gradient;
        }
    }
}
</script>