<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col max-w-[600px]">
        <h1 class="text-2xl font-black font-lato text-left m-0">{{ formattedNumber(total) }}</h1>
        <h3 class="text-neutral-400 text-left -mt-1">Total Corals</h3>
        <div class="relative w-full bg-neutral-700 rounded h-2 my-4">
            <div class="h-full bg-primary rounded" :style="{ width: progress + '%' }"></div>
            <span class="absolute right-0 -top-5 text-xs">{{ formattedNumber(goal) }}</span>
        </div>
        <div class="flex justify-between w-full">
            <div class="bg-neutral-800 rounded p-2 w-[47%]">
                <p class="m-0 text-xs text-neutral-400 text-left">In nurseries</p>
                <h2 class="m-0 text-lg text-left">{{ formattedNumber(nurseries) }}</h2>
            </div>
            <div class="bg-neutral-800 rounded p-2 w-[47%]">
                <p class="m-0 text-xs text-neutral-400 text-left">In outplant sites</p>
                <h2 class="m-0 text-lg text-left">{{ formattedNumber(outplants) }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TotalsCard',
    props: ['global'],
    data() {
        return {
            goal: 300000,
        }
    },
    computed: {
        progress() {
            return (this.total / this.goal) * 100;
        },
        total() {
            return this.global['Fragments'] + this.global['Outplanted'];
        },
        nurseries() {
            return this.global['Fragments'];
        },
        outplants() {
            return this.global['Outplanted'];
        },
    },
    methods: {
        formattedNumber(number) {
            return new Intl.NumberFormat().format(number);
        }
    }
}
</script>
