<template>
    <div class="relative inline-block text-left w-full z-50">
        <div @click="toggleDropdown"
            class="cursor-pointer p-2 bg-black text-white rounded flex items-center justify-between">
            <span>{{ selected ? selected[optionLabel] : placeholder }}</span>
        </div>
        <div v-if="isOpen"
            class="z-50 origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-lg bg-black ring-1 ring-black ring-opacity-5 w-full min-w-max noscrollbar border border-neutral-700"
            style="max-height: 400px; overflow-y: auto;">
            <div class="py-1" role="menu" aria-orientation="vertical">
                <div v-for="item in options" :key="item[optionValue]" @click="selectItem(item)"
                    class="block px-4 py-2 text-sm text-white hover:bg-neutral-700 cursor-pointer flex items-center">
                    <span v-if="item[typeField] === 'outplant'" class="mdi mdi-cube-outline text-lg mr-2"></span>
                    <span v-if="item[typeField] === 'survey'" class="mdi mdi-nfc-search-variant text-lg mr-2"></span>
                    {{ item[optionLabel] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, String, Object],
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: 'Select an option'
        },
        optionLabel: {
            type: String,
            required: true
        },
        optionValue: {
            type: String,
            required: true
        },
        typeField: {
            type: String,
            deafult: null
        }
    },
    data() {
        return {
            isOpen: false,
            selected: this.options.find(option => option[this.optionValue] === this.value) || null
        };
    },
    mounted() {
        document.addEventListener('click', this.handleClick, true);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClick, true);
    },
    watch: {
        value(newValue) {
            this.selected = this.options.find(option => option[this.optionValue] === newValue) || null;
        }
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectItem(item) {
            this.selected = item;
            this.$emit('input', item[this.optionValue]); // Emit the value field of the selected item
            this.isOpen = false;
        },
        handleClick(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        },
    },
};
</script>

<style scoped>
.noscrollbar::-webkit-scrollbar {
    display: none;
}

.noscrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>