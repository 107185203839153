<template>
    <div v-if="nursery">
        <div class="flex items-center mb-4 ml-1">
            <h1 class="text-2xl font-black font-lato mr-2">{{ nursery.name }}</h1>
            <div class="relative">
                <button @click="toggleMenu" class="text-white hover:text-gray-300 transition-colors duration-300">
                    <i class="mdi mdi-dots-vertical text-xl"></i>
                </button>
                <div v-if="showMenu" class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                    <div class="py-1 text-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem" @click.prevent="editNursery">
                            <i class="mdi mdi-pencil mr-2"></i>Edit
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <NurseryEdit v-if="editMode" :branchId="branchId" :nurseryId="nurseryId" @update="editNursery" />

        <!-- <h2 class="text-2xl font-black font-lato mb-4">Overview</h2> -->
        <AINurserySummary :data="data" />

        <h2 class="text-2xl font-black font-lato mb-4 mt-4 ml-1">Schedule</h2>
        <ScheduleCard class="mb-4" :branchId="branchId" :siteId="nurseryId" :type="'nursery'" />

        <NurseryOverview v-if="data" :branchId="branchId" :nurseryId="nurseryId" :data="data" />

        <div class="bg-black p-4 mt-4 rounded-lg">
            <h2 class="text-2xl font-black font-lato mb-4">Structures
                <span class="rounded-full text-lg bg-white text-black px-2 ml-2">{{ sortedStructures.length }}</span>
            </h2>
            <div class="structures-grid">
                <StructureCard v-for="structure in sortedStructures" :key="structure.id" :structure="structure"
                    :fragments="fragmentsByStructure[structure.id]" :branchId="branchId" :nurseryId="nurseryId" />
            </div>
        </div>
    </div>
</template>

<script>
import { db_resto } from '../main';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import StructureCard from './StructureCard.vue';
import NurseryOverview from './NurseryOverview.vue';
import ScheduleCard from './ScheduleCard.vue'
import AINurserySummary from './AINurserySummary.vue';
import NurseryEdit from './NurseryEdit.vue';

export default {
    name: 'Nursery',
    components: {
        StructureCard,
        NurseryOverview,
        ScheduleCard,
        AINurserySummary,
        NurseryEdit,
    },
    props: {
        branchId: {
            type: String,
            required: true
        },
        nurseryId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            nursery: null,
            fragments: [],
            structures: [],
            data: null,
            editMode: false,
            showMenu: false,
        };
    },
    mounted() {
        // this.fetchNurseryData();
        // this.fetchData();
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.fetchNurseryData(); // Firestore
                this.fetchData(); // DE API
            }
        }
    },
    computed: {
        fragmentsByStructure() {
            return this.structures.reduce((acc, structure) => {
                acc[structure.id] = this.fragments.filter(f => f.location.structureID === structure.id);
                return acc;
            }, {});
        },
        sortedStructures() {
            return [...this.structures].sort((a, b) => a.position - b.position);
        }
    },
    methods: {
        editNursery() {
            this.editMode = !this.editMode;
            this.showMenu = false; 
        },
        async fetchData() {
            try {
                const response = await fetch(`https://data.coralgardeners.org/data/nursery?org=coral-gardeners&nursery=${this.nurseryId}&pwd=showmethedata`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                this.data = await response.json();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchNurseryData() {
            try {
                const nurseryRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}`);
                const nurseryDoc = await getDoc(nurseryRef);

                if (nurseryDoc.exists()) {
                    this.nursery = { id: nurseryDoc.id, ...nurseryDoc.data() };
                    await Promise.all([
                        this.fetchStructures(),
                        this.fetchFragments()
                    ]);
                } else {
                    console.log('No such nursery!');
                }
            } catch (error) {
                console.error("Error fetching nursery data:", error);
            }
        },
        async fetchStructures() {
            try {
                const structuresRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}/Structures`);
                const structuresSnapshot = await getDocs(structuresRef);

                this.structures = structuresSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

            } catch (error) {
                console.error("Error fetching structures:", error);
            }
        },
        async fetchFragments() {
            try {
                const fragmentsRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Fragments`);
                const q = query(fragmentsRef, where("location.nurseryID", "==", this.nurseryId));
                const querySnapshot = await getDocs(q);

                this.fragments = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

            } catch (error) {
                console.error("Error fetching fragments:", error);
            }
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
    }
}
</script>

<style scoped>
.structures-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}
</style>