<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-[350px]">
        <div class="flex items-center mb-2 justify-between">
            <div class="flex items-center space-x-2">
                <i class="mdi mdi-fish text-2xl"></i>
                <select v-model="selectedGroup" @change="updateChart"
                    class="bg-neutral-700 text-white py-2 px-4 rounded text-xs">
                    <option value="all_classes">All Fish</option>
                    <option v-for="group in fishGroups" :key="group" :value="group">{{ prettifyGroupName(group) }}</option>
                </select>
            </div>
            <div class="flex flex-col items-end">
                <select v-model="selectedTimespan" @change="handleTimespanChange"
                    class="bg-neutral-700 text-white py-2 px-4 rounded text-xs">
                    <option value="1d">1 day</option>
                    <option value="1w">1 week</option>
                    <option value="1m">1 month</option>
                    <option value="3m">3 months</option>
                    <option value="1y">1 year</option>
                    <option value="custom">Custom</option>
                </select>
                <div v-if="selectedTimespan === 'custom'" class="flex items-center space-x-2 mt-2">
                    <input 
                        type="date" 
                        v-model="customStartDate" 
                        @change="updateChart"
                        class="bg-neutral-700 text-white py-1 px-2 rounded text-xs"
                    >
                    <input 
                        type="date" 
                        v-model="customEndDate" 
                        @change="updateChart"
                        class="bg-neutral-700 text-white py-1 px-2 rounded text-xs"
                    >
                </div>
            </div>
        </div>
        <div class="flex-grow relative">
            <canvas ref="chartCanvas"></canvas>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { InfluxDB } from '@influxdata/influxdb-client';
import 'chartjs-adapter-date-fns';
import { getColorByFishClass } from '@/assets/js/utils/fishUtils.js';

const token = '1Mb6JnkdlVyRrVb7JaccrOOgt-7jWgkQNIrpU_iJtGm_6CNeLt_WlABvfMfKR1JHzSC7nZb75HJraBRxAkYhpQ==';
const client = new InfluxDB({ url: 'https://us-central1-1.gcp.cloud2.influxdata.com', token: token });

export default {
    name: 'DeviceFish',
    props: {
        deviceId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            chart: null,
            selectedGroup: 'all_classes',
            selectedTimespan: '3m',
            fishGroups: [],
            customStartDate: null,
            customEndDate: null,
        };
    },
    mounted() {
        this.initializeCustomDates();
        this.getFishGroups();
    },
    methods: {
        prettifyGroupName(name) {
            if (!name) return '';
            if (name === 'all_classes') return 'All Fish';
            return name
                .replace(/_/g, ' ')
                .replace(/\b\w/g, l => l.toUpperCase());
        },
        async getFishGroups() {
            const uniqueClasses = new Set();
            const queryApi = client.getQueryApi('ReefOS');
            const fluxQuery = `
                from(bucket: "${this.deviceId}")
                |> range(start: -3mo)
                |> filter(fn: (r) => r._measurement == "class_detection")
                |> filter(fn: (r) => r._field == "count")
                |> filter(fn: (r) => r.model == "reefos-01")
                |> filter(fn: (r) => r.version == "4")
                |> aggregateWindow(every: 3h, fn: mean, createEmpty: false)
                |> yield(name: "mean")
            `;

            try {
                await queryApi.queryRows(fluxQuery, {
                    next: (row, tableMeta) => {
                        const o = tableMeta.toObject(row);
                        uniqueClasses.add(o.class);
                    },
                    error: (error) => {
                        console.error("Error fetching fish groups:", error);
                    },
                    complete: () => {
                        this.fishGroups = Array.from(uniqueClasses).filter(group => group !== 'all_classes');
                        this.updateChart();
                    },
                });
            } catch (error) {
                console.error("Error fetching fish groups:", error);
            }
        },
        async fetchData() {
            const queryApi = client.getQueryApi('ReefOS');
            const fluxQuery = this.buildFluxQuery();

            try {
                const result = await queryApi.collectRows(fluxQuery);
                return result.map(row => ({
                    count: parseFloat(row._value.toFixed(1)),
                    timestamp: new Date(row._time),
                }));
            } catch (error) {
                console.error("Error fetching data:", error);
                return [];
            }
        },
        handleTimespanChange() {
            if (this.selectedTimespan === 'custom') {
                this.initializeCustomDates();
            }
            this.updateChart();
        },
        initializeCustomDates() {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 3);
            this.customEndDate = end.toISOString().split('T')[0];
            this.customStartDate = start.toISOString().split('T')[0];
        },
        buildFluxQuery() {
            let range;
            if (this.selectedTimespan === 'custom') {
                range = `start: ${this.customStartDate}T00:00:00Z, stop: ${this.customEndDate}T23:59:59Z`;
            } else {
                switch (this.selectedTimespan) {
                    case '1d': range = 'start: -1d'; break;
                    case '1w': range = 'start: -1w'; break;
                    case '1m': range = 'start: -1mo'; break;
                    case '3m': range = 'start: -3mo'; break;
                    case '1y': range = 'start: -1y'; break;
                    default: range = 'start: -3mo';
                }
            }

            return `
        from(bucket: "${this.deviceId}")
          |> range(${range})
          |> filter(fn: (r) => r._measurement == "class_detection")
          |> filter(fn: (r) => r._field == "count")
          |> filter(fn: (r) => r.model == "reefos-01")
          |> filter(fn: (r) => r.version == "4")
          |> filter(fn: (r) => r.class == "${this.selectedGroup}")
          |> aggregateWindow(every: ${this.getAggregateWindow()}, fn: mean, createEmpty: false)
          |> sort(columns: ["_time"])
          |> yield(name: "mean")
      `;
        },
        getAggregateWindow() {
            if (this.selectedTimespan === 'custom') {
                const start = new Date(this.customStartDate);
                const end = new Date(this.customEndDate);
                const diffDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

                if (diffDays <= 1) return '10m';
                if (diffDays <= 7) return '1h';
                if (diffDays <= 30) return '1h';
                if (diffDays <= 90) return '3h';
                return '1d';
            }

            switch (this.selectedTimespan) {
                case '1d': return '10m';
                case '1w': return '1h';
                case '1m': return '1h';
                case '3m': return '3h';
                case '1y': return '1d';
                default: return '3h';
            }
        },
        async updateChart() {
            const data = await this.fetchData();

            const dataset = {
                label: this.prettifyGroupName(this.selectedGroup),
                data: data.map(d => ({ x: d.timestamp, y: d.count })),
                borderWidth: 1.5,
                borderColor: getColorByFishClass(this.selectedGroup),
                backgroundColor: this.getGradient(getColorByFishClass(this.selectedGroup)),
                fill: true,
                tension: 0.1,
                pointRadius: 0,
                pointHoverRadius: 0
            };

            this.createChart([dataset]);
        },
        createChart(datasets) {
            const ctx = this.$refs.chartCanvas.getContext('2d');

            if (this.chart) {
                this.chart.destroy();
            }

            this.chart = new Chart(ctx, {
                type: 'line',
                data: { datasets },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: { display: false },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            displayColors: false,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            titleColor: 'white',
                            bodyColor: 'white',
                            titleFont: { weight: 'bold' },
                            bodyFont: { size: 14 },
                            padding: 10,
                            callbacks: {
                                title: (tooltipItems) => {
                                    const date = new Date(tooltipItems[0].parsed.x);
                                    return date.toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    });
                                },
                                label: (context) => {
                                    return `${context.parsed.y.toFixed(1)} fish`;
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: this.getTimeUnit(),
                                displayFormats: {
                                    hour: 'HH:mm',
                                    day: 'MMM d',
                                    week: 'MMM d',
                                    month: 'MMM yyyy'
                                }
                            },
                            ticks: {
                                color: 'white',
                                maxTicksLimit: 6
                            },
                        },
                        y: {
                            beginAtZero: true,
                            grid: {
                                color: 'rgba(255, 255, 255, 0.1)',
                            },
                            ticks: { color: 'white' },
                        }
                    }
                }
            });
        },
        getGradient(color) {
            const ctx = this.$refs.chartCanvas.getContext('2d');
            const gradient = ctx.createLinearGradient(0, 0, 0, 250);
            gradient.addColorStop(0, color);
            gradient.addColorStop(1, 'rgba(0, 0, 0, 0.1)');
            return gradient;
        },
        getTimeUnit() {
            if (this.selectedTimespan === 'custom') {
                const start = new Date(this.customStartDate);
                const end = new Date(this.customEndDate);
                const diffDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
                if (diffDays <= 1) return 'hour';
                if (diffDays <= 30) return 'day';
                if (diffDays <= 365) return 'month';
                return 'year';
            }

            switch (this.selectedTimespan) {
                case '1d': return 'hour';
                case '1w': return 'day';
                case '1m': return 'week';
                case '3m': return 'month';
                case '1y': return 'month';
                default: return 'month';
            }
        },
    }
}
</script>