<template>
    <div v-if="data" ref="card" class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col mb-4 relative">
        <h3 class="font-black font-lato mb-2">
            <div class="gradient-text flex items-center">
                <img :src="require('@/assets/icons/ai_coral.svg')" class="w-8 h-8 mr-2" alt="AI Coral Icon" />
                AI Analysis
            </div>
        </h3>
        <div v-if="loading" class="animate-pulse">
            <div class="h-4 bg-gray-700 rounded w-3/4 mb-2"></div>
            <div class="h-4 bg-gray-700 rounded w-1/2"></div>
        </div>
        <div v-else :class="{ 'text-black': !showOverview, 'text-white transition-colors duration-500': showOverview }">
            {{ overview }}
        </div>
        <SparkleAnimation v-if="cardWidth && cardHeight" :width="cardWidth" :height="cardHeight" :active="isAnimating" />
    </div>
</template>

<script>
import SparkleAnimation from './utils/SparkleAnimation.vue';

export default {
    name: 'AINurserySummary',
    components: {
        SparkleAnimation
    },
    props: ['data'],
    data() {
        return {
            lastData: null,
            overview: null,
            key: 'sk-proj-8LWsgEpvGdVWNaiqXHhqT3BlbkFJ6P3HOg8yROCNTfljYq9E',
            userName: '',
            loading: true,
            isAnimating: false,
            cardWidth: 0,
            cardHeight: 0,
            showOverview: false
        };
    },
    watch: {
        data: {
            immediate: true,
            handler() {
                this.overview = null;
                this.loading = true;
                this.showOverview = false;
                this.$nextTick(() => {
                    this.updateCardDimensions();
                    this.getOverview();
                });
            }
        }
    },
    methods: {
        async getOverview() {
            const data = JSON.stringify(this.data);

            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.key}`
                },
                body: JSON.stringify({
                    model: "gpt-4o-mini-2024-07-18",
                    messages: [
                        { role: "system", content: `You are an AI based coral restoration expert with the goal of summarizing complex data for the user.` },
                        { role: "system", content: `Here is the data: ${data}` },
                        {
                            role: "system", content: `For background info: Health is on a scale of 1 to 6 with 1 being healthy and 6 being dead (the inverse of what you would expect). Bleaching is on a scale of 1 to 6 with 1 being not bleached and 6 being fully bleached. 
                            "Fraction Alive" should be called Survival and 100% means all coral fragments are alive. EVI is the ecological volume index and referes to size of the coral fragments in cm3. 
                            Age is measured in days and the intended dwell time in the nursery is two years. Colonies refers to the mother colonies that donated fragments to this nursery. You don't need to mention this scale or the nursery ID.` },
                        { role: "user", content: `I would like you to look at this data and surface any interesting insights or trends that I should be aware of. What can I do to improve the success of this nursery? Keep it 5 sentences max and in paraphraph form with no special formatting.` }
                    ],
                    temperature: 0.4,
                    max_tokens: 1024,
                    frequency_penalty: 0.5,
                    presence_penalty: 0,
                })
            });

            const result = await response.json();
            this.overview = result.choices[0].message.content;
            this.loading = false;
            this.$nextTick(() => {
                this.updateCardDimensions();
                this.triggerAnimation();
            });
        },
        triggerAnimation() {
            this.isAnimating = true;
            setTimeout(() => {
                this.isAnimating = false;
                this.showOverview = true;
            }, 1500); // Animation duration
        },
        updateCardDimensions() {
            const card = this.$refs.card;
            if (card) {
                this.cardWidth = card.offsetWidth;
                this.cardHeight = card.offsetHeight;
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateCardDimensions);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateCardDimensions);
    }
}
</script>

<style scoped>
.gradient-text {
    background: linear-gradient(90deg,
            #5EBAF0 0%,
            #B9F6FC 7%,
            #F9F5FE 15%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: flex;
    align-items: center;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>