<template>
  <div class="p-4">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      <div v-for="camera in cameras" :key="camera.id" class="bg-black rounded-lg shadow-md overflow-hidden">
        <div class="relative pb-[56.25%]">
          <iframe
            :src="`https://camstreamer.com/embed/${camera.camstreamer_id}`"
            class="absolute top-0 left-0 w-full h-full"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        <!-- <div class="pl-2">
          <h2 class="text-lg font-semibold text-white mb-2">{{ camera.name }}</h2>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { db_map } from '../main.js'
import { collection, getDocs, query } from 'firebase/firestore';

export default {
  name: 'Livestreams',
  data() {
    return {
      cameras: []
    }
  },
  methods: {
    async fetchCameras() {
      try {
        const camerasRef = collection(db_map, 'Cameras');
        const q = query(camerasRef);
        const querySnapshot = await getDocs(q);
        this.cameras = querySnapshot.docs
          .filter(doc => !doc.id.startsWith('debug'))
          .map(doc => ({
            id: doc.id,
            name: doc.data().name || doc.id,
            camstreamer_id: doc.data().camstreamer_id,
            location: doc.data().location
          }));
      } catch (error) {
        console.error('Error fetching cameras:', error);
      }
    }
  },
  mounted() {
    this.fetchCameras();
  }
}
</script>

<style scoped>
.livestreams {
  padding: 20px;
}

.stream-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.stream-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
