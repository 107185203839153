<template>
    <div class="card">
        <h1 class="title">STATS</h1>
        <div class="stats">
            <div class="stat">
                <p class="stat-title">Branches</p>
                <h2 class="stat-number">{{ global['Branches'] }}</h2>
            </div>
            <div class="stat ml-4">
                <p class="stat-title">Nurseries</p>
                <h2 class="stat-number">{{ global['Nurseries'] }}</h2>
            </div>
            <div class="stat ml-4">
                <p class="stat-title">Outplant Sites</p>
                <h2 class="stat-number">{{ global['Outplants'] }}</h2>
            </div>
            <div class="stat ml-4">
                <p class="stat-title">Genera</p>
                <h2 class="stat-number">{{ global['Genera'] }}</h2>
            </div>
            <div class="stat ml-4">
                <p class="stat-title">Species</p>
                <h2 class="stat-number">{{ global['Species'] }}</h2>
            </div>
            <div class="stat ml-4">
                <p class="stat-title">Mother Colonies</p>
                <h2 class="stat-number">{{ formattedNumber(global['Mother Colonies']) }}</h2>
            </div>
        </div>
        <div class="content">
            <div id="map" class="map"></div>
            <div class="progress-card">
                <h2 class="progress-card-title">2024 GOALS</h2>
                <div class="progress-bars">
                    <div class="progress-bar-container" v-for="(branch, index) in branches" :key="index">
                        <div class="progress-title-container">
                            <p class="progress-title">{{ branch.Branch.split(',')[1] }}</p>
                            <span class="goal-number">{{ formattedNumber(calculateProgress(branch)[1]) }}</span>
                        </div>
                        <div class="progress-bar">
                            <div class="progress" :style="{ width: calculateProgress(branch)[0] + '%' }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export default {
    name: 'MapCard',
    props: ['global', 'branches'],
    data() {
        return {
            map: null,
            goals: {
                'Malolo Island, Fiji': 13265,
                "Mo'orea, French Polynesia": 126700,
                'Koh Mak, Thailand': 10000,
            }
        };
    },
    watch: {
        branches() {
            this.initializeMap();
        }
    },
    methods: {
        initializeMap() {
            if (this.map) {
                this.map.remove();
            }

            mapboxgl.accessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
            this.map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/drewjgray3d/clxze1tmw004d01r4h1eqg426',
                center: [-210, 0],
                zoom: 0,
                attributionControl: false
            });

            this.map.on('load', () => {
                this.addMarkers();
            });
        },
        addMarkers() {
            this.bounds = new mapboxgl.LngLatBounds();

            this.branches.forEach(branch => {
                new mapboxgl.Marker({
                    element: this.createCustomMarker()
                })
                    .setLngLat([branch.lon, branch.lat])
                    .addTo(this.map);
            });
        },
        createCustomMarker() {
            const marker = document.createElement('div');
            marker.style.width = '16px';
            marker.style.height = '16px';
            marker.style.backgroundColor = '#27bdf4';
            marker.style.borderRadius = '50%';
            marker.style.border = '3px solid rgba(15, 60, 77, 0.7)';
            marker.style.boxSizing = 'border-box';
            return marker;
        },
        calculateProgress(branch) {
            const fragments = branch['Fragments'];
            const goal = this.goals[branch.Branch];
            return [(fragments / goal) * 100, goal];
        },
        formattedNumber(number) {
            return new Intl.NumberFormat().format(number);
        }
    }
}
</script>

<style scoped>
.card {
    background-color: black;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    height: 350px;
}

.title {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
}

.stats {
    display: flex;
    justify-content: left;
    margin-bottom: 0.5rem;
}

.stat {
    text-align: left;
}

.stat-title {
    color: gray;
    font-size: 0.8rem;
    margin: 0;
}

.stat-number {
    font-size: 1.2rem;
    margin: 0;
}

.content {
    display: flex;
    height: 100%;
}

.map {
    width: 67%;
    height: 100%;
}

.progress-card {
    width: 33%;
    background-color: #1c1c1c;
    border-radius: 8px;
    padding: 1rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
}

.progress-card-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.7rem;
}

.progress-bars {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.progress-bar-container {
    margin-bottom: 1rem;
}

.progress-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.progress-title {
    color: #ffffff;
    font-size: 12px;
    margin: 0;
}

.progress-bar {
    background-color: #0C508D;
    border-radius: 4px;
    height: 8px;
    position: relative;
}

.progress {
    height: 100%;
    background-color: #27bdf4;
    border-radius: 4px;
}

.goal-number {
    font-size: 0.8rem;
    color: #27bdf4;
}
</style>