<template>
    <div @click="navigateToCell"
        class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col min-w-[200px] min-h-[150px] cursor-pointer hover:shadow-lg transition-shadow duration-300 relative">
        <h3 class="text-xl font-black font-lato mb-2">Cell {{ cell.name }}</h3>
        <i v-if="hasPhotogrammetry" class="mdi mdi-cube-outline text-xl absolute top-4 right-4 text-white" />
    </div>
</template>

<script>
export default {
    name: 'OutplantCellCard',
    props: {
        branchId: {
            type: String,
            required: true
        },
        outplantSiteId: {
            type: String,
            required: true
        },
        cell: {
            type: Object,
            required: true
        },
    },
    computed: {
        hasPhotogrammetry() {
            return this.cell.healths && this.cell.healths.some(health => health.photogrammetryPath);
        }
    },
    methods: {
        navigateToCell() {
            this.$router.push({
                name: 'OutplantCell',
                params: {
                    branchId: this.branchId,
                    outplantSiteId: this.outplantSiteId,
                    cellId: this.cell.id,
                }
            });
        }
    }
}
</script>