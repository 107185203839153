<template>
    <div @click="navigateToStructure"
        class="bg-neutral-800 rounded-lg shadow-md text-white p-3 flex flex-col min-w-[200px] min-h-[150px] cursor-pointer hover:shadow-lg transition-shadow duration-300 relative">
        <h3 class="text-xl font-black font-lato mb-2">Structure {{ structure.position }}</h3>

        <div class="flex justify-between items-center mb-4">
            <div class="flex space-x-4">
                <div>
                    <p class="text-[#5EBAF0] text-sm">Survival</p>
                    <p class="font-black font-lato">{{ formatPercentage(avgSurvival) }}</p>
                </div>
                <div>
                    <p class="text-[#B9F6FC] text-sm">Health</p>
                    <p class="font-black font-lato">{{ formatDecimal(avgHealth) }}</p>
                </div>
                <div>
                    <p class="text-[#F9F5FE] text-sm">Bleaching</p>
                    <p class="font-black font-lato">{{ formatDecimal(avgBleach) }}</p>
                </div>
            </div>
            <div class="w-14 h-14 ml-4">
                <ThreeCircleGauge :survival="survivalPercentage" :health="healthPercentage" :bleach="bleachPercentage"
                    :size="48" />
            </div>
        </div>

        <div class="flex justify-between mt-1">
            <div class="w-[48%] bg-neutral-900 rounded-lg px-2 py-1">
                <p class="text-lg font-black font-lato text-white">{{ fragments.length.toLocaleString() }}</p>
                <p class="text-xs text-neutral-400">Fragments</p>
            </div>
            <div class="w-[48%] bg-neutral-900 rounded-lg px-2 py-1">
                <p class="text-lg font-black font-lato text-white">{{ structure.substructureCount.toLocaleString() }}</p>
                <p class="text-xs text-neutral-400">Substructures</p>
            </div>
        </div>
    </div>
</template>

<script>
import ThreeCircleGauge from './utils/ThreeCircleGauge.vue';

export default {
    name: 'StructureCard',
    components: {
        ThreeCircleGauge
    },
    props: {
        structure: {
            type: Object,
            required: true
        },
        fragments: {
            type: Array,
            required: true
        },
        branchId: {
            type: String,
            required: true
        },
        nurseryId: {
            type: String,
            required: true
        }
    },
    computed: {
        avgHealth() {
            return this.calculateAverage('health');
        },
        avgBleach() {
            return this.calculateAverage('bleach');
        },
        avgSurvival() {
            const fragmentsWithHealth = this.fragments.filter(fragment =>
                fragment.healths && fragment.healths.length > 0 &&
                fragment.healths.some(h => h.health != null)
            );

            if (fragmentsWithHealth.length === 0) return null;

            const survivingFragments = fragmentsWithHealth.filter(fragment => {
                const lastValidHealth = fragment.healths
                    .slice()
                    .reverse()
                    .find(h => h.health != null);
                return lastValidHealth && lastValidHealth.health !== 6;
            });

            return survivingFragments.length / fragmentsWithHealth.length;
        },
        survivalPercentage() {
            return this.avgSurvival !== null ? this.avgSurvival : 0;
        },
        healthPercentage() {
            return this.avgHealth !== null ? this.convertScale(this.avgHealth) : 0;
        },
        bleachPercentage() {
            return this.avgBleach !== null ? this.convertScale(this.avgBleach) : 0;
        }
    },
    methods: {
        calculateAverage(property) {
            const fragmentsWithData = this.fragments.filter(fragment =>
                fragment.healths &&
                fragment.healths.length > 0 &&
                fragment.healths.some(h => h[property] != null)
            );

            const scores = fragmentsWithData
                .map(fragment => {
                    const lastValidScore = fragment.healths
                        .slice()
                        .reverse()
                        .find(h => h[property] != null);
                    return lastValidScore ? lastValidScore[property] : null;
                })
                .filter(score => score !== null);

            if (scores.length === 0) return null;

            const sum = scores.reduce((acc, score) => acc + score, 0);
            const average = sum / scores.length;

            return average;
        },
        navigateToStructure() {
            this.$router.push({
                name: 'Structure',
                params: {
                    branchId: this.branchId,
                    nurseryId: this.nurseryId,
                    structureId: this.structure.id
                }
            });
        },
        formatDecimal(value) {
            return value !== null ? value.toFixed(1) : '';
        },
        formatPercentage(value) {
            return value !== null ? `${(value * 100).toFixed(0)}%` : '';
        },
        convertScale(value) {
            // Convert from 1-6 scale to 0-1 scale (inverted)
            return Math.max(0, Math.min(1, (6 - value) / 5));
        }
    }
}
</script>