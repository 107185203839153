<template>
	<div class="relative h-screen overflow-hidden">
		<ToastNotification />
		<!-- Background image with blur -->
		<div class="absolute inset-0 bg-black">
			<div
				class="absolute inset-0 bg-[url('@/assets/images/background.png')] bg-cover bg-center bg-no-repeat blur-[50px]">
			</div>
		</div>

		<!-- Main content -->
		<div class="relative z-10 h-full">
			<Header class="h-12" />
			<div class="flex h-[calc(100vh-3rem)]">
				<!-- <Sidebar v-if="!isMobile" /> -->
				<div class="relative w-full">
					<div class="absolute inset-0 flex flex-col rounded-2xl px-4">
						<div class="flex-grow overflow-y-auto py-4 text-white [scrollbar-width:none] [-ms-overflow-style:none] [&::-webkit-scrollbar]:hidden">
							<router-view />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';
import ToastNotification from '@/components/utils/ToastNotification.vue';



export default {
	name: 'Main',
	components: {
		Header,
		Sidebar,
		ToastNotification,
	},
}
</script>