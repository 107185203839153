<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-80">
        <div class="flex justify-between items-center mb-4">
            <h1 class="text-left text-xl font-black font-lato">Diversity</h1>
        </div>

        <div class="chart-container flex-grow relative flex justify-around items-center h-3/4">
            <canvas ref="chartRef" class="max-w-[50%] max-h-[85%]"></canvas>
            <div class="custom-legend flex flex-col justify-around items-start text-xs">
                <div v-for="(species, index) in topSpecies" :key="species.name" class="legend-item flex items-center">
                    <span class="legend-color w-3 h-3 rounded-full inline-block mr-1"
                        :style="{ backgroundColor: colors[index % colors.length] }"></span>
                    <span class="capitalize mr-1">{{ species.name.split(' ')[0] }}</span><span> {{ species.name.split(' ')[1] }}</span>
                </div>
                <div class="legend-item flex items-center" v-if="otherFraction > 0">
                    <span class="legend-color w-3 h-3 rounded-full inline-block mr-1"
                        :style="{ backgroundColor: colors[colors.length - 1] }"></span>
                    Other
                </div>
            </div>
        </div>
        <div class="flex justify-between mt-2">
            <div class="w-[48%] bg-neutral-800 rounded-lg px-2 py-1">
                <p class="text-xl font-black font-lato text-white">{{ data.nursery.summary.species }} / {{ data.nursery.summary.genera }}</p>
                <p class="text-xs text-neutral-400">Species / Genera</p>
            </div>
            <div class="w-[48%] bg-neutral-800 rounded-lg px-2 py-1">
                <p class="text-xl font-black font-lato text-white">{{ data.nursery.summary.colonies }}</p>
                <p class="text-xs text-neutral-400">Mother Colonies</p>
            </div>
        </div>
        <div class="flex justify-between mt-2">
            <div class="w-[48%] bg-neutral-800 rounded-lg px-2 py-1">
                <p class="text-xl font-black font-lato text-white">{{ (data.nursery.summary.shannon_diversity_spp / Math.log(data.nursery.summary.species)).toFixed(2)*100 }}<span class="font-normal text-xs"> %</span></p>
                <p class="text-xs text-neutral-400">Species Diversity</p>
            </div>
            <div class="w-[48%] bg-neutral-800 rounded-lg px-2 py-1">
                <p class="text-xl font-black font-lato text-white">{{ (data.nursery.summary.shannon_diversity_mc / Math.log(data.nursery.summary.colonies)).toFixed(2)*100 }}<span class="font-normal text-xs"> %</span></p>
                <p class="text-xs text-neutral-400">Genetic Diversity</p>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart, ArcElement, Tooltip, Legend, DoughnutController } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend, DoughnutController);

export default {
    name: 'NurseryDiversity',
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            chart: null,
            colors: ['#27bdf4', '#1e95c2', '#166d8f', '#0e465d', '#06202b', '#051c25', '#041720', '#03131a', '#333333'],
            otherFraction: 0,
        };
    },
    computed: {
        speciesDiversity() {
            const speciesSummary = this.data.nursery.species_summary;
            const totalCount = speciesSummary.reduce((sum, species) => sum + species.count, 0);
            const diversity = {};

            speciesSummary.forEach(species => {
                diversity[species.taxon] = (species.count / totalCount) * 100;
            });

            return diversity;
        },
        topSpecies() {
            const speciesArray = Object.entries(this.speciesDiversity).map(([name, percentage]) => ({ name, percentage }));
            speciesArray.sort((a, b) => b.percentage - a.percentage);

            const topSpecies = speciesArray.slice(0, 5);
            const otherSpecies = speciesArray.slice(5);
            this.otherFraction = otherSpecies.reduce((sum, species) => sum + species.percentage, 0);

            return topSpecies;
        },
    },
    mounted() {
        if (this.data.nursery.species_summary) this.createChart();
    },
    watch: {
        'data.nursery.species_summary': {
            handler() {
                this.createChart();
            },
            deep: true
        }
    },
    methods: {
        createChart() {
            if (this.chart) this.chart.destroy();
            const ctx = this.$refs.chartRef.getContext('2d');
            const data = [
                ...this.topSpecies.map(species => species.percentage),
                this.otherFraction
            ];
            const labels = [
                ...this.topSpecies.map(species => species.name),
                'Other'
            ];

            this.chart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: this.colors,
                        borderWidth: 0,
                    }]
                },
                options: {
                    responsive: true,
                    animation: true,
                    cutout: '50%',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            displayColors: false,
                            callbacks: {
                                title: () => '',
                                label: (context) => {
                                    const label = context.label.charAt(0).toUpperCase() + context.label.slice(1) || '';
                                    const value = context.raw !== undefined ? context.raw.toFixed(1) : '';
                                    return `${label}: ${value}%`;
                                }
                            }
                        },
                    }
                }
            });
        },
        formatNumber(value) {
            return value != null ? value.toLocaleString() : '-';
        },
        formatDecimal(value) {
            return value != null ? value.toFixed(1) : '-';
        },
        formatPercentage(value) {
            return value != null ? `${(value).toFixed(0)}%` : '-';
        }
    }
};
</script>

<style scoped>

</style>

