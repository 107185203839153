<!-- ThreeCircleGauge.vue -->
<template>
    <div class="gauge-container" :style="{ width: size + 'px', height: size + 'px' }">
        <svg :width="size" :height="size">
            <!-- Outer circle (Survival) -->
            <circle :cx="center" :cy="center" :r="outerRadius" fill="none" stroke="#333" :stroke-width="strokeWidth" />
            <path :d="survivalPath" fill="none" stroke="#5EBAF0" :stroke-width="strokeWidth"
                stroke-linecap="round" :stroke-dasharray="`${survival * outerCircumference} ${outerCircumference}`" />

            <!-- Middle circle (Health) -->
            <circle :cx="center" :cy="center" :r="middleRadius" fill="none" stroke="#333" :stroke-width="strokeWidth" />
            <path :d="healthPath" fill="none" stroke="#B9F6FC" :stroke-width="strokeWidth"
                stroke-linecap="round" :stroke-dasharray="`${health * middleCircumference} ${middleCircumference}`" />

            <!-- Inner circle (Bleaching) -->
            <circle :cx="center" :cy="center" :r="innerRadius" fill="none" stroke="#333" :stroke-width="strokeWidth" />
            <path :d="bleachPath" fill="none" stroke="#F9F5FE" :stroke-width="strokeWidth"
                stroke-linecap="round" :stroke-dasharray="`${bleach * innerCircumference} ${innerCircumference}`" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'ThreeCircleGauge',
    props: {
        survival: {
            type: Number,
            default: 0
        },
        health: {
            type: Number,
            default: 0
        },
        bleach: {
            type: Number,
            default: 0
        },
        size: {
            type: Number,
            default: 96
        }
    },
    computed: {
        center() {
            return this.size / 2;
        },
        strokeWidth() {
            return this.size / 12;
        },
        outerRadius() {
            return this.size / 2 - this.strokeWidth / 2;
        },
        middleRadius() {
            return this.outerRadius - this.strokeWidth * 1.5;
        },
        innerRadius() {
            return this.middleRadius - this.strokeWidth * 1.5;
        },
        outerCircumference() {
            return 2 * Math.PI * this.outerRadius;
        },
        middleCircumference() {
            return 2 * Math.PI * this.middleRadius;
        },
        innerCircumference() {
            return 2 * Math.PI * this.innerRadius;
        },
        survivalPath() {
            return this.describeArc(this.center, this.center, this.outerRadius, 0, 359.99);
        },
        healthPath() {
            return this.describeArc(this.center, this.center, this.middleRadius, 0, 359.99);
        },
        bleachPath() {
            return this.describeArc(this.center, this.center, this.innerRadius, 0, 359.99);
        }
    },
    methods: {
        polarToCartesian(centerX, centerY, radius, angleInDegrees) {
            const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
            return {
                x: centerX + (radius * Math.cos(angleInRadians)),
                y: centerY + (radius * Math.sin(angleInRadians))
            };
        },
        describeArc(x, y, radius, startAngle, endAngle) {
            const start = this.polarToCartesian(x, y, radius, startAngle);
            const end = this.polarToCartesian(x, y, radius, endAngle);
            const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
            return [
                "M", start.x, start.y,
                "A", radius, radius, 0, largeArcFlag, 1, end.x, end.y
            ].join(" ");
        }
    }
}
</script>

<style scoped>
.gauge-container {
    display: inline-block;
}
</style>