<template>
    <div class="sparkle-container" :style="containerStyle">
        <div v-for="(hexagon, index) in hexagons" :key="index" class="hexagon" :style="hexagonStyle(hexagon)">
        </div>
    </div>
</template>

<script>
export default {
    name: 'SparkleAnimation',
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hexagons: [],
            hexSize: 10,
            animationDuration: 1000,
        }
    },
    computed: {
        containerStyle() {
            return {
                width: `${this.width}px`,
                height: `${this.height}px`,
            }
        }
    },
    watch: {
        active(newValue) {
            if (newValue) {
                this.startAnimation();
            } else {
                this.clearAnimation();
            }
        }
    },
    methods: {
        startAnimation() {
            this.clearAnimation();
            this.generateHexagons();
            this.animateHexagons();
        },
        clearAnimation() {
            this.hexagons = [];
        },
        generateHexagons() {
            const cols = Math.ceil(this.width / (this.hexSize * 1.5));
            const rows = Math.ceil(this.height / (this.hexSize * Math.sqrt(3)));

            for (let row = 0; row < rows; row++) {
                for (let col = 0; col < cols; col++) {
                    const x = col * this.hexSize * 1.5;
                    const y = row * this.hexSize * Math.sqrt(3) + (col % 2 ? this.hexSize * Math.sqrt(3) / 2 : 0);
                    this.hexagons.push({ x, y, delay: 0 });
                }
            }
        },
        animateHexagons() {
            const centerX = this.width / 2;
            const centerY = this.height / 2;

            this.hexagons.forEach(hexagon => {
                const dx = hexagon.x - centerX;
                const dy = hexagon.y - centerY;
                const distance = Math.sqrt(dx * dx + dy * dy);
                hexagon.delay = (distance / Math.max(this.width, this.height)) * this.animationDuration;
            });
        },
        hexagonStyle(hexagon) {
            return {
                left: `${hexagon.x}px`,
                top: `${hexagon.y}px`,
                width: `${this.hexSize}px`,
                height: `${this.hexSize}px`,
                border: '1px solid #27bdf4', // Add border with the specified color
                backgroundColor: '#27bdf4', // Make the background transparent
                animationDelay: `${hexagon.delay}ms`,
            }
        }
    }
}
</script>

<style scoped>
.sparkle-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
}

.hexagon {
    position: absolute;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    opacity: 0;
    animation: sparkle 1s ease-out forwards;
    box-sizing: border-box;
}

@keyframes sparkle {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}
</style>