<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-80">
        <h1 class="title text-sm text-left font-black font-lato mb-2">
            <span class="px-2 py-1 rounded-lg text-black" :style="{ backgroundColor: stageColor }">
                {{ stageName }}
            </span>
        </h1>
        <div class="flex-grow flex flex-col items-center justify-center">
            <svg class="w-40 h-40 mb-2">
                <circle cx="80" cy="80" r="72" fill="none" stroke="#333" stroke-width="14" stroke-linecap="round" />
                <circle
                    cx="80"
                    cy="80"
                    r="72"
                    fill="none"
                    :stroke="stageColor"
                    stroke-width="14"
                    stroke-linecap="round"
                    :stroke-dasharray="`${progressPercentage * 452.39} 452.39`"
                    transform="rotate(-90 80 80)"
                />
                <text
                    x="80"
                    y="80"
                    text-anchor="middle"
                    dominant-baseline="central"
                    fill="white"
                    font-size="30"
                    class="font-black font-lato"
                >
                    {{ displayValue }}
                </text>
                <!-- <text
                    x="80"
                    y="96"
                    text-anchor="middle"
                    dominant-baseline="central"
                    fill="#999"
                    font-size="14"
                >
                    {{ displayGoal }}
                </text> -->
            </svg>
        </div>
        <div class="flex justify-between mt-4">
            <div class="w-1/2 bg-neutral-800 rounded-lg px-2 py-1 mr-2">
                <p class="text-xl font-black font-lato text-white">{{ summary.corals.toLocaleString() }}</p>
                <p class="text-sm text-neutral-400">Corals</p>
            </div>
            <div class="w-1/2 bg-neutral-800 rounded-lg px-2 py-1 ml-2">
                <p class="text-xl font-black font-lato text-white">{{ summary.age.toFixed(0) }}</p>
                <p class="text-sm text-neutral-400">Age</p>
            </div>
        </div>
    </div>
</template>

<script>
import { doc, getDoc } from 'firebase/firestore';
import { db_resto } from '../main';

export default {
    name: 'NurseryProgress',
    props: {
        data: {
            type: Object,
            required: true
        },
        nurseryId: {
            type: String,
            required: true
        },
        branchId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            stage: 'growing',
            goals: {},
            stageColors: {
                seeding: '#BA76FA',
                growing: '#27bdf4',
                planting: '#4971FF'
            }
        };
    },
    mounted() {
        this.getNurseryData();
    },
    computed: {
        summary() {
            return this.data?.nursery?.summary || {
                corals: 0,
                age: 0
            };
        },
        stageColor() {
            return this.stageColors[this.stage] || '#A3A3A3';
        },
        stageName() {
            return this.stage.charAt(0).toUpperCase() + this.stage.slice(1);
        },
        stageGoal() {
            if (this.stage === 'seeding') {
                return this.goals.seeding || 5400;
            } else if (this.stage === 'growing') {
                return (this.goals.growing || 2) * 365;
            } else if (this.stage === 'planting') {
                return this.goals.planting || 0;
            }
            return 0;
        },
        progressPercentage() {
            if (this.stage === 'seeding') {
                return Math.min(this.summary.corals / this.stageGoal, 1);
            } else if (this.stage === 'growing') {
                return Math.min(this.summary.age / this.stageGoal, 1);
            } else if (this.stage === 'planting') {
                return 0.75; // TODO: how to track this? (0.75 is a placeholder)
            }
            return 0;
        },
        displayValue() {
            // if (this.stage === 'seeding') {
            //     return this.summary.corals.toLocaleString();
            // } else if (this.stage === 'growing') {
            //     return this.summary.age.toFixed(0);
            // } else {
            //     return (this.progressPercentage * 100).toFixed(0) + '%';
            // }
            return (this.progressPercentage * 100).toFixed(0) + '%';
        },
        displayGoal() {
            if (this.stage === 'seeding') {
                return `/ ${this.stageGoal.toLocaleString()} corals`;
            } else if (this.stage === 'growing') {
                return `/ ${this.stageGoal.toFixed(0)} days`;
            } else {
                return 'Planting Progress';
            }
        }
    },
    methods: {
        async getNurseryData() {
            const nurseryRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}`);
            try {
                const docSnap = await getDoc(nurseryRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    if (data.goals) {
                        this.goals = data.goals;
                    }
                    if (data.stage) {
                        this.stage = data.stage;
                    }
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.log('Error getting document:', error);
            }
        }
    }
};
</script>

<style scoped>
/* Add your styles here */
</style>
</script>