<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-[350px]  max-w-[900px]">
        <h1 class="text-left text-2xl font-black font-lato mb-4">CORALS</h1>
        <div class="flex-grow relative">
            <canvas ref="coralChart" class="max-w-full max-h-[230px]"></canvas>
        </div>
        <div class="flex justify-start mt-2.5 text-xs">
            <span class="flex items-center mr-4">
                <span class="w-3 h-3 rounded-full bg-[#0C508D] inline-block mr-1.5"></span>
                In nurseries
            </span>
            <span class="flex items-center">
                <span class="w-3 h-3 rounded-full bg-[#27bdf4] inline-block mr-1.5"></span>
                In outplant sites
            </span>
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export default {
    name: 'CoralCard',
    props: ['time_series'],
    data() {
        return {
            chart: null,
        };
    },
    mounted() {
        this.createChart();
        window.addEventListener('resize', this.resizeChart);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeChart);
        if (this.chart) {
            this.chart.destroy();
        }
    },
    methods: {
        createChart() {
            if (!this.time_series.length) {
                return;
            }
            if (this.chart) {
                this.chart.destroy();
            }
            const ctx = this.$refs.coralChart.getContext('2d');
            
            const years = [...new Set(this.time_series.map(item => item.year))].sort();

            const cumulativeData = years.map(year => {
                const dataUpToYear = this.time_series.filter(item => item.year <= year);
                const inNurseries = dataUpToYear.reduce((sum, item) => sum + item.in_nurseries, 0);
                const outplanted = dataUpToYear.reduce((sum, item) => sum + item.outplanted, 0);
                return { year, inNurseries, outplanted };
            });

            this.chart = new ChartJS(ctx, {
                type: 'bar',
                data: {
                    labels: cumulativeData.map(d => d.year),
                    datasets: [
                        {
                            label: 'In nurseries',
                            data: cumulativeData.map(d => d.inNurseries),
                            backgroundColor: '#0C508D',
                            barThickness: 15,
                        },
                        {
                            label: 'In outplant sites',
                            data: cumulativeData.map(d => d.outplanted),
                            backgroundColor: '#27bdf4',
                            barThickness: 15,
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        title: {
                            display: false,
                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                display: true,
                                borderDash: [4, 4],
                                color: 'rgba(255, 255, 255, 0.2)'
                            },
                            ticks: {
                                color: 'white',
                            }
                        },
                        y: {
                            stacked: true,
                            grid: {
                                display: true,
                                borderDash: [4, 4],
                                color: 'rgba(255, 255, 255, 0.2)'
                            },
                            ticks: {
                                color: 'white',
                            }
                        }
                    }
                }
            });
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        }
    }
}
</script>
