<template>
    <div v-if="cell"
        :class="['bg-neutral-800', 'rounded-lg', 'shadow-md', 'text-white', 'flex', 'cursor-pointer', 'hover:shadow-lg', 'transition-shadow', 'duration-300', 'min-h-[400px]', isMobile ? 'flex-col' : 'flex-row']">
        <!-- Left side: Cell information -->
        <div v-if="cellStats" :class="[isMobile ? 'w-full' : 'w-1/2', 'pr-4', 'p-4']">
            <h2 class="text-xl font-black font-lato mb-4 capitalize">{{ cell.type }} {{ cell.name }}</h2>

            <div class="grid grid-cols-3 gap-4 mb-6">
                <div v-for="(stat, index) in cellStats" :key="index" class="flex flex-col bg-black rounded-lg p-4">
                    <span class="text-2xl font-black font-lato">{{ formatPercentage(stat.value) }}</span>
                    <span class="text-sm text-neutral-400">{{ stat.label }}</span>
                </div>
            </div>

            <div class="text-sm">
                <p>Last monitored: {{ formatFirestoreTimestamp(lastHealth.timestamp) }}</p>
            </div>
        </div>

        <!-- Right side: Photogrammetry -->
        <div :class="[isMobile ? 'w-full mt-4' : 'w-1/2']">
            <Photogrammetry :url="url" :modelTransform="modelTransform" />
        </div>
    </div>
</template>



<script>
import { db_resto } from '../main';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import Photogrammetry from './Photogrammetry.vue';

export default {
    name: 'OutplantCell',
    components: {
        Photogrammetry
    },
    props: {
        branchId: { type: String, required: true },
        outplantSiteId: { type: String, required: true },
        cellId: { type: String, required: true }
    },
    data() {
        return {
            cell: null,
            bucket: 'https://storage.googleapis.com/restoration-ios.appspot.com',
        };
    },
    mounted() {
        this.fetchOutplantCellData()
    },
    watch: {
        cellId() {
            this.cell = null;
            this.fetchOutplantCellData();
        }
    },
    computed: {
        url() {
            return this.bucket + '/' + this.lastHealth.photogrammetryPath;
        },
        modelTransform() {
            return this.lastHealth.modelTransform || null;
        },
        lastHealth() {
            return this.cell.healths[this.cell.healths.length - 1];
        },
        cellStats() {
            const monitoring = this.lastHealth.cellMonitoring;
            if (!monitoring) return [];
            return [
                { label: 'Bleaching', value: monitoring.bleach },
                { label: 'Coral Cover', value: monitoring.cover },
                { label: 'Survival', value: monitoring.survival }
            ];
        },
        isMobile() {
            return window.innerWidth < 768;
        }
    },
    methods: {
        async fetchOutplantCellData() {
            try {
                const cellRef = doc(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'OutplantSites', this.outplantSiteId, 'OutplantCells', this.cellId);
                const docSnap = await getDoc(cellRef);
                if (docSnap.exists()) {
                    this.cell = { id: docSnap.id, ...docSnap.data() };
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching outplant cell data:", error);
            }
        },
        formatPercentage(value) {
            return (value * 1).toFixed(0) + '%';
        },
        formatFirestoreTimestamp(timestamp) {
            if (timestamp instanceof Timestamp) {
                return new Date(timestamp.toDate()).toLocaleDateString();
            }
            return 'Invalid Date';
        }
    },
}
</script>

<style scoped>
/* Add any additional styles here */
</style>