import { linearRegression, linearRegressionLine } from 'simple-statistics';

/**
 * Calculates trend analysis for a given time series data.
 * @param {Array|Object} dataPoints - Array of objects or object with x (Date) and y (number) properties.
 * @returns {Object|null} Trend analysis results or null if insufficient data.
 */
export function calculateTrendAnalysis(dataPoints) {

    // Filter out invalid data points
    const validDataPoints = dataPoints.filter(point => point.y !== null && !isNaN(point.y));

    // If we have less than 2 valid data points, we can't calculate a trend
    if (validDataPoints.length < 2) {
        return null;
    }

    // Get the first date to normalize x-values
    const firstDate = validDataPoints[0].x;

    // Convert data points to [x, y] pairs for simple-statistics
    // x is the number of days since firstDate
    const timeSeriesData = validDataPoints.map(point => [
        (point.x.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24), // x in days since first date
        point.y
    ]);

    const regression = linearRegression(timeSeriesData);
    const regressionLine = linearRegressionLine(regression);

    const firstX = timeSeriesData[0][0]; // Should be zero
    const lastX = timeSeriesData[timeSeriesData.length - 1][0];
    const firstValue = regressionLine(firstX);
    const lastValue = regressionLine(lastX);

    const absoluteChange = lastValue - firstValue;
    const percentChange = (absoluteChange / Math.abs(firstValue)) * 100;

    const rSquared = calculateRSquared(timeSeriesData, regression);

    // Generate trendline data
    const trendlineData = validDataPoints.map(point => {
        const x = (point.x.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24);
        return {
            x: point.x,
            y: regressionLine(x)
        };
    });

    return {
        slope: regression.m, // Units are y per day
        intercept: regression.b, // y value at x = 0 (which is firstDate)
        absoluteChange: absoluteChange,
        percentChange: percentChange,
        rSquared: rSquared,
        predict: (date) => {
            const xValue = (new Date(date).getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24);
            return regressionLine(xValue);
        },
        trendlineData: trendlineData,
    };
}

/**
 * Calculates the R-squared value for the regression.
 * @param {Array} data - The time series data as [x, y] pairs.
 * @param {Object} regression - The regression object with m (slope) and b (intercept).
 * @returns {number} The R-squared value.
 */
function calculateRSquared(data, regression) {
    const yMean = data.reduce((sum, [_, y]) => sum + y, 0) / data.length;
    const ssTotal = data.reduce((sum, [_, y]) => sum + Math.pow(y - yMean, 2), 0);
    const ssResidual = data.reduce((sum, [x, y]) => {
        const yPredicted = regression.m * x + regression.b;
        return sum + Math.pow(y - yPredicted, 2);
    }, 0);
    return 1 - ssResidual / ssTotal;
}

export function createTrendlineDataset(label, trendlineData, color = 'rgba(255, 99, 132, 1)') {
    return {
        label: label,
        data: trendlineData,
        borderColor: color,
        backgroundColor: 'transparent',
        borderWidth: 0.75,
        fill: false,
        pointRadius: 0,
        tension: 0,
    };
}