<template>
    <div class="outer-container">
        <div v-if="editedSite" class="inner-container flex flex-col md:flex-row bg-black rounded-lg shadow-md text-white relative">
            <div class="w-full md:w-1/2 p-4 flex flex-col overflow-y-auto no-scrollbar">
                <div class="flex justify-between items-center mb-1">
                    <h2 class="text-2xl font-black font-lato">Edit Restoration Site</h2>
                    <span class="bg-white text-black px-2 py-1 rounded-lg font-black font-lato text-sm capitalize">{{ editedSite.type }}</span>
                </div>
                <p class="text-neutral-500 mb-4">{{ formattedCreatedDate }}</p>
                
                <!-- Form content -->
                <div class="mb-4">
                    <div class="mb-4">
                        <label for="siteName" class="block mb-2">Site Name</label>
                        <input v-model="editedSite.name" id="siteName" type="text" class="w-full bg-neutral-700 rounded p-2" required>
                    </div>
                    <div class="mb-4">
                        <label for="controlSite" class="block mb-2">
                            {{ editedSite.type === 'control' ? 'Link Outplant Site' : 'Link Control Site' }}
                        </label>
                        <Dropdown 
                            v-model="selectedControlSite" 
                            :options="controlSites" 
                            :placeholder="editedSite.type === 'control' ? 'Select an Outplant Site' : 'Select a Control Site'" 
                            :clearable="true"
                            @clear="clearControlSite"
                        />
                    </div>
                    <div class="mb-4">
                        <label for="reefOSDevice" class="block mb-2">Link ReefOS Device</label>
                        <Dropdown 
                            v-model="selectedReefOSDevice" 
                            :options="reefOSDevices" 
                            placeholder="Select a ReefOS Device" 
                            :clearable="true"
                            @clear="clearReefOSDevice"
                        />
                        <div v-if="!isReefOSDeviceInsidePolygon && selectedReefOSDevice" class="flex items-center text-red-500 mt-2">
                            <i class="mdi mdi-alert-circle-outline mr-2"></i>
                            <span>The selected ReefOS device is not inside the restoration area</span>
                        </div>
                    </div>

                    <!-- Restoration Goals Section -->
                    <div class="mb-4" v-if="editedSite.restorationGoals">
                        <h3 class="text-lg font-semibold mb-2">Restoration Goals</h3>
                        <div class="mb-4">
                            <label for="coralCover" class="block">Coral Cover (%)</label>
                            <div class="relative">
                                <input v-model.number="editedSite.restorationGoals.coralCover" id="coralCover" type="range" min="0" max="100" class="w-full range-slider" @input="updateRestorationGoals">
                                <div class="flex justify-between text-neutral-400 mt-1">
                                    <span>{{ computedCoralsPerSquareMeter }} corals/m²</span>
                                    <span>{{ editedSite.restorationGoals.coralCover }}%</span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-1">
                            <label for="expectedSurvival" class="block">Expected Survival (%)</label>
                            <div class="relative">
                                <input v-model.number="editedSite.restorationGoals.expectedSurvival" id="expectedSurvival" type="range" min="0" max="100" class="w-full range-slider">
                                <div class="flex justify-between text-neutral-400 mt-1">
                                    <span></span>
                                    <span>{{ editedSite.restorationGoals.expectedSurvival }}%</span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="restorableSubstrate" class="block">Restorable Substrate (%)</label>
                            <div class="relative">
                                <input v-model.number="editedSite.restorationGoals.restorableSubstrate" id="restorableSubstrate" type="range" min="0" max="100" class="w-full range-slider">
                                <div class="flex justify-between text-neutral-400 mt-1">
                                    <span>{{ formattedRestorableArea }} m² / {{ formattedPolygonAreaHectares }}</span>
                                    <span>{{ editedSite.restorationGoals.restorableSubstrate }}%</span>
                                </div>
                            </div>
                        </div>
                        <div class="text-lg">
                            Total Corals: <strong>{{ formattedTotalCorals }}</strong>
                        </div>
                    </div>

                    <!-- Monitoring Specifications Section -->
                    <div class="mb-4">
                        <h3 class="text-lg font-semibold mb-2">Monitoring Specifications</h3>
                        <div class="ml-4">
                            <div class="mb-2">
                                <label class="inline-flex items-center">
                                    <input type="checkbox" v-model="editedSite.monitoringSettings.transectSurvey.enabled" class="form-checkbox text-primary">
                                    <span class="ml-2">Transect Survey</span>
                                </label>
                                <div v-if="editedSite.monitoringSettings.transectSurvey.enabled" class="ml-4 mt-2 flex items-center">
                                    <input type="number" v-model.number="editedSite.monitoringSettings.transectSurvey.number" min="1" placeholder="Number" class="w-20 bg-neutral-700 rounded p-1 mr-2">
                                    <select v-model="editedSite.monitoringSettings.transectSurvey.frequency" class="bg-neutral-700 rounded p-1">
                                        <option value="monthly">Monthly</option>
                                        <option value="quarterly">Quarterly</option>
                                        <option value="annually">Annually</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-2">
                                <label class="inline-flex items-center">
                                    <input type="checkbox" v-model="editedSite.monitoringSettings.spotSurvey.enabled" class="form-checkbox text-primary">
                                    <span class="ml-2">Spot Survey</span>
                                </label>
                                <div v-if="editedSite.monitoringSettings.spotSurvey.enabled" class="ml-4 mt-2 flex items-center">
                                    <input type="number" v-model.number="editedSite.monitoringSettings.spotSurvey.number" min="1" placeholder="Number" class="w-20 bg-neutral-700 rounded p-1 mr-2">
                                    <select v-model="editedSite.monitoringSettings.spotSurvey.frequency" class="bg-neutral-700 rounded p-1">
                                        <option value="monthly">Monthly</option>
                                        <option value="quarterly">Quarterly</option>
                                        <option value="annually">Annually</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-2">
                                <label class="inline-flex items-center">
                                    <input type="checkbox" v-model="editedSite.monitoringSettings.photogrammetry.enabled" class="form-checkbox text-primary">
                                    <span class="ml-2">Photogrammetry</span>
                                </label>
                                <div v-if="editedSite.monitoringSettings.photogrammetry.enabled" class="ml-4 mt-2 flex items-center">
                                    <input type="number" v-model.number="editedSite.monitoringSettings.photogrammetry.cellsPercentage" min="1" max="100" placeholder="% of cells" class="w-20 bg-neutral-700 rounded p-1 mr-2">
                                    <span class="mr-2">% of cells</span>
                                    <select v-model="editedSite.monitoringSettings.photogrammetry.frequency" class="bg-neutral-700 rounded p-1">
                                        <option value="monthly">Monthly</option>
                                        <option value="quarterly">Quarterly</option>
                                        <option value="annually">Annually</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-2">
                                <label class="inline-flex items-center">
                                    <input type="checkbox" v-model="editedSite.monitoringSettings.waterQuality.enabled" class="form-checkbox text-primary">
                                    <span class="ml-2">Water Quality</span>
                                </label>
                                <div v-if="editedSite.monitoringSettings.waterQuality.enabled" class="ml-4 mt-2 flex items-center">
                                    <select v-model="editedSite.monitoringSettings.waterQuality.frequency" class="bg-neutral-700 rounded p-1">
                                        <option value="monthly">Monthly</option>
                                        <option value="quarterly">Quarterly</option>
                                        <option value="annually">Annually</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Right side: Map -->
            <div class="w-full md:w-1/2 mt-4 md:mt-0 flex flex-col flex-grow">
                <div ref="map" class="flex-grow relative" :class="[
                    isMobile ? 'rounded-bl-lg rounded-br-lg' : 'rounded-tr-lg rounded-br-lg'
                ]">
                    <div v-if="polygonArea" style="z-index: 1000;" class="absolute top-4 right-2 bg-white text-black px-2 py-1 rounded-lg font-black font-lato text-sm">
                        {{ formattedPolygonArea }} / {{ formattedPolygonAreaHectares }}
                    </div>
                    <!-- Add this button for editing the polygon -->
                    <div class="absolute top-4 left-2 flex" style="z-index: 1000;">
                        <button @click="toggleEditMode" class="bg-black text-white px-2 py-1 rounded-lg font-black font-lato text-sm mr-2"
                        :class="[isEditing ? 'mdi mdi-check bg-green-500' : 'mdi mdi-pencil']">
                            {{ isEditing ? 'Save' : 'Edit Area' }}
                        </button>
                        <button v-if="isEditing" @click="cancelPolygonEdit" class="bg-red-500 text-white px-2 py-1 rounded-lg font-black font-lato text-sm mdi mdi-close">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Add Save and Cancel buttons outside the card -->
        <div class="flex justify-end mt-4">
            <button @click="cancel" class="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600 transition-colors duration-300">
                Cancel
            </button>
            <button @click="save" 
                    class="bg-primary text-white px-4 py-2 rounded transition-colors duration-300"
                    :class="{ 'opacity-50 cursor-not-allowed': !isFormValid, 'hover:bg-primary-dark': isFormValid }"
                    :disabled="!isFormValid">
                Save
            </button>
        </div>
    </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { polygon, area, point, booleanPointInPolygon } from '@turf/turf';
import '@mdi/font/css/materialdesignicons.css';
import Dropdown from '@/components/utils/Dropdown.vue';
import { db_resto, db_map } from '@/main';
import { doc, getDoc, updateDoc, collection, getDocs, query } from 'firebase/firestore';
import { EventBus } from '@/event-bus';

export default {
    name: 'SiteEdit',
    components: {
        Dropdown
    },
    props: {
        branchId: {
            type: String,
            required: true
        },
        siteId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            site: null,
            editedSite: null,
            map: null,
            featureGroup: null,
            currentPolygon: null,
            isEditing: false,
            controlSites: [],
            selectedControlSite: null,
            reefOSDevices: [],
            selectedReefOSDevice: null,
            deviceMarker: null,
            originalPolygon: null,
            outplantCells: [],
        };
    },
    computed: {
        computedCoralsPerSquareMeter() {
            return Math.ceil((this.editedSite.restorationGoals.coralCover / 50) * 5);
        },
        formattedRestorableArea() {
            const restorableArea = (this.polygonArea * this.editedSite.restorationGoals.restorableSubstrate) / 100;
            return Number(restorableArea.toFixed(0)).toLocaleString();
        },
        formattedRestorableAreaHectares() {
            const hectares = (this.polygonArea * this.editedSite.restorationGoals.restorableSubstrate / 100) / 10000;
            return hectares.toFixed(1) + ' ha';
        },
        formattedTotalCorals() {
            const restorableArea = (this.polygonArea * this.editedSite.restorationGoals.restorableSubstrate / 100);
            const survivalFactor = 1 / (this.editedSite.restorationGoals.expectedSurvival / 100);
            const totalCorals = restorableArea * this.computedCoralsPerSquareMeter * survivalFactor;
            return Number(totalCorals.toFixed(0)).toLocaleString();
        },
        polygonArea() {
            if (this.currentPolygon) {
                const coordinates = this.currentPolygon.getLatLngs()[0].map(latLng => [latLng.lng, latLng.lat]);
                coordinates.push(coordinates[0]); // Close the polygon
                const poly = polygon([coordinates]);
                return area(poly);
            }
            return 0;
        },
        isFormValid() {
            return this.editedSite && 
                   this.editedSite.name && 
                   this.currentPolygon && 
                   this.editedSite.perimeter.length > 0;
        },
        formattedCreatedDate() {
            if (this.editedSite && this.editedSite.created) {
                const date = this.editedSite.created.toDate();
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                return date.toLocaleDateString(undefined, options);
            }
            return '';
        },
        isMobile() {
            return window.innerWidth < 768;
        },
        formattedPolygonArea() {
            return this.polygonArea > 0 
                ? `${Number(this.polygonArea.toFixed(0)).toLocaleString()} m²` 
                : '0 m²';
        },
        formattedPolygonAreaHectares() {
            const hectares = this.polygonArea / 10000;
            return hectares.toFixed(1) + ' ha';
        },
        isReefOSDeviceInsidePolygon() {
            if (this.selectedReefOSDevice && this.currentPolygon) {
                const deviceLocation = point([this.selectedReefOSDevice.location._long, this.selectedReefOSDevice.location._lat]);
                const coordinates = this.currentPolygon.getLatLngs()[0].map(latLng => [latLng.lng, latLng.lat]);
                coordinates.push(coordinates[0]); // Close the polygon
                const poly = polygon([coordinates]);
                return booleanPointInPolygon(deviceLocation, poly);
            }
            return false;
        }
    },
    mounted() {
        this.fetchSiteData();
        this.fetchControlSites();
    },
    watch: {
        selectedReefOSDevice(newDevice) {
            this.updateDeviceMarker(newDevice);
        },
        outplantCells() {
            this.addOutplantCells();
        },
    },
    methods: {
        async fetchSiteData() {
            try {
                const siteRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/OutplantSites/${this.siteId}`);
                const siteDoc = await getDoc(siteRef);
                
                if (siteDoc.exists()) {
                    this.site = { id: siteDoc.id, ...siteDoc.data() };
                    this.site.type = 'outplant';
                    
                    // Create a new object with default values
                    this.editedSite = {
                        ...this.site,
                        restorationGoals: {
                            coralCover: 50,
                            expectedSurvival: 75,
                            restorableSubstrate: 50,
                            coralsPerSquareMeter: 0,
                            restorableArea: 0,
                            totalCorals: 0,
                            ...this.site.restorationGoals, // Merge existing values if any
                        },
                        monitoringSettings: {
                            transectSurvey: { enabled: false, number: 1, frequency: 'monthly' },
                            spotSurvey: { enabled: false, number: 1, frequency: 'monthly' },
                            photogrammetry: { enabled: false, cellsPercentage: 10, frequency: 'quarterly' },
                            waterQuality: { enabled: false, frequency: 'monthly' },
                            ...this.site.monitoringSettings, // Merge existing values if any
                        },
                    };
                    
                    this.$nextTick(() => {
                        this.initMap();
                        this.fetchOutplantCells();
                        this.fetchReefOSDevices();
                    });
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching site data:', error);
            }
        },
        initMap() {
            if (this.map) {
                this.map.remove();
            }
            this.map = L.map(this.$refs.map, {
                center: [this.editedSite.geoLocation.latitude, this.editedSite.geoLocation.longitude],
                zoom: 13,
                attributionControl: false,
                zoomControl: false,
            });

            const mapboxAccessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
            L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + mapboxAccessToken, {
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.map);

            this.featureGroup = new L.FeatureGroup().addTo(this.map);

            this.addPolygon();
            this.fitMapBounds();
            this.addEditHandleStyle();
            this.addOutplantCells();
        },
        addPolygon() {
            if (this.editedSite.perimeter && this.editedSite.perimeter.length > 0) {
                const coordinates = this.editedSite.perimeter.map(point => [point.latitude, point.longitude]);
                this.currentPolygon = L.polygon(coordinates, {
                    color: '#ffa500',
                    weight: 3,
                    opacity: 0.7,
                    fillColor: '#ffa500',
                    fillOpacity: 0.2
                }).addTo(this.featureGroup);
            }
        },
        fitMapBounds() {
            if (this.currentPolygon) {
                this.map.fitBounds(this.currentPolygon.getBounds());
            }
        },
        toggleEditMode() {
            if (this.isEditing) {
                this.stopEditing();
            } else {
                this.startEditing();
            }
            this.isEditing = !this.isEditing;
            this.updatePolygonStyle();
        },
        startEditing() {
            if (this.currentPolygon) {
                // Store the original polygon coordinates before editing
                this.originalPolygon = this.currentPolygon.getLatLngs()[0].map(latLng => ({
                    latitude: latLng.lat,
                    longitude: latLng.lng
                }));
                this.currentPolygon.editing.enable();
            }
        },
        stopEditing() {
            if (this.currentPolygon) {
                this.currentPolygon.editing.disable();
                this.updatePerimeter();
                this.originalPolygon = null; // Clear the stored original polygon
            }
        },
        cancelPolygonEdit() {
            if (this.originalPolygon) {
                // Restore the original polygon
                const coordinates = this.originalPolygon.map(point => [point.latitude, point.longitude]);
                this.currentPolygon.setLatLngs(coordinates);
                this.stopEditing();
                this.isEditing = false;
                this.updatePolygonStyle();
            }
        },
        updatePolygonStyle() {
            if (this.currentPolygon) {
                const newStyle = this.isEditing
                    ? { dashArray: '5, 10', color: '#ffa500' }
                    : { dashArray: null, color: '#ffa500' };
                this.currentPolygon.setStyle(newStyle);
            }
        },
        updatePerimeter() {
            if (this.currentPolygon) {
                this.editedSite.perimeter = this.currentPolygon.getLatLngs()[0].map(latLng => ({
                    latitude: latLng.lat,
                    longitude: latLng.lng
                }));
            }
        },
        addEditHandleStyle() {
            const editHandleStyle = `
                .leaflet-editing-icon {
                    border-radius: 50%;
                    background-color: white;
                    border: 2px solid #ffa500;
                    width: 10px !important;
                    height: 10px !important;
                    margin-left: -5px !important;
                    margin-top: -5px !important;
                }
            `;
            const styleElement = document.createElement('style');
            styleElement.textContent = editHandleStyle;
            document.head.appendChild(styleElement);
        },
        cancel() {
            this.editedSite = JSON.parse(JSON.stringify(this.site));
            this.$emit('cancel');
        },
        async save() {
            if (this.isFormValid) {
                try {
                    const siteRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/OutplantSites/${this.siteId}`);
                    
                    // Update the editedSite object with the selected control site and ReefOS device
                    this.editedSite.controlSiteID = this.selectedControlSite ? this.selectedControlSite.id : null;
                    this.editedSite.reefos_id = this.selectedReefOSDevice ? this.selectedReefOSDevice.id : null;
                    
                    // Update restoration goals with computed values
                    this.editedSite.restorationGoals.coralsPerSquareMeter = this.computedCoralsPerSquareMeter;
                    this.editedSite.restorationGoals.restorableArea = parseFloat(this.formattedRestorableArea.replace(/,/g, ''));
                    this.editedSite.restorationGoals.totalCorals = parseInt(this.formattedTotalCorals.replace(/,/g, ''));
                    
                    await updateDoc(siteRef, this.editedSite);
                    console.log('Site updated successfully');
                    EventBus.$emit('show-toast', { message: 'Site updated successfully!', type: 'success', loading: false });
                    this.$emit('update', this.editedSite);
                } catch (error) {
                    console.error('Error updating site:', error);
                    EventBus.$emit('show-toast', { message: 'Error updating site', type: 'error', loading: false });
                }
            }
        },
        updateRestorationGoals() {
            // Ensure editedSite and restorationGoals exist
            if (this.editedSite && this.editedSite.restorationGoals) {
                this.$set(this.editedSite.restorationGoals, 'coralsPerSquareMeter', this.computedCoralsPerSquareMeter);
                this.$set(this.editedSite.restorationGoals, 'restorableArea', parseFloat(this.formattedRestorableArea.replace(/,/g, '')));
            }
        },
        async fetchControlSites() {
            try {
                const controlSitesRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/ControlSites`);
                const querySnapshot = await getDocs(controlSitesRef);
                this.controlSites = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().name
                }));
                
                // Set the selected control site if it exists
                if (this.editedSite.controlSiteID) {
                    this.selectedControlSite = this.controlSites.find(site => site.id === this.editedSite.controlSiteID);
                }
            } catch (error) {
                console.error('Error fetching sites:', error);
                this.controlSites = [];
            }
        },
        async fetchReefOSDevices() {
            try {
                const camerasRef = collection(db_map, 'Cameras');
                const q = query(camerasRef);
                const querySnapshot = await getDocs(q);
                this.reefOSDevices = querySnapshot.docs
                    .filter(doc => !doc.id.startsWith('debug'))
                    .map(doc => ({
                        id: doc.id,
                        name: doc.data().name || doc.id,
                        location: doc.data().location
                    }));
                
                // Set the selected ReefOS device if it exists
                if (this.editedSite.reefos_id) {
                    this.selectedReefOSDevice = this.reefOSDevices.find(device => device.id === this.editedSite.reefos_id);
                    this.updateDeviceMarker(this.selectedReefOSDevice);
                }
            } catch (error) {
                console.error('Error fetching ReefOS devices:', error);
                this.reefOSDevices = [];
            }
        },
        async fetchOutplantCells() {
            try {
                const cellsRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/OutplantSites/${this.siteId}/Cells`);
                const querySnapshot = await getDocs(cellsRef);
                this.outplantCells = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
            } catch (error) {
                console.error('Error fetching outplant cells:', error);
            }
        },
        createPulsingIcon() {
            return L.divIcon({
                className: 'pulsing-icon',
                iconSize: [5, 5],
                iconAnchor: [2.5, 2.5]
            });
        },
        updateDeviceMarker(device) {
            if (this.deviceMarker) {
                this.map.removeLayer(this.deviceMarker);
            }

            if (device && device.location) {
                this.deviceMarker = L.marker([device.location._lat, device.location._long], {
                    icon: this.createPulsingIcon(),
                    zIndexOffset: 1000
                }).addTo(this.map);

                this.deviceMarker.bindPopup(`<h3>${device.name || 'ReefOS Device'}</h3>`);
            }
        },
        addOutplantCells() {
            this.outplantCells.forEach(cell => {
                const marker = L.circleMarker([cell.geoLocation.latitude, cell.geoLocation.longitude], {
                    radius: 2,
                    fillColor: '#ffa500',
                    color: '#000',
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0.8
                }).addTo(this.map);

                marker.bindPopup(`<h3>${cell.name}</h3>`);
                marker.bringToFront();
            });
        },
        clearControlSite() {
            this.selectedControlSite = null;
            this.editedSite.controlSiteID = null;
        },
        clearReefOSDevice() {
            this.selectedReefOSDevice = null;
            this.editedSite.reefos_id = null;
            if (this.deviceMarker) {
                this.map.removeLayer(this.deviceMarker);
                this.deviceMarker = null;
            }
        },
    }
};
</script>

<style scoped>
.range-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px; /* Slider Track height */
    background: #27BDF4; /* Background Color */
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.range-slider:hover {
    opacity: 1;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px; /* Thumb width */
    height: 15px; /* Thumb height */
    border-radius: 50%;
    background: #27BDF4; /* Thumb Color */
    cursor: pointer;
}

.range-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #27BDF4;
    cursor: pointer;
}
.map-controls {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
}

.edit-button {
    background-color: #3490dc;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-button:hover {
    background-color: #2779bd;
}

.pulsing-icon {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 0, 0, 1);
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 0 rgba(255, 0, 0, 1);
    animation: pulse 5s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }
    50% {
        box-shadow: 0 0 15px 15px rgba(255, 0, 0, 0);
        opacity: 0.7;
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }
}
</style>