<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-200 max-w-[600px]">
        <h1 class="text-left text-2xl font-black font-lato">SURVIVAL</h1>
        <div class="flex flex-1">
            <div class="flex-1 flex flex-col justify-center items-center relative h-full">
                <canvas ref="chartRef" class="max-h-[70%]"></canvas>
                <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <div class="text-2xl font-black font-lato">{{ global_survival }}%</div>
                    <div class="text-xs text-gray-500 -mt-1">global</div>
                </div>
            </div>
            <div class="flex-1 bg-[#1c1c1c] rounded-lg p-2 flex flex-col">
                <div class="flex flex-col justify-between flex-grow w-full overflow-y-auto">
                    <div v-for="(branch, index) in branches" :key="index" class="mb-2" v-if="branch.data">
                        <div class="flex justify-between items-center mb-1">
                            <p class="text-white text-xs m-0">{{ branch.data.Branch }}</p>
                            <span class="text-sm text-[#27bdf4]">{{ (branch.data['Fraction Alive'] * 100).toFixed(0) }}%</span>
                        </div>
                        <div class="bg-[#0C508D] rounded h-2 relative">
                            <div class="h-full bg-[#27bdf4] rounded" :style="{ width: calculateProgress(branch.data) + '%' }">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
    name: 'SurvivalCard',
    props: ['global', 'branches'],
    data() {
        return {
            chart: null,
        };
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.destroy();
        }
    },
    mounted() {
        this.createChart();
    },
    computed: {
        global_survival() {
            return (this.global['Fraction Alive'] * 100).toFixed(0);
        }
    },
    methods: {
        createChart() {
            if (this.chart) {
                this.chart.destroy();
            }
            const ctx = this.$refs.chartRef.getContext('2d');
            this.chart = new ChartJS(ctx, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: [this.global_survival, 100 - this.global_survival],
                        backgroundColor: [
                            '#27bdf4',
                            '#333333'
                        ],
                        borderWidth: 0,
                        circumference: 360,
                        rotation: 0,
                        borderRadius: 2,
                    }]
                },
                options: {
                    responsive: true,
                    cutout: '80%',
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                }
            });
        },
        calculateProgress(branch) {
            const fractionAlive = branch['Fraction Alive'];
            return fractionAlive * 100;
        },
    },
};
</script>

