<template>
    <div class="h-screen w-full">
        <div ref="map" class="h-[calc(100%-80px)] w-full rounded-lg z-0"></div>
        <div v-if="isLoadingRelationships"
            class="loading-overlay absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white z-10">
            <div class="loading-spinner w-10 h-10 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin">
            </div>
            <p class="mt-2">Analyzing relationships...</p>
        </div>
    </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { collection, getDocs } from 'firebase/firestore';
import { db_resto } from '../main';

export default {
    name: 'BranchMap',
    props: {
        branchId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            map: null,
            nurseries: [],
            outplantSites: [],
            outplantCells: [],
            controlSites: [],
            motherColonies: [],
            layerGroups: {
                nurseries: L.layerGroup(),
                outplantSites: L.layerGroup(),
                controlSites: L.layerGroup(),
                motherColonies: L.layerGroup(),
                outplantCells: L.layerGroup(),
                motherColonyRelationships: L.layerGroup(),
                nurseryOutplantRelationships: L.layerGroup(),
            },
            motherColonyRelationships: {},
            nurseryOutplantRelationships: {},
            isLoadingRelationships: false,
            fragmentsLoaded: false,
        };
    },
    mounted() {
        this.fetchData();
    },
    beforeDestroy() {
        this.destroyMap();
    },
    methods: {
        async fetchData() {
            try {
                await Promise.all([
                    this.fetchNurseries(),
                    this.fetchOutplantSites(),
                    this.fetchControlSites(),
                    this.fetchMotherColonies(),
                ]);

                await this.fetchOutplantCells();
                this.initMap();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchNurseries() {
            const nurseriesRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'Nurseries');
            const nurseriesSnapshot = await getDocs(nurseriesRef);
            this.nurseries = nurseriesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        },
        async fetchOutplantSites() {
            const outplantSitesRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'OutplantSites');
            const outplantSitesSnapshot = await getDocs(outplantSitesRef);
            this.outplantSites = outplantSitesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        },
        async fetchOutplantCells() {
            const outplantCells = [];
            for (const site of this.outplantSites) {
                const cellsRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'OutplantSites', site.id, 'OutplantCells');
                const cellsSnapshot = await getDocs(cellsRef);
                const siteCells = cellsSnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        siteId: site.id,
                        ...doc.data()
                    }))
                    .filter(cell =>
                        cell.geoLocation &&
                        cell.geoLocation.latitude !== 0 &&
                        cell.geoLocation.longitude !== 0
                    );
                outplantCells.push(...siteCells);
            }
            this.outplantCells = outplantCells;
        },
        async fetchControlSites() {
            const controlSitesRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'ControlSites');
            const controlSitesSnapshot = await getDocs(controlSitesRef);
            this.controlSites = controlSitesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        },
        async fetchMotherColonies() {
            const motherColoniesRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'MotherColonies');
            const motherColoniesSnapshot = await getDocs(motherColoniesRef);
            this.motherColonies = motherColoniesSnapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
                .filter(colony =>
                    colony.geoLocation &&
                    colony.geoLocation.latitude !== 0 &&
                    colony.geoLocation.longitude !== 0
                );
        },
        async fetchFragments() {
            const fragmentsRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'Fragments');
            const fragmentsSnapshot = await getDocs(fragmentsRef);
            const fragments = fragmentsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            this.processFragmentRelationships(fragments);
        },
        processFragmentRelationships(fragments) {
            this.motherColonyRelationships = {};
            this.nurseryOutplantRelationships = {};

            fragments.forEach(fragment => {
                const colonyId = fragment.colonyID;
                let targetId, targetType;

                if (fragment.location && fragment.location.nurseryID) {
                    targetId = fragment.location.nurseryID;
                    targetType = 'nursery';
                } else if (fragment.outplantInfo && fragment.outplantInfo.outplantCellID) {
                    targetId = fragment.outplantInfo.outplantCellID;
                    targetType = 'outplantCell';

                    // Process Nursery/Outplant relationship
                    if (!fragment.location && fragment.previousLocations && fragment.previousLocations.length > 0) {
                        const lastNurseryLocation = fragment.previousLocations
                            .slice()
                            .reverse()
                            .find(loc => loc.nurseryID);

                        if (lastNurseryLocation && lastNurseryLocation.nurseryID) {
                            const nurseryId = lastNurseryLocation.nurseryID;
                            if (!this.nurseryOutplantRelationships[nurseryId]) {
                                this.nurseryOutplantRelationships[nurseryId] = [];
                            }
                            this.nurseryOutplantRelationships[nurseryId].push(targetId);
                        }
                    }
                }

                if (colonyId && targetId) {
                    if (!this.motherColonyRelationships[colonyId]) {
                        this.motherColonyRelationships[colonyId] = [];
                    }
                    
                    // Get the last health value that actually has a health field
                    const lastHealth = this.getLastValidHealth(fragment.healths);

                    this.motherColonyRelationships[colonyId].push({ 
                        targetId, 
                        targetType,
                        health: lastHealth
                    });
                }
            });
        },
        getLastValidHealth(healths) {
            if (!healths || !Array.isArray(healths)) {
                return null;
            }

            for (let i = healths.length - 1; i >= 0; i--) {
                if (healths[i].health !== undefined && healths[i].health !== null) {
                    return healths[i].health;
                }
            }

            return null;
        },
        initMap() {
            // Destroy existing map if it exists
            this.destroyMap();

            this.map = L.map(this.$refs.map, {
                zoomControl: false,
                attributionControl: false,
            }).setView([0, 0], 2);

            const mapboxAccessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
            L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + mapboxAccessToken, {
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.map);

            this.addMarkers();
            this.fitMapBounds();
            this.addLayersControl();
        },
        addMarkers() {
            this.nurseries.forEach(nursery => {
                if (nursery.geoLocation) {
                    L.marker([nursery.geoLocation.latitude, nursery.geoLocation.longitude], { icon: this.createCustomIcon('#27bdf4') })
                        .bindPopup(`<h3>${nursery.name}</h3>`)
                        .addTo(this.layerGroups.nurseries);
                }
            });

            this.outplantSites.forEach(site => {
                if (site.geoLocation) {
                    L.marker([site.geoLocation.latitude, site.geoLocation.longitude], { icon: this.createCustomIcon('orange') })
                        .bindPopup(`<h3>${site.name}</h3>`)
                        .addTo(this.layerGroups.outplantSites);

                    if (site.perimeter && site.perimeter.length > 0) {
                        const coordinates = site.perimeter.map(point => [point.latitude, point.longitude]);
                        L.polygon(coordinates, {
                            color: 'orange',
                            weight: 2,
                            opacity: 0.7,
                            fillColor: 'orange',
                            fillOpacity: 0.2
                        }).addTo(this.layerGroups.outplantSites);
                    }
                }
            });

            this.outplantCells.forEach(cell => {
                if (cell.geoLocation) {
                    L.circleMarker([cell.geoLocation.latitude, cell.geoLocation.longitude], {
                        radius: 2,
                        fillColor: '#ffa500',
                        color: '#000',
                        weight: 1,
                        opacity: 1,
                        fillOpacity: 0.8
                    })
                        .bindPopup(`<h3>Outplant Cell: ${cell.id}</h3><p>Site: ${cell.name}</p>`)
                        .addTo(this.layerGroups.outplantCells);
                }
            });

            this.controlSites.forEach(site => {
                if (site.geoLocation) {
                    L.marker([site.geoLocation.latitude, site.geoLocation.longitude], { icon: this.createCustomIcon('red') })
                        .bindPopup(`<h3>Control Site: ${site.name}</h3>`)
                        .addTo(this.layerGroups.controlSites);

                    // Add polygon for control site if perimeter exists
                    if (site.perimeter && site.perimeter.length > 0) {
                        const coordinates = site.perimeter.map(point => [point.latitude, point.longitude]);
                        L.polygon(coordinates, {
                            color: 'red',
                            weight: 2,
                            opacity: 0.7,
                            fillColor: 'red',
                            fillOpacity: 0.2
                        }).addTo(this.layerGroups.controlSites);
                    }
                }
            });

            this.motherColonies.forEach(colony => {
                if (colony.geoLocation) {
                    L.marker([colony.geoLocation.latitude, colony.geoLocation.longitude], { icon: this.motherColonyIcon() })
                        .bindPopup(`<h3>Mother Colony: ${colony.coral.genus.charAt(0).toUpperCase() + colony.coral.genus.slice(1)} ${colony.coral.species}</h3>`)
                        .addTo(this.layerGroups.motherColonies);
                }
            });
        },
        drawMotherColonyRelationshipLines() {
            this.layerGroups.motherColonyRelationships.clearLayers();

            Object.entries(this.motherColonyRelationships).forEach(([colonyId, relationships]) => {
                const colony = this.motherColonies.find(c => c.id === colonyId);
                if (!colony || !colony.geoLocation) return;

                relationships.forEach(({ targetId, targetType, health }) => {
                    let target;
                    if (targetType === 'nursery') {
                        target = this.nurseries.find(n => n.id === targetId);
                    } else if (targetType === 'outplantCell') {
                        target = this.outplantCells.find(c => c.id === targetId);
                    }

                    if (target && target.geoLocation) {
                        const lineColor = this.getHealthColor(health);
                        const lineOpacity = health === null ? 0.1 : 0.3; // Reduce opacity for white lines
                        const lineWeight = health === null ? 0.1 : 0.5; // Reduce weight for white lines
                        
                        const line = L.polyline(
                            [
                                [colony.geoLocation.latitude, colony.geoLocation.longitude],
                                [target.geoLocation.latitude, target.geoLocation.longitude]
                            ],
                            {
                                color: lineColor,
                                weight: lineWeight,
                                opacity: lineOpacity
                            }
                        ).addTo(this.layerGroups.motherColonyRelationships);

                        line.bindPopup(`Mother Colony: ${colony.id}<br>Connected to: ${targetType} ${targetId}<br>Health: ${health || 'N/A'}`);
                    }
                });
            });
        },
        drawNurseryOutplantRelationshipLines() {
            this.layerGroups.nurseryOutplantRelationships.clearLayers();

            Object.entries(this.nurseryOutplantRelationships).forEach(([nurseryId, outplantCellIds]) => {
                const nursery = this.nurseries.find(n => n.id === nurseryId);
                if (!nursery || !nursery.geoLocation) return;

                outplantCellIds.forEach(cellId => {
                    const outplantCell = this.outplantCells.find(c => c.id === cellId);
                    if (!outplantCell || !outplantCell.geoLocation) return;

                    const line = L.polyline(
                        [
                            [nursery.geoLocation.latitude, nursery.geoLocation.longitude],
                            [outplantCell.geoLocation.latitude, outplantCell.geoLocation.longitude]
                        ],
                        {
                            color: 'white',
                            weight: 0.5,
                            opacity: 0.3
                        }
                    ).addTo(this.layerGroups.nurseryOutplantRelationships);

                    line.bindPopup(`Nursery: ${nurseryId}<br>Connected to Outplant Cell: ${cellId}`);
                });
            });
        },
        fitMapBounds() {
            const allLocations = [
                ...this.nurseries.map(nursery => nursery.geoLocation),
                ...this.outplantSites.map(site => site.geoLocation),
                ...this.controlSites.map(site => site.geoLocation),
                ...this.motherColonies.map(colony => colony.geoLocation),
                ...this.outplantCells.map(cell => cell.geoLocation)
            ].filter(location => location && location.latitude && location.longitude);

            if (allLocations.length === 0) {
                return;
            }

            const bounds = L.latLngBounds(allLocations.map(loc => [loc.latitude, loc.longitude]));
            this.map.fitBounds(bounds, { padding: [50, 50] });
        },
        createCustomIcon(color) {
            return L.divIcon({
                className: 'custom-div-icon',
                html: `<div style="background-color:${color};width:10px;height:10px;border-radius:50%;"></div>`,
                iconSize: [10, 10],
                iconAnchor: [5, 5]
            });
        },
        motherColonyIcon() {
            return L.divIcon({
                className: 'custom-div-icon',
                html: `<div style="background-color:white;width:5px;height:5px;border-radius:50%;"></div>`,
                iconSize: [5, 5],
                iconAnchor: [2.5, 2.5]
            });
        },
        addLayersControl() {
            const overlayMaps = {
                "Nurseries": this.layerGroups.nurseries,
                "Outplant Sites": this.layerGroups.outplantSites,
                "Control Sites": this.layerGroups.controlSites,
                "Mother Colonies": this.layerGroups.motherColonies,
                "Outplant Cells": this.layerGroups.outplantCells,
                "Mother Colony Relationships": this.layerGroups.motherColonyRelationships,
                "Nursery/Outplant Relationships": this.layerGroups.nurseryOutplantRelationships,
            };

            L.control.layers(null, overlayMaps, { collapsed: false }).addTo(this.map);

            // Add all layers to the map by default, except relationships
            Object.entries(this.layerGroups).forEach(([key, layerGroup]) => {
                if (key !== 'motherColonyRelationships' && key !== 'nurseryOutplantRelationships') {
                    this.map.addLayer(layerGroup);
                }
            });

            // Add event listeners for both relationship layers
            this.map.on('overlayadd', async (e) => {
                if (e.name === 'Mother Colony Relationships' || e.name === 'Nursery/Outplant Relationships') {
                    this.isLoadingRelationships = true;
                    if (!this.fragmentsLoaded) {
                        await this.fetchFragments();
                        this.fragmentsLoaded = true;
                    }
                    if (e.name === 'Mother Colony Relationships') {
                        this.drawMotherColonyRelationshipLines();
                    } else {
                        this.drawNurseryOutplantRelationshipLines();
                    }
                    this.isLoadingRelationships = false;
                }
            });

            // Clear relationships when the layers are removed
            this.map.on('overlayremove', (e) => {
                if (e.name === 'Mother Colony Relationships') {
                    this.layerGroups.motherColonyRelationships.clearLayers();
                } else if (e.name === 'Nursery/Outplant Relationships') {
                    this.layerGroups.nurseryOutplantRelationships.clearLayers();
                }
            });
        },
        destroyMap() {
            if (this.map) {
                this.map.off();
                this.map.remove();
                this.map = null;
            }

            // Clear all layer groups
            Object.values(this.layerGroups).forEach(layerGroup => {
                layerGroup.clearLayers();
            });
        },
        getHealthColor(health) {
            if (health === null || health === undefined) {
                return 'white';
            }
            
            const startColor = [0, 255, 0]; // Green
            const endColor = [255, 0, 0];   // Red
            
            // Normalize health value to 0-1 range
            const normalizedHealth = (health - 1) / 5;
            
            return this.interpolateColor(startColor, endColor, normalizedHealth);
        },
        interpolateColor(color1, color2, factor) {
            const result = color1.slice();
            for (let i = 0; i < 3; i++) {
                result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
            }
            return `rgb(${result.join(',')})`;
        }
    }
}
</script>

<style scoped>
:deep(.leaflet-control-layers) {
    background-color: black !important;
    border: 1px solid #444 !important;
}

:deep(.leaflet-control-layers-list) {
    color: white !important;
}

:deep(.leaflet-control-layers-selector) {
    accent-color: #27bdf4;
}

:deep(.leaflet-control-layers-expanded) {
    padding: 6px 10px 6px 6px;
}

:deep(.leaflet-control-layers-toggle) {
    background-color: black;
    border: 1px solid #444;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1000;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>