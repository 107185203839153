<template>
    <div @click="navigateToDevice"
        class="relative bg-cover bg-center rounded-lg shadow-md text-white p-4 flex flex-col min-w-[200px] min-h-[150px] cursor-pointer hover:shadow-lg transition-shadow duration-300 overflow-hidden"
        :style="{ backgroundImage: `url(${device.zed})` }">
        <div class="absolute inset-0 bg-gradient-to-b from-black to-transparent opacity-0"></div>
        <h3 class="relative z-10 text-xl font-black font-lato mb-2">{{ device.id }}</h3>
        <div class="absolute bottom-2 right-2 flex items-center text-xs">
            <i class="mdi mdi-update mr-1"></i>
            <span>{{ formattedTime }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeviceCard',
    props: ['device'],
    computed: {
        formattedTime() {
            return new Date(this.device.zed_time.toDate()).toLocaleString();
        }
    },
    methods: {
        navigateToDevice() {
            this.$router.push({
                name: 'Device',
                params: {
                    deviceId: this.device.id
                }
            });
        }
    }
}
</script>