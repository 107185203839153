<template>
    <div class="branch-dropdown relative">
        <!-- Dropdown button -->
        <div @click="toggleDropdown" class="dropdown-toggle">
            <span>{{ displayText }}</span>
            <span :class="`mdi ${icon}`"></span>
            <!-- Clear button -->
            <button v-if="clearable && selectedValue" @click.stop="clearSelection" class="clear-button mdi mdi-close"></button>
        </div>

        <!-- Dropdown options -->
        <div v-if="isOpen" class="dropdown-menu absolute bg-black mt-1 border border-gray-700 rounded shadow-lg">
            <div v-if="options.length === 0" class="dropdown-item">No options available</div>
            <div v-else v-for="item in options" :key="item.id" @click="selectItem(item)" class="dropdown-item">
                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Object, null],
            default: null
        },
        options: {
            type: Array,
            required: true
        },
        icon: {
            type: String,
            default: 'mdi-chevron-down'
        },
        placeholder: {
            type: String,
            default: 'Select an option'
        },
        clearable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: false,
            selectedValue: this.value
        };
    },
    computed: {
        displayText() {
            return this.selectedValue ? this.selectedValue.name : this.placeholder;
        }
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectItem(item) {
            this.selectedValue = item;
            this.$emit('input', item);
            this.isOpen = false;
        },
        clearSelection() {
            this.selectedValue = null;
            this.$emit('input', null);
            this.$emit('clear');
        }
    },
    watch: {
        value(newValue) {
            this.selectedValue = newValue;
        }
    }
};
</script>

<style scoped>
.branch-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-toggle {
    padding: 2px 8px;
    background-color: black;
    color: white;
    border-radius: 4px;
    border: 1px solid #444;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #000;
    border: 1px solid #444;
    border-radius: 4px;
    z-index: 3;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    padding: 8px 16px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    text-align: left;
}

.dropdown-item:hover {
    background-color: #555;
}

.clear-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #999;
}
</style>
