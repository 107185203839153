<template>
    <div
        class="relative bg-black rounded-lg shadow-md text-white p-4 flex flex-col min-w-[270px] h-[200px] cursor-pointer hover:shadow-lg transition-shadow duration-300 mb-4 overflow-hidden">
        <div class="absolute inset-0 bg-cover bg-center" :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
        <div class="absolute inset-0 bg-gradient-overlay"></div>
        <h3 class="relative z-10 text-xl font-black font-lato mb-2">{{ app.name }}</h3>
        <div class="mt-auto relative z-10">
            <span class="mdi mdi-launch text-2xl text-white opacity-75 text-sm"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppCard',
    props: ['app'],
    computed: {
        backgroundImage() {
            try {
                return require(`@/assets/images/${this.app.img}`);
            } catch (e) {
                console.warn(`Image not found for app ${this.app.name}. Using default.`);
                return require('@/assets/images/branch_moorea.png');
            }
        }
    },
}
</script>

<style scoped>
.bg-gradient-overlay {
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.8) 25%,
            rgba(0, 0, 0, 0.5) 50%,
            rgba(0, 0, 0, 0) 100%);
}
</style>