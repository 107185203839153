<template>
  <div>
    <h1>Adoptions</h1>
    <table v-if="adoptions.length">
      <thead>
        <tr>
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(adoption, index) in adoptions" :key="index">
          <td v-for="(value, key) in adoption" :key="key">{{ value }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>Loading data...</p>
  </div>
</template>

<script>
export default {
  name: 'Adoptions',
  data() {
    return {
      adoptions: [],
      headers: [],
    }
  },
  methods: {
    async fetchAdoptions() {
      const sheetId = '1IqkaUSjxD7c7l8jZeU476Unufdlv6tp7KwcHkuzIr1M';
      const sheetGid = '726989204'; // This is the gid sheet URL
      const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&gid=${sheetGid}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const csvData = await response.text();
        this.parseCSV(csvData);
      } catch (error) {
        console.error('Error fetching adoptions:', error);
      }
    },
    parseCSV(csvData) {
      const rows = csvData.split('\n').map(row => 
        row.split(',').map(cell => 
          cell.replace(/^"|"$/g, '').replace(/""/g, '"')
        )
      );
      
      if (rows.length > 0) {
        this.headers = rows[0];
        this.adoptions = rows.slice(1).map(row => {
          const adoption = {};
          this.headers.forEach((header, index) => {
            adoption[header] = row[index] || '';
          });
          return adoption;
        });
      }
    }
  },
  mounted() {
    this.fetchAdoptions();
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
th {
  background-color: black;
}
</style>
