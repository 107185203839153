<template>
  <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col space-y-4 mb-4">
    <!-- Header with title and menu -->
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <h3 class="text-lg font-black font-lato mr-1">7-Day Weather Forecast</h3>
        <!-- Menu button -->
        <div class="relative">
          <button @click="toggleMenu" class="text-white hover:text-gray-300 transition-colors duration-300">
            <i class="mdi mdi-dots-vertical text-xl"></i>
          </button>
          <!-- Dropdown menu -->
          <div v-if="showMenu"
              class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
            <div class="py-1" role="menu">
              <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800"
                  @click.prevent="savePDF">
                <i class="mdi mdi-file-pdf-box mr-2"></i>Save as PDF
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Weather forecast timeline -->
    <div class="timeline-container relative" ref="container">
      <svg :width="timelineWidth" :height="svgHeight">
        <!-- SVG Definitions -->
        <defs>
          <!-- Add any gradient definitions here if needed -->
        </defs>

        <!-- Vertical Day Lines -->
        <g>
          <line v-for="(day, index) in days" :key="'grid-' + day"
            :x1="labelWidth + (index * (timelineWidth - labelWidth - padding) / 7)"
            :y1="topPadding"
            :x2="labelWidth + (index * (timelineWidth - labelWidth - padding) / 7)"
            :y2="totalHeight - bottomPadding" stroke="#333333" stroke-width="1" />
        </g>

        <!-- Horizontal Lines Between Rows -->
        <g>
          <line v-for="index in 5" :key="'row-divider-' + index"
            :x1="labelWidth"
            :y1="topPadding + (rowHeight * index)"
            :x2="timelineWidth - padding"
            :y2="topPadding + (rowHeight * index)"
            stroke="#333333"
            stroke-width="1"
            opacity="0.5"
          />
        </g>

        <!-- Row Icons -->
        <g>
          <image v-for="(icon, index) in rowIcons" :key="'icon-' + index"
            :x="10"
            :y="topPadding + (rowHeight * index) + (rowHeight / 2) - 10"
            :href="icon"
            width="20"
            height="20"
          />
        </g>

        <!-- Day Labels -->
        <g>
          <text v-for="(day, index) in days" :key="'day-' + day"
            :x="labelWidth + ((index + 0.5) * (timelineWidth - labelWidth - padding) / 7)"
            :y="totalHeight - 5"
            font-size="12"
            fill="white"
            text-anchor="middle"
          >
            {{ day }}
          </text>
        </g>

        <!-- Weather Data -->
        <g v-for="(row, rowIndex) in weatherData" :key="'row-' + rowIndex">
          <text v-for="(value, dayIndex) in row" :key="'data-' + rowIndex + '-' + dayIndex"
            :x="labelWidth + ((dayIndex + 0.5) * (timelineWidth - labelWidth - padding) / 7)"
            :y="topPadding + (rowHeight * (rowIndex + 0.5))"
            font-size="12"
            fill="white"
            text-anchor="middle"
          >
            {{ value }}
          </text>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';

export default {
  name: 'BranchWeather',
  setup() {
    const showMenu = ref(false);
    const timelineWidth = ref(0);
    const container = ref(null);

    const topPadding = 20;
    const bottomPadding = 30;
    const labelWidth = 40;
    const padding = 20;
    const rowHeight = 40;

    const days = ['Today', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const rowIcons = [
      'path/to/temperature-icon.svg',
      'path/to/wind-icon.svg',
      'path/to/rain-icon.svg',
      'path/to/swell-icon.svg',
      'path/to/tide-icon.svg'
    ];

    // Placeholder weather data
    const weatherData = [
      ['25°C', '24°C', '26°C', '23°C', '22°C', '25°C', '27°C'],
      ['10 km/h', '15 km/h', '12 km/h', '8 km/h', '20 km/h', '18 km/h', '14 km/h'],
      ['0%', '20%', '60%', '30%', '0%', '10%', '0%'],
      ['1.5m', '2m', '1.8m', '1.2m', '1m', '1.3m', '1.7m'],
      ['H 1.2m\nL 0.3m', 'H 1.4m\nL 0.2m', 'H 1.3m\nL 0.4m', 'H 1.1m\nL 0.5m', 'H 1.0m\nL 0.6m', 'H 1.2m\nL 0.3m', 'H 1.5m\nL 0.1m']
    ];

    const totalHeight = computed(() => (weatherData.length * rowHeight) + topPadding + bottomPadding);
    const svgHeight = computed(() => totalHeight.value + 20);

    onMounted(() => {
      updateTimelineWidth();
      window.addEventListener('resize', updateTimelineWidth);
    });

    function updateTimelineWidth() {
      if (container.value) {
        timelineWidth.value = container.value.clientWidth;
      }
    }

    function toggleMenu() {
      showMenu.value = !showMenu.value;
    }

    function savePDF() {
      // Implement PDF saving logic here
      console.log('Saving PDF...');
    }

    return {
      showMenu,
      timelineWidth,
      container,
      topPadding,
      bottomPadding,
      labelWidth,
      padding,
      rowHeight,
      days,
      rowIcons,
      weatherData,
      totalHeight,
      svgHeight,
      toggleMenu,
      savePDF
    };
  }
};
</script>

<style scoped>
.timeline-container {
  position: relative;
  width: 100%;
  overflow: visible;
}

/* Add any additional styles here */
</style>