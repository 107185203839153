<template>
    <div class="outer-container">
        <div class="inner-container flex flex-col md:flex-row bg-black rounded-lg shadow-md text-white relative">
            <div class="w-full md:w-1/2 p-4 flex flex-col overflow-y-auto no-scrollbar">
                <div class="flex justify-between items-center mb-1">
                    <h2 class="text-2xl font-black font-lato">Restoration Site Designer</h2>
                    <span class="bg-white text-black px-2 py-1 rounded-lg font-black font-lato text-sm capitalize">{{ type }}</span>
                </div>
                <p class="text-neutral-500 mb-4">{{ formattedCreatedDate }}</p>
                <form @submit.prevent="saveOutplantSite" class="pb-4">
                    <div v-if="!currentPolygon" class="text-center py-8 bg-neutral-900 rounded">
                        <i class="mdi mdi-map-outline text-4xl mb-2"></i>
                        <p>To get started, create the site on the map</p>
                    </div>

                    <div v-else>
                        <div class="mb-4">
                            <label for="siteName" class="block mb-2">Site Name</label>
                            <input v-model="siteName" id="siteName" type="text" class="w-full bg-neutral-700 rounded p-2" :disabled="isSiteNameDisabled" required>
                        </div>
                        <div class="mb-4">
                            <label for="controlSite" class="block mb-2">
                                {{ type === 'control' ? 'Link Outplant Site' : 'Link Control Site' }}
                            </label>
                            <Dropdown 
                                v-model="selectedControlSite" 
                                :options="controlSites" 
                                :placeholder="type === 'control' ? 'Select an Outplant Site' : 'Select a Control Site'" 
                            />
                        </div>
                        <div class="mb-4">
                            <label for="reefOSDevice" class="block mb-2">Link ReefOS Device</label>
                            <Dropdown 
                                v-model="selectedReefOSDevice" 
                                :options="reefOSDevices" 
                                placeholder="Select a ReefOS Device" 
                            />
                        </div>

                        <!-- Restoration Goals Section (for outplant sites) -->
                        <div v-if="type === 'outplant'" class="mb-4">
                            <h3 class="text-lg font-semibold mb-2">Restoration Goals</h3>
                            <div class="mb-4">
                                <label for="coralCover" class="block">Coral Cover (%)</label>
                                <div class="relative">
                                    <input v-model.number="coralCover" id="coralCover" type="range" min="0" max="100" class="w-full range-slider" @input="updateRestorationGoals">
                                    <div class="flex justify-between text-neutral-400 mt-1">
                                        <span>{{ computedCoralsPerSquareMeter }} corals/m²</span>
                                        <span>{{ coralCover }}%</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-1">
                                    <label for="expectedSurvival" class="block mb-1">Expected Survival (%)</label>
                                    <div class="relative">
                                        <input v-model.number="expectedSurvival" id="expectedSurvival" type="range" min="0" max="100" class="w-full range-slider">
                                        <div class="flex justify-between text-neutral-400 mt-1">
                                            <span></span>
                                            <span>{{ expectedSurvival }}%</span>
                                        </div>
                                    </div>
                                </div>
                            <div class="mb-4">
                                <label for="restorableSubstrate" class="block mb-1">Restorable Substrate (%)</label>
                                <div class="relative">
                                    <input v-model.number="restorableSubstrate" id="restorableSubstrate" type="range" min="0" max="100" class="w-full range-slider">
                                    <div class="flex justify-between text-neutral-400 mt-1">
                                        <span>{{ formattedRestorableArea }} m² / {{ formattedRestorableAreaHectares }}</span>
                                        <span>{{ restorableSubstrate }}%</span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-lg">
                                Total Corals: <strong>{{ formattedTotalCorals }}</strong>
                            </div>
                        </div>

                        <!-- Monitoring Specifications Section -->
                        <div v-if="type === 'outplant'" class="mb-4">
                            <h3 class="text-lg font-semibold mb-2">Monitoring Specifications</h3>
                            <div class="ml-4">
                                <div class="mb-2">
                                    <label class="inline-flex items-center">
                                        <input type="checkbox" v-model="transectSelected" class="form-checkbox text-primary">
                                        <span class="ml-2">Transect Survey</span>
                                    </label>
                                    <div v-if="transectSelected" class="ml-4 mt-2 flex items-center">
                                        <input type="number" v-model.number="transectNumber" min="1" placeholder="Number" class="w-20 bg-neutral-700 rounded p-1 mr-2">
                                        <select v-model="transectFrequency" class="bg-neutral-700 rounded p-1">
                                            <option value="monthly">Monthly</option>
                                            <option value="quarterly">Quarterly</option>
                                            <option value="annually">Annually</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <label class="inline-flex items-center">
                                        <input type="checkbox" v-model="spotSelected" class="form-checkbox text-primary">
                                        <span class="ml-2">Spot Survey</span>
                                    </label>
                                    <div v-if="spotSelected" class="ml-4 mt-2 flex items-center">
                                        <input type="number" v-model.number="spotNumber" min="1" placeholder="Number" class="w-20 bg-neutral-700 rounded p-1 mr-2">
                                        <select v-model="spotFrequency" class="bg-neutral-700 rounded p-1">
                                            <option value="monthly">Monthly</option>
                                            <option value="quarterly">Quarterly</option>
                                            <option value="annually">Annually</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <label class="inline-flex items-center">
                                        <input type="checkbox" v-model="photogrammetrySelected" class="form-checkbox text-primary">
                                        <span class="ml-2">Photogrammetry</span>
                                    </label>
                                    <div v-if="photogrammetrySelected" class="ml-4 mt-2 flex items-center">
                                        <input type="number" v-model.number="photogrammetryCellsPercentage" min="1" max="100" placeholder="% of cells" class="w-20 bg-neutral-700 rounded p-1 mr-2">
                                        <span class="mr-2">% of cells</span>
                                        <select v-model="photogrammetryFrequency" class="bg-neutral-700 rounded p-1">
                                            <option value="monthly">Monthly</option>
                                            <option value="quarterly">Quarterly</option>
                                            <option value="annually">Annually</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <label class="inline-flex items-center">
                                        <input type="checkbox" v-model="waterQualitySelected" class="form-checkbox text-primary">
                                        <span class="ml-2">Water Quality</span>
                                    </label>
                                    <div v-if="waterQualitySelected" class="ml-4 mt-2 flex items-center">
                                        <select v-model="waterQualityFrequency" class="bg-neutral-700 rounded p-1">
                                            <option value="weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                            <option value="quarterly">Quarterly</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="w-full md:w-1/2 mt-4 md:mt-0 flex flex-col flex-grow">
                <div id="map" class="flex-grow" :class="[
                    isMobile ? 'rounded-bl-lg rounded-br-lg' : 'rounded-tr-lg rounded-br-lg'
                ]">
                    <div v-if="polygonArea" style="z-index: 1000;" class="absolute top-4 right-2 bg-white text-black px-2 py-1 rounded-lg font-black font-lato text-sm">
                        {{ formattedPolygonArea }} / {{ formattedPolygonAreaHectares }}
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Add Save and Cancel buttons outside the card -->
        <div class="flex justify-end mt-4">
            <button @click="cancel" type="button" class="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600 transition-colors duration-300">
                Cancel
            </button>
            <button @click="saveOutplantSite" 
                    class="bg-primary text-white px-4 py-2 rounded transition-colors duration-300"
                    :class="{ 'opacity-50 cursor-not-allowed': !isFormValid, 'hover:bg-primary-dark': isFormValid }"
                    :disabled="!isFormValid">
                Save
            </button>
        </div>
    </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { polygon, area } from '@turf/turf';
import '@mdi/font/css/materialdesignicons.css';
import Dropdown from '@/components/utils/Dropdown.vue';
import { db_resto, db_map } from '@/main';
import { collection, getDocs, query, where, addDoc } from 'firebase/firestore';
import { EventBus } from '@/event-bus';

export default {
    name: 'AddOutplantSite',
    components: {
        Dropdown
    },
    props: {
        branchId: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'outplant',
            validator: (value) => ['outplant', 'control'].includes(value)
        },
        loc: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            siteName: '',
            createdDate: new Date(),
            map: null,
            drawControl: null,
            featureGroup: null,
            perimeter: [],
            transectSelected: false,
            transectNumber: null,
            transectFrequency: 'monthly',
            spotSelected: false,
            spotNumber: null,
            spotFrequency: 'monthly',
            photogrammetrySelected: false,
            photogrammetryCellsPercentage: 10,
            photogrammetryFrequency: 'quarterly',
            waterQualitySelected: false,
            waterQualityFrequency: 'monthly',
            currentPolygon: null,
            polygonArea: 0,
            coralCover: 50,
            restorableSubstrate: 50,
            expectedSurvival: 75,
            controlSites: [],
            selectedControlSite: null,
            reefOSDevices: [],
            selectedReefOSDevice: null,
            deviceMarker: null,
        };
    },
    computed: {
        isFormValid() {
            return this.siteName && 
                   this.currentPolygon && 
                   this.perimeter.length > 0 && 
                   (!this.transectSelected || (this.transectNumber && this.transectFrequency)) &&
                   (!this.spotSelected || (this.spotNumber && this.spotFrequency)) &&
                   (!this.photogrammetrySelected || (this.photogrammetryCellsPercentage >= 1 && this.photogrammetryCellsPercentage <= 100 && this.photogrammetryFrequency)) &&
                   (!this.waterQualitySelected || this.waterQualityFrequency);
        },
        formattedCreatedDate() {
            const date = new Date();
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        },
        isMobile() {
            return window.innerWidth < 768;
        },
        formattedPolygonArea() {
            return this.polygonArea > 0 
                ? `${Number(this.polygonArea.toFixed(0)).toLocaleString()} m²` 
                : '0 m²';
        },
        formattedPolygonAreaHectares() {
            const hectares = this.polygonArea / 10000;
            return hectares.toFixed(1) + ' ha';
        },
        formattedRestorableArea() {
            const restorableArea = (this.polygonArea * this.restorableSubstrate) / 100;
            return Number(restorableArea.toFixed(0)).toLocaleString();
        },
        formattedRestorableAreaHectares() {
            const hectares = (this.polygonArea * this.restorableSubstrate / 100) / 10000;
            return hectares.toFixed(1) + ' ha';
        },
        computedCoralsPerSquareMeter() {
            return Math.ceil((this.coralCover / 50) * 5);
        },
        formattedTotalCorals() {
            const restorableArea = (this.polygonArea * this.restorableSubstrate / 100);
            const survivalFactor = 1 / (this.expectedSurvival / 100);
            const totalCorals = restorableArea * this.computedCoralsPerSquareMeter * survivalFactor;
            return Number(totalCorals.toFixed(0)).toLocaleString();
        },
        isSiteNameDisabled() {
            return this.type === 'control' && this.selectedControlSite !== null;
        }
    },
    mounted() {
        this.initMap();
        this.fetchControlSites();
        this.fetchReefOSDevices();
    },
    watch: {
        selectedControlSite(newVal) {
            if (this.type === 'control' && newVal) {
                this.siteName = `${newVal.name} Control`;
            }
        },
        currentPolygon() {
            this.resizeMap();
        },
        selectedReefOSDevice(newDevice) {
            this.updateDeviceMarker(newDevice);
        },
    },
    methods: {
        initMap() {
            this.map = L.map('map', {
                center: this.loc,
                zoom: 13,
                attributionControl: false,
                zoomControl: false,
            });

            const mapboxAccessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
            L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + mapboxAccessToken, {
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.map);

            this.featureGroup = new L.FeatureGroup().addTo(this.map);

            this.drawControl = new L.Control.Draw({
                draw: {
                    polygon: {
                        shapeOptions: {
                            color: '#ffa500',
                            weight: 3,
                            opacity: 0.7,
                            fillColor: '#ffa500',
                            fillOpacity: 0.2
                        },
                        allowIntersection: false,
                    },
                    polyline: false,
                    rectangle: false,
                    circle: false,
                    circlemarker: false,
                    marker: false
                },
                edit: {
                    featureGroup: this.featureGroup,
                    remove: true
                }
            });

            this.map.addControl(this.drawControl);

            this.map.on(L.Draw.Event.CREATED, this.onDrawCreated);
            this.map.on(L.Draw.Event.EDITED, this.onDrawEdited);
            this.map.on(L.Draw.Event.DELETED, this.onDrawDeleted);
            this.map.on(L.Draw.Event.DRAWSTART, this.onDrawStart);
            this.map.on('draw:drawvertex', this.updatePolygonArea);
            this.map.on('draw:editvertex', this.updatePolygonArea);

            this.addEditHandleStyle();
        },
        onDrawStart(e) {
            // Remove existing polygon when starting to draw a new one
            if (this.currentPolygon) {
                this.featureGroup.removeLayer(this.currentPolygon);
                this.currentPolygon = null;
                this.perimeter = [];
            }
        },
        onDrawCreated(e) {
            const layer = e.layer;
            
            // Remove existing polygon if there is one
            if (this.currentPolygon) {
                this.featureGroup.removeLayer(this.currentPolygon);
            }

            this.currentPolygon = layer;
            this.featureGroup.addLayer(layer);
            this.updatePerimeter();
            
            // Apply the same style to the created polygon
            layer.setStyle({
                color: '#ffa500',
                weight: 3,
                opacity: 0.7,
                fillColor: '#ffa500',
                fillOpacity: 0.2
            });

            // Disable the polygon draw control after creating a polygon
            this.drawControl.setDrawingOptions({
                polygon: false
            });
            this.map.removeControl(this.drawControl);
            this.map.addControl(this.drawControl);
            this.updatePolygonArea();
            this.resizeMap();
        },
        onDrawEdited(e) {
            this.updatePerimeter();
            this.updatePolygonArea();
        },
        onDrawDeleted(e) {
            this.currentPolygon = null;
            this.perimeter = [];
            this.polygonArea = 0;

            // Re-enable the polygon draw control after deleting the polygon
            this.drawControl.setDrawingOptions({
                polygon: {
                    shapeOptions: {
                        color: '#ffa500',
                        weight: 3,
                        opacity: 0.7,
                        fillColor: '#ffa500',
                        fillOpacity: 0.2
                    },
                    allowIntersection: false,
                }
            });
            this.map.removeControl(this.drawControl);
            this.map.addControl(this.drawControl);
        },
        updatePerimeter() {
            this.perimeter = [];
            if (this.currentPolygon) {
                const latlngs = this.currentPolygon.getLatLngs()[0];
                this.perimeter = latlngs.map(latLng => ({
                    latitude: latLng.lat,
                    longitude: latLng.lng
                }));
            }
        },
        calculatePolygonCenter() {
            const bounds = this.featureGroup.getBounds();
            const center = bounds.getCenter();
            return {
                latitude: center.lat,
                longitude: center.lng
            };
        },
        getMonitoringSpecifications() {
            const specs = {
                surveys: {}
            };
            if (this.transectSelected) {
                specs.surveys.transect = {
                    number: this.transectNumber,
                    frequency: this.transectFrequency
                };
            }
            if (this.spotSelected) {
                specs.surveys.spot = {
                    number: this.spotNumber,
                    frequency: this.spotFrequency
                };
            }
            if (this.photogrammetrySelected) {
                specs.surveys.photogrammetry = {
                    cellsPercentage: this.photogrammetryCellsPercentage,
                    frequency: this.photogrammetryFrequency
                };
            }
            if (this.waterQualitySelected) {
                specs.surveys.waterQuality = {
                    frequency: this.waterQualityFrequency
                };
            }
            return specs;
        },
        updatePolygonArea() {
            if (this.currentPolygon) {
                const latlngs = this.currentPolygon.getLatLngs()[0];
                const coordinates = latlngs.map(latlng => [latlng.lng, latlng.lat]);

                // Ensure the polygon is closed
                if (coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
                    coordinates[0][1] !== coordinates[coordinates.length - 1][1]) {
                    coordinates.push(coordinates[0]);
                }

                const poly = polygon([coordinates]);
                this.polygonArea = area(poly);
            } else {
                this.polygonArea = 0;
            }
        },
        async fetchControlSites() {
            try {
                const collectionName = this.type === 'control' ? 'OutplantSites' : 'ControlSites';
                const controlSitesRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/${collectionName}`);
                const querySnapshot = await getDocs(controlSitesRef);
                this.controlSites = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().name
                }));
            } catch (error) {
                console.error('Error fetching sites:', error);
                this.controlSites = []; // Ensure it's an empty array if there's an error
            }
        },
        async fetchReefOSDevices() {
            try {
                const camerasRef = collection(db_map, 'Cameras');
                const q = query(camerasRef);
                const querySnapshot = await getDocs(q);
                this.reefOSDevices = querySnapshot.docs
                    .filter(doc => !doc.id.startsWith('debug'))
                    .map(doc => ({
                        id: doc.id,
                        name: doc.data().name || doc.id,
                        location: doc.data().location // Make sure to include the location data
                    }));
            } catch (error) {
                console.error('Error fetching ReefOS devices:', error);
                this.reefOSDevices = [];
            }
        },
        async saveOutplantSite() {
            if (this.isFormValid) {
                const siteData = {
                    name: this.siteName,
                    created: this.createdDate,
                    type: this.type,
                    perimeter: this.perimeter,
                    geoLocation: this.calculatePolygonCenter(),
                    reefos_id: this.selectedReefOSDevice ? this.selectedReefOSDevice.id : null,
                };

                if (this.type === 'outplant') {
                    siteData.restorationGoals = {
                        coralCover: this.coralCover,
                        coralsPerSquareMeter: this.computedCoralsPerSquareMeter,
                        restorableSubstrate: this.restorableSubstrate,
                        restorableArea: parseFloat(this.formattedRestorableArea.replace(/,/g, '')),
                        expectedSurvival: this.expectedSurvival,
                    };
                    siteData.monitoringSettings =this.getMonitoringSpecifications(),
                    siteData.controlSiteID = this.selectedControlSite ? this.selectedControlSite.id : null;
                } else {
                    siteData.outplantSiteID = this.selectedControlSite ? this.selectedControlSite.id : null;
                }

                try {
                    const collectionName = this.type === 'outplant' ? 'OutplantSites' : 'ControlSites';
                    const docRef = await addDoc(collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/${collectionName}`), siteData);
                    console.log("Document written with ID: ", docRef.id);
                    EventBus.$emit('show-toast', { message: 'New site created!', type: 'success', loading: false });
                    // TODO: Update Data Explorer API
                    this.$router.go(-1);
                } catch (error) {
                    console.error("Error adding document: ", error);
                    // You might want to show an error message to the user here
                }
            }
        },
        cancel() {
            this.$router.go(-1);
        },
        addEditHandleStyle() {
            const editHandleStyle = `
                .leaflet-editing-icon {
                    border-radius: 50%;
                    background-color: white;
                    border: 2px solid #ffa500;
                    width: 10px !important;
                    height: 10px !important;
                    margin-left: -5px !important;
                    margin-top: -5px !important;
                }
            `;
            const styleElement = document.createElement('style');
            styleElement.textContent = editHandleStyle;
            document.head.appendChild(styleElement);
        },
        resizeMap() {
            if (this.map) {
                this.$nextTick(() => {
                    this.map.invalidateSize();
                });
            }
        },
        createPulsingIcon() {
            return L.divIcon({
                className: 'pulsing-icon',
                iconSize: [5, 5],
                iconAnchor: [2.5, 2.5]
            });
        },
        updateDeviceMarker(device) {
            if (this.deviceMarker) {
                this.map.removeLayer(this.deviceMarker);
            }

            if (device && device.location) {
                this.deviceMarker = L.marker([device.location._lat, device.location._long], {
                    icon: this.createPulsingIcon(),
                    zIndexOffset: 1000
                }).addTo(this.map);

                this.deviceMarker.bindPopup(`<h3>${device.name || 'ReefOS Device'}</h3>`);
            }
        },
        updateRestorationGoals() {
            // This method can be used to update other related values if needed
        },
    }
};
</script>

<style scoped>
#map {
    width: 100%;
    height: 100%;
    min-height: 400px;
}

.outer-container {
    max-height: calc(100vh - 100px); 
    display: flex;
    flex-direction: column;
}

.inner-container {
    overflow-y: auto;
    flex-grow: 1;
}
.leaflet-editing-icon {
    border-radius: 50%;
    background-color: white;
    border: 2px solid #ffa500;
    width: 10px !important;
    height: 10px !important;
    margin-left: -5px !important;
    margin-top: -5px !important;
}

.range-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px; /* Slider Track height */
    background: #27BDF4; /* Background Color */
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.range-slider:hover {
    opacity: 1;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px; /* Thumb width */
    height: 15px; /* Thumb height */
    border-radius: 50%;
    background: #27BDF4; /* Thumb Color */
    cursor: pointer;
}

.range-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #27BDF4;
    cursor: pointer;
}
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}   

.pulsing-icon {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 0, 0, 1);
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 0 rgba(255, 0, 0, 1);
    animation: pulse 5s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }
    50% {
        box-shadow: 0 0 15px 15px rgba(255, 0, 0, 0);
        opacity: 0.7;
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }
}
</style>