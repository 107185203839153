<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col min-w-[200px] min-h-[300px] cursor-pointer hover:shadow-lg transition-shadow duration-300"
        @click="navigateToNursery">

        <div class="flex justify-between items-start mb-4">
            <h3 class="text-xl font-black font-lato">{{ nursery.name }}</h3>
            <div v-if="nursery.data" class="badge-container">
                <div :class="['badge text-xs font-black font-lato px-2 py-1 rounded-full', badgeClasses]">
                    {{ formatPercentage(averagePercentage) }}
                </div>
                <span class="tooltip">
                    NurseryScore™
                    <br>
                    (Survival % + Health % + Bleaching %) / 3
                </span>
            </div>
        </div>

        <div v-if="nursery.data">
            <div class="flex mb-4">
                <div class="flex-shrink-0 ml-2">
                    <ThreeCircleGauge :survival="survivalPercentage" :health="healthPercentage" :bleach="bleachPercentage"
                        :size="96" />
                </div>
                <div class="flex flex-col justify-center ml-4 text-sm text-neutral-400 ">
                    <!-- Survival -->
                    <div class="flex items-center mb-2">
                        <div class="w-4 h-4 rounded-full flex items-center justify-center mr-2"
                            style="background-color: #5EBAF0;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none"
                                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                :style="{ transform: `rotate(${aliveRotation})` }">
                                <path d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                            </svg>
                        </div>
                        <div>Survival: <span class="font-black font-lato">{{ formatPercentage(survivalPercentage) }}</span></div>
                    </div>
                    <!-- Health -->
                    <div class="flex items-center mb-2">
                        <div class="w-4 h-4 rounded-full flex items-center justify-center mr-2"
                            style="background-color: #B9F6FC;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none"
                                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                :style="{ transform: `rotate(${healthRotation})` }">
                                <path d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                            </svg>
                        </div>
                        <div>
                            Health: 
                            <div class="badge-container inline-block">
                                <span class="font-black font-lato">{{ formatPercentage(healthPercentage) }}</span>
                                <span class="tooltip">{{ formatDecimal(nursery.data.health) }} (100% is healthy)</span>
                            </div>
                        </div>
                    </div>
                    <!-- Bleaching -->
                    <div class="flex items-center">
                        <div class="w-4 h-4 rounded-full flex items-center justify-center mr-2"
                            style="background-color: #F9F5FE;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none"
                                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                :style="{ transform: `rotate(${bleachRotation})` }">
                                <path d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                            </svg>
                        </div>
                        <div>
                            Bleaching: 
                            <div class="badge-container inline-block">
                                <span class="font-black font-lato">{{ formatPercentage(bleachPercentage) }}</span>
                                <span class="tooltip">{{ formatDecimal(nursery.data.bleach) }}  (100% is no bleaching)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Two boxes  -->
            <div class="flex justify-between mt-2">
                <div class="w-[48%] bg-neutral-800 rounded-lg px-2 py-1">
                    <p class="text-lg font-black font-lato text-white">{{ formatNumber(nursery.data.corals) }}</p>
                    <p class="text-xs text-neutral-400">Corals</p>
                </div>
                <div class="w-[48%] bg-neutral-800 rounded-lg px-2 py-1">
                    <p class="text-lg font-black font-lato text-white">{{ formatNumber(nursery.data.species) }} / {{ formatNumber(nursery.data.genera) }}</p>
                    <p class="text-xs text-neutral-400">Species / Genera</p>
                </div>
            </div>

            <!-- Progress bar -->
            <div class="capitalize text-sm mt-2 mb-1">{{ stage }}</div>
            <div class="w-full bg-neutral-700 rounded h-2 overflow-hidden">
                <div class="h-full rounded" :style="{ width: `${stageProgressPercentage}%`, backgroundColor: stageColor }">
                </div>
            </div>
            <div class="flex justify-between items-center mt-1 text-xs">
                <p v-if="stage === 'seeding'">{{ formatNumber(nursery.data.corals) }} / {{ formatNumber(stageGoal) }}</p>
                <p v-if="stage === 'growing'">{{ formatDuration(nursery.data.age) }} / {{ formatStageGoal }}</p>
                <div class="flex items-center">
                    <span>{{ stageProgressPercentage.toFixed(0) }}%</span>
                </div>
            </div>
        </div>

        <div v-else class="mt-auto border border-neutral-700 p-1 rounded-lg">
            <p class="text-center text-neutral-400">
                <span class="mdi mdi-alert-circle-outline mr-1"></span>Data Explorer unreachable
            </p>
        </div>
    </div>
</template>

<script>
import ThreeCircleGauge from './utils/ThreeCircleGauge.vue';

export default {
    name: 'NurseryCard',
    components: {
        ThreeCircleGauge
    },
    props: {
        nursery: {
            type: Object,
            required: true
        },
        branchId: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            stageColors: {
                seeding: '#BA76FA',
                growing: '#34D9C7',
                planting: '#4971FF'
            }
        }
    },
    computed: {
        stage() {
            return this.nursery.stage || 'growing';
        },
        stageColor() {
            return this.stageColors[this.stage] || '#A3A3A3';
        },
        stageName() {
            return this.stage.charAt(0).toUpperCase() + this.stage.slice(1);
        },
        stageGoal() {
            if (this.stage === 'seeding') {
                return this.nursery.goals?.seeding || 5400;
            } else if (this.stage === 'growing') {
                return (this.nursery.goals?.growing || 2) * 365;
            } else if (this.stage === 'planting') {
                return (this.nursery.goals?.planting || 0) * (this.nursery.data?.corals || 0);
            } else {
                return 0;
            }
        },
        stageProgressPercentage() {
            if (!this.nursery.data) return 0;
            if (this.stage === 'seeding') {
                return (this.nursery.data.corals || 0) / this.stageGoal * 100;
            } else if (this.stage === 'growing') {
                return (this.nursery.data.age || 0) / this.stageGoal * 100;
            } else if (this.stage === 'planting') {
                return 0.75 * 100;
            } else {
                return 0;
            }
        },
        survivalPercentage() {
            return this.nursery.data?.alive ?? 0;
        },
        healthPercentage() {
            return this.nursery.data ? this.convertScale(this.nursery.data.health ?? 0) : 0;
        },
        bleachPercentage() {
            return this.nursery.data ? this.convertScale(this.nursery.data.bleach ?? 0) : 0;
        },
        averagePercentage() {
            if (!this.nursery.data) return 0;
            const values = [this.survivalPercentage, this.healthPercentage, this.bleachPercentage];
            const validValues = values.filter(v => v !== null);
            return validValues.length > 0 ? validValues.reduce((a, b) => a + b) / validValues.length : 0;
        },
        badgeClasses() {
            const percentage = this.averagePercentage * 100;
            if (percentage === 0) {
                return 'text-gray-800 bg-gray-300';
            } else if (percentage > 85) {
                return 'text-green-800 bg-green-200';
            } else if (percentage > 72) {
                return 'text-yellow-800 bg-yellow-200';
            } else if (percentage > 50) {
                return 'text-orange-800 bg-orange-200';
            } else {
                return 'text-red-800 bg-red-200';
            }
        },
        aliveTrend() {
            return this.nursery.data?.alive_trend?.[0]?.trend || 'same';
        },
        healthTrend() {
            return this.nursery.data?.health_trend?.[0]?.trend || 'same';
        },
        bleachTrend() {
            return this.nursery.data?.bleach_trend?.[0]?.trend || 'same';
        },
        aliveRotation() {
            switch (this.aliveTrend) {
                case 'down':
                    return '225deg'; 
                case 'up':
                    return '135deg'; 
                default:
                    return '180deg'; 
            }
        },
        healthRotation() {
            switch (this.healthTrend) {
                case 'up':
                    return '225deg'; 
                case 'down':
                    return '135deg'; 
                default:
                    return '180deg'; 
            }
        },
        bleachRotation() {
            switch (this.bleachTrend) {
                case 'up':
                    return '225deg'; 
                case 'down':
                    return '135deg'; 
                default:
                    return '180deg'; 
            }
        },
        formatStageGoal() {
            if (this.stage === 'growing') {
                const years = Math.floor(this.stageGoal / 365);
                return `${years} year${years > 1 ? 's' : ''}`;
            }
            return this.formatDuration(this.stageGoal);
        }
    },
    methods: {
        formatNumber(value) {
            return value != null ? value.toLocaleString() : '-';
        },
        formatDecimal(value) {
            return value != null ? value.toFixed(2) : '-';
        },
        formatPercentage(value) {
            return value != null ? `${(value * 100).toFixed(0)}%` : '-';
        },
        convertScale(value) {
            return Math.max(0, Math.min(1, (6 - value) / 5));
        },
        navigateToNursery() {
            this.$router.push({
                name: 'Nursery',
                params: {
                    branchId: this.branchId,
                    nurseryId: this.nursery.id,
                }
            });
        },
        formatDuration(days) {
            if (days == null) return '-';
            const years = Math.floor(days / 365);
            const months = Math.floor((days % 365) / 30);
            const remainingDays = Math.floor(days % 30);

            let parts = [];
            if (years > 0) {
                parts.push(`${years} year${years > 1 ? 's' : ''}`);
            }
            if (months > 0) {
                parts.push(`${months} month${months > 1 ? 's' : ''}`);
            }
            if (remainingDays > 0) {
                parts.push(`${remainingDays} day${remainingDays > 1 ? 's' : ''}`);
            }

            if (parts.length === 0) {
                return '0 days';
            } else if (parts.length === 1) {
                return parts[0];
            } else {
                return `${parts.slice(0, -1).join(', ')} and ${parts[parts.length - 1]}`;
            }
        }
    }
}
</script>

<style scoped>
.badge-container {
    position: relative;
    display: inline-block;
}

.badge {
    position: relative;
    z-index: 1;
}

.tooltip {
    position: absolute;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(57, 65, 80, 1.0);
    color: white;
    text-align: center;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.75rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s;
    z-index: 10;
    white-space: nowrap;
}

.badge-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
</style>