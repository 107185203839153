<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-[350px]">
        <div class="flex items-center mb-2 justify-between">
            <div class="flex items-center space-x-2">
                <i :class="['mdi', 'text-2xl', isVideoSelected ? 'mdi-video' : 'mdi-image']"></i>
                <select v-model="selectedImage" @change="updateImage" class="bg-neutral-700 text-white py-2 px-4 rounded text-xs">
                    <option value="zed">Zed</option>
                    <option value="zed_depth">Depth</option>
                    <option value="image">Image</option>
                    <option value="axis">Axis</option>
                    <option value="timelapse">Timelapse</option>
                    <option value="livestream">Livestream</option>
                </select>
            </div>
            <select v-if="!isVideoSelected" v-model="selectedTimestamp" class="bg-neutral-700 text-white py-2 px-4 rounded text-xs ml-auto">
                <option :value="selectedImage">{{ formattedTimestamp }}</option>
            </select>
        </div>
        <div class="flex-grow relative overflow-hidden">
            <img 
                v-if="selectedImage !== 'timelapse' && selectedImage !== 'livestream'"
                :src="currentImageUrl" 
                alt="Device Image" 
                class="w-full h-full object-contain"
            >
            <video
                v-else-if="selectedImage === 'timelapse'"
                :src="currentImageUrl"
                class="w-full h-full object-contain"
                controls
                autoplay
                loop
            ></video>
            <iframe
                v-else
                :src="currentImageUrl"
                class="w-full h-full"
                frameborder="0"
                allowfullscreen
            ></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeviceImages',
    props: {
        device: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedImage: 'axis',
            selectedTimestamp: 'axis'
        };
    },
    computed: {
        currentImageUrl() {
            switch (this.selectedImage) {
                case 'zed': return this.device.zed;
                case 'zed_depth': return this.device.zed_depth;
                case 'image': return this.device.images;
                case 'axis': return this.device.axis;
                case 'timelapse': return this.device.zed_timelapse?.url;
                case 'livestream': return `https://camstreamer.com/embed/${this.device.camstreamer_id}`;
                default: return '';
            }
        },
        formattedTimestamp() {
            if (this.selectedImage === 'timelapse') {
                const start = this.formatDate(this.device.zed_timelapse?.start_time);
                const end = this.formatDate(this.device.zed_timelapse?.end_time);
                return `${start} - ${end}`;
            } else if (this.selectedImage === 'livestream') {
                return 'Live';
            } else {
                const timestamp = this.device[`${this.selectedImage}_time`];
                return this.formatDate(timestamp);
            }
        },
        isVideoSelected() {
            return this.selectedImage === 'timelapse' || this.selectedImage === 'livestream';
        }
    },
    watch: {
        selectedImage(newValue) {
            this.selectedTimestamp = newValue;
        }
    },
    methods: {
        updateImage() {
            // This method is called when the dropdown selection changes
            // You can add any additional logic here if needed
        },
        formatDate(timestamp) {
            if (!timestamp) return 'N/A';
            const date = timestamp.toDate();
            return date.toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
        }
    }
}
</script>
