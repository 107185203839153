<template>
    <div
        class="bg-neutral-800 rounded-lg shadow-md text-white flex flex-col cursor-pointer hover:shadow-lg transition-shadow duration-300 min-h-[400px] mt-4 relative">
        <div :class="['flex', 'flex-grow', isMobile ? 'flex-col h-[600px]' : 'flex-row']">
            <div v-if="overview" :class="[
                isMobile ? 'w-full' : 'w-1/2',
                'flex',
                'flex-col',
                'justify-between',
                'p-4',
            ]">
                <div class="grid grid-cols-2 gap-4">
                    <div class="bg-black rounded-lg p-2">
                        <p class="text-xl font-black font-lato">{{ overview.cells }}</p>
                        <p class="text-sm text-neutral-400">Cells</p>
                    </div>
                    <div class="bg-black rounded-lg p-2">
                        <p class="text-xl font-black font-lato">{{ overview.corals_per_cell.toFixed(0) }}</p>
                        <p class="text-sm text-neutral-400">Coral/Cell</p>
                    </div>
                    <div class="bg-black rounded-lg p-2">
                        <p class="text-xl font-black font-lato">{{ overview.fragments.toLocaleString() }}</p>
                        <p class="text-sm text-neutral-400">Corals</p>
                    </div>
                    <div class="bg-black rounded-lg p-2">
                        <p class="text-xl font-black font-lato">{{ overview.species }}</p>
                        <p class="text-sm text-neutral-400">Species</p>
                    </div>
                    <div class="bg-black rounded-lg p-2">
                        <p class="text-xl font-black font-lato">{{ overview.species_per_cell.toFixed(0) }}</p>
                        <p class="text-sm text-neutral-400">Species/Cell</p>
                    </div>
                    <div class="bg-black rounded-lg p-2">
                        <p class="text-xl font-black font-lato">{{ formattedArea }}</p>
                        <p class="text-sm text-neutral-400">Restored Area</p>
                    </div>
                </div>
                <div v-if="site.restorationGoals" class="mt-4 bg-black rounded-lg p-4">
                    <p class="text-sm mb-1">Outplanting Goal: {{ overview.fragments.toLocaleString() }} / {{ site.restorationGoals.totalCorals.toLocaleString() }}</p>
                    <div class="w-full bg-neutral-600 rounded-full h-2.5 relative">
                        <div class="bg-primary h-2.5 rounded-full"
                            :style="{ width: `${(overview.fragments / site.restorationGoals.totalCorals) * 100}%` }"></div>
                        <span class="absolute top-0 right-0 -mt-6 text-xs">
                            {{ ((overview.fragments / site.restorationGoals.totalCorals) * 100).toFixed(1) }}%
                        </span>
                    </div>
                </div>
            </div>
            <div :class="['z-10', isMobile ? 'w-full h-full mt-4 relative' : 'w-1/2 pl-4', 'relative']">
                <div :class="[
                    isMobile ? 'rounded-bl-lg rounded-br-lg' : 'rounded-tr-lg rounded-br-lg',
                ]" id="map"></div>
            </div>
        </div>
    </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

export default {
    name: 'OutplantOverview',
    props: {
        site: {
            type: Object,
            required: true,
        },
        outplantCells: {
            type: Array,
            default: () => [],
        },
        overview: {
            type: Object,
            default: () => ({}),
        },
        devices: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            map: null,
            area: 0,
            polygonLayer: null,
            deviceMarkers: [],
        };
    },
    computed: {
        formattedArea() {
            if (this.area > 100000) {
                return Number((this.area * 1e-6 ).toFixed(1)).toLocaleString() + ' km²';
            }
            return Number(this.area.toFixed(0)).toLocaleString() + ' m²';
        },
        isMobile() {
            return window.innerWidth < 768;
        }
    },
    mounted() {
        this.initMap();
    },
    watch: {
        site() {
            this.initMap();
        },
        outplantCells() {
            this.addOutplantCells();
        },
        devices() {
            this.plotDevices();
        },
    },
    methods: {
        initMap() {
            if (this.map) {
                this.map.remove();
            }

            this.map = L.map('map', {
                center: [this.site.perimeter[0].latitude, this.site.perimeter[0].longitude],
                attributionControl: false,
                zoomControl: false,
            });

            const mapboxAccessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
            L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + mapboxAccessToken, {
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.map);

            this.addPolygon();
            this.fitMapBounds();
            if (this.outplantCells.length > 0) this.addOutplantCells();
            if (this.devices.length > 0) this.plotDevices();
        },
        addPolygon() {
            const coordinates = this.site.perimeter.map(point => [point.latitude, point.longitude]);
            this.polygonLayer = L.polygon(coordinates, {
                color: '#ffa500',
                weight: 3,
                opacity: 0.7,
                fillColor: '#ffa500',
                fillOpacity: 0.2
            }).addTo(this.map);
            this.updateArea();
        },
        addOutplantCells() {
            this.outplantCells.forEach(cell => {
                const marker = L.circleMarker([cell.geoLocation.latitude, cell.geoLocation.longitude], {
                    radius: 2,
                    fillColor: '#ffa500',
                    color: '#000',
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0.8
                }).addTo(this.map);

                marker.bindPopup(`<h3>${cell.name}</h3>`);
                marker.bringToFront();
            });
        },
        fitMapBounds() {
            const bounds = L.latLngBounds(this.site.perimeter.map(point => [point.latitude, point.longitude]));
            this.map.fitBounds(bounds, { padding: [100, 100] });
        },
        createPulsingIcon() {
            return L.divIcon({
                className: 'pulsing-icon',
                iconSize: [5, 5], 
                iconAnchor: [2.5, 2.5]  
            });
        },
        plotDevices() {
            this.deviceMarkers.forEach(marker => marker.remove());
            this.deviceMarkers = [];

            if (this.devices.length > 0) {
                this.devices.forEach(device => {
                    const marker = L.marker([device.location._lat, device.location._long], {
                        icon: this.createPulsingIcon(),
                        zIndexOffset: 1000
                    }).addTo(this.map);

                    marker.bindPopup(`<h3>${device.name || 'Device'}</h3>`);
                    this.deviceMarkers.push(marker);
                });
            }
        },
        formatPercentage(value) {
            return value.toFixed(0) + '%';
        },
        updateArea() {
            if (this.polygonLayer) {
                const latlngs = this.polygonLayer.getLatLngs()[0];
                this.area = L.GeometryUtil.geodesicArea(latlngs);
            }
        }
    },
};
</script>

<style>
#map {
    width: 100%;
    height: 100%;
}

.pulsing-icon {
    width: 10px;
    /* Small red circle */
    height: 10px;
    background-color: rgba(255, 0, 0, 1);
    /* Solid red */
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 0 rgba(255, 0, 0, 1);
    animation: pulse 5s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }

    50% {
        box-shadow: 0 0 15px 15px rgba(255, 0, 0, 0);
        /* Creates the pulsing glow */
        opacity: 0.7;
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }
}
</style>