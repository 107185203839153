<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 w-full">
        <h1 class="text-left text-xl font-black font-lato mb-4">MONITORING HISTORY</h1>
        <div v-if="Object.keys(monitoring).length > 0" class="overflow-x-auto -webkit-overflow-scrolling-touch">
            <div class="grid-container">
                <div v-for="(entry, date) in monitoring" :key="date" class="grid-item text-left text-sm">
                    <div :class="['w-full h-1 mb-2.5 rounded', entry.type === 'full' ? 'bg-[#27bdf4]' : 'bg-white']">
                    </div>
                    <div class="flex flex-col text-left">
                        <div class="text-xs mb-0.5">{{ formatDate(date) }}</div>
                        <div class="text-xs text-neutral-400">{{ entry.type }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="text-left text-neutral-400">
                <span class="mdi mdi-alert-circle-outline mr-2"></span>
                No monitoring data
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NurseryMonitoring',
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    computed: {
        monitoring() {
            const historyTypes = this.data?.nursery?.history?.type;
            if (!historyTypes) {
                return {};
            }

            const combinedMonitoring = {};
            for (const date in historyTypes) {
                if (historyTypes.hasOwnProperty(date)) {
                    combinedMonitoring[date] = {
                        name: date,
                        type: historyTypes[date]
                    };
                }
            }

            return combinedMonitoring;
        }
    },
    methods: {
        formatDate(date) {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options);
        }
    }
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.overflow-x-auto::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-x-auto {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1.25rem;
    /* This replaces the previous mr-5 */
    width: 100%;
}

.grid-item {
    min-width: 0;
    /* This allows grid items to shrink below their minimum content size */
}
</style>