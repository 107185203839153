import { render, staticRenderFns } from "./CamCard.vue?vue&type=template&id=a8e3f504&scoped=true"
import script from "./CamCard.vue?vue&type=script&lang=js"
export * from "./CamCard.vue?vue&type=script&lang=js"
import style0 from "./CamCard.vue?vue&type=style&index=0&id=a8e3f504&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8e3f504",
  null
  
)

export default component.exports