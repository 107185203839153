<template>
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-[200px] max-w-[6000px]">
        <h1 class="text-left text-2xl font-black font-lato">DIVERSITY</h1>
        <div class="flex-grow relative flex justify-around items-center h-3/4">
            <canvas ref="chartRef" class="max-w-[50%] max-h-[90%]"></canvas>
            <div class="flex flex-col justify-around items-start text-xs">
                <div v-for="(genus, index) in topGenera" :key="genus.name" class="flex items-center">
                    <span class="w-3 h-3 rounded-full inline-block mr-1.5"
                        :style="{ backgroundColor: colors[index % colors.length] }"></span>
                    {{ genus.name }}
                </div>
                <div v-if="otherFraction > 0" class="flex items-center">
                    <span class="w-3 h-3 rounded-full inline-block mr-1.5"
                        :style="{ backgroundColor: colors[colors.length - 1] }"></span>
                    Other
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip);

export default {
    name: 'GenusCard',
    props: ['global'],
    data() {
        return {
            chart: null,
            colors: ['#27bdf4', '#1e95c2', '#166d8f', '#0e465d', '#06202b', '#333333'],
            otherFraction: 0,
        };
    },
    mounted() {
        this.createChart();
    },
    computed: {
        topGenera() {
            if (!this.global || this.global.length <= 0) return [];
            const genusFracs = this.global['Genus_Fracs'];
            const generaArray = Object.entries(genusFracs).map(([genus, fraction]) => ({ name: genus, fraction }));
            generaArray.sort((a, b) => b.fraction - a.fraction);

            const topGenera = generaArray.slice(0, 5);
            const otherGenera = generaArray.slice(5);
            this.otherFraction = otherGenera.reduce((sum, genus) => sum + genus.fraction, 0);

            return topGenera;
        }
    },
    methods: {
        createChart() {
            if (this.chart) {
                this.chart.destroy();
            }
            const ctx = this.$refs.chartRef.getContext('2d');
            const data = [
                ...this.topGenera.map(genus => genus.fraction),
                this.otherFraction
            ];
            const labels = [
                ...this.topGenera.map(genus => genus.name),
                'Other'
            ];

            this.chart = new ChartJS(ctx, {
                type: 'doughnut',
                data: {
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: this.colors,
                        borderWidth: 0,
                    }]
                },
                options: {
                    responsive: true,
                    cutout: '50%',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.label || '';
                                    const value = context.raw || 0;
                                    const total = context.dataset.data.reduce((a, b) => a + b, 0);
                                    const percentage = ((value / total) * 100).toFixed(1);
                                    return `${label}: ${percentage}%`;
                                }
                            }
                        }
                    }
                }
            });
        }
    }
};
</script>
