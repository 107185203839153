<template>
  <div class="relative">
    <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col mb-4">
      <h2 class="text-2xl font-black font-lato mb-4">Nursery Designer</h2>
      <div class="bg-neutral-800 rounded-lg p-4">
        <h3 class="text-xl font-black font-lato mb-2">Specifications</h3>
        <form @submit.prevent="generateNurseryPlan" class="space-y-4">
          <div class="grid grid-cols-2 gap-4 mb-4">
            <div class="flex flex-col">
              <label for="nurseryType" class="text-sm text-neutral-400 mb-1">Nursery Type:</label>
              <select v-model="nurseryType" id="nurseryType" required
                class="bg-black text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-primary">
                <option value="rope">Rope</option>
                <option value="tree">Tree</option>
              </select>
            </div>
            <div class="flex flex-col">
              <label for="totalCorals" class="text-sm text-neutral-400 mb-1">Total Corals:</label>
              <input :value="formatNumber(totalCorals)" @input="updateTotalCorals" type="text" id="totalCorals" required
                class="bg-black text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-primary">
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4">
            <div class="flex flex-col">
              <label :for="structuresPerUnitLabel" class="text-sm text-neutral-400 mb-1">{{ structuresPerUnitLabel }}:</label>
              <input v-model.number="structuresPerUnit" type="number" :id="structuresPerUnitLabel" required
                class="bg-black text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-primary">
            </div>
            <div class="flex flex-col">
              <label :for="coralsPerStructureLabel" class="text-sm text-neutral-400 mb-1">{{ coralsPerStructureLabel }}:</label>
              <input v-model.number="coralsPerStructure" type="number" :id="coralsPerStructureLabel" required
                class="bg-black text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-primary">
            </div>
            <div class="flex flex-col">
              <label for="coralsPerMotherColony" class="text-sm text-neutral-400 mb-1">Corals per Mother Colony:</label>
              <input v-model.number="coralsPerMotherColony" type="number" id="coralsPerMotherColony" required
                class="bg-black text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-primary">
            </div>
          </div>
          <button type="submit"
            class="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark transition duration-300"
            @click="triggerAnimation">
            Generate Nursery Plan
          </button>
        </form>
      </div>
    </div>

    <div v-if="nurseryPlan.length" class="bg-black p-4 rounded-lg mb-4">
      <h2 class="text-2xl font-black font-lato mb-4">
        Structures
        <span class="rounded-full text-lg bg-white text-black px-2 ml-2">{{ nurseryPlan.length }}</span>
        <span class="ml-4">Mother Colonies</span>
        <span class="rounded-full text-lg bg-white text-black px-2 ml-2">{{ motherColoniesCount }}</span>
      </h2>
      <div class="structures-grid">
        <div v-for="(structure, index) in nurseryPlan" :key="index"
          class="bg-neutral-800 rounded-lg shadow-md text-white p-3 flex flex-col min-w-[200px] cursor-pointer hover:shadow-lg transition-shadow duration-300 relative">
          <h3 class="text-xl font-black font-lato mb-2">{{ unitLabel }} {{ index + 1 }}</h3>
          <div class="flex justify-between mt-1">
            <div class="w-[48%] bg-neutral-900 rounded-lg px-2 py-1">
              <p class="text-lg font-black font-lato text-white">{{ structure.structures }}</p>
              <p class="text-xs text-neutral-400">{{ nurseryType === 'rope' ? 'Ropes' : 'Trees' }}</p>
            </div>
            <div class="w-[48%] bg-neutral-900 rounded-lg px-2 py-1">
              <p class="text-lg font-black font-lato text-white">{{ formatNumber(structure.corals) }}</p>
              <p class="text-xs text-neutral-400">Corals</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Save and Cancel buttons -->
    <div class="flex justify-end mt-4">
      <button @click="cancel" type="button" class="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600 transition-colors duration-300">
        Cancel
      </button>
      <button @click="saveNursery" 
              class="bg-primary text-white px-4 py-2 rounded transition-colors duration-300"
              :class="{ 'opacity-50 cursor-not-allowed': !isFormValid, 'hover:bg-primary-dark': isFormValid }"
              :disabled="!isFormValid">
        Save
      </button>
    </div>

    <SparkleAnimation :width="cardWidth" :height="cardHeight" :active="isAnimating" />
  </div>
</template>

<script>
import SparkleAnimation from './utils/SparkleAnimation.vue';

export default {
  name: 'AddNursery',
  components: {
    SparkleAnimation
  },
  props: {
    branchId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      nurseryType: 'rope',
      coralsPerStructure: 30,
      structuresPerUnit: 12,
      totalCorals: 5400,
      coralsPerMotherColony: 12,
      nurseryPlan: [],
      isAnimating: false,
      cardWidth: 0,
      cardHeight: 0,
    }
  },
  computed: {
    coralsPerStructureLabel() {
      return this.nurseryType === 'rope' ? 'Corals per Rope' : 'Corals per Tree'
    },
    structuresPerUnitLabel() {
      return this.nurseryType === 'rope' ? 'Ropes per Table' : 'Trees per Unit'
    },
    unitLabel() {
      return this.nurseryType === 'rope' ? 'Structure' : 'Unit'
    },
    motherColoniesCount() {
      return Math.ceil(this.totalCorals / this.coralsPerMotherColony);
    },
    isFormValid() {
      return this.nurseryType && 
             this.coralsPerStructure > 0 && 
             this.structuresPerUnit > 0 && 
             this.totalCorals > 0 &&
             this.coralsPerMotherColony > 0 &&
             this.nurseryPlan.length > 0;
    }
  },
  methods: {
    formatNumber(value) {
      return value.toLocaleString();
    },
    updateTotalCorals(event) {
      // Remove non-digit characters and parse as integer
      this.totalCorals = parseInt(event.target.value.replace(/\D/g, '')) || 0;
    },
    generateNurseryPlan() {
      this.triggerAnimation().then(() => {
        const coralsPerUnit = this.coralsPerStructure * this.structuresPerUnit;
        const totalUnits = Math.ceil(this.totalCorals / coralsPerUnit);
        
        this.nurseryPlan = [];
        let remainingCorals = this.totalCorals;

        for (let i = 0; i < totalUnits; i++) {
          const unitCorals = Math.min(coralsPerUnit, remainingCorals);
          const unitStructures = Math.ceil(unitCorals / this.coralsPerStructure);
          
          this.nurseryPlan.push({
            structures: unitStructures,
            corals: unitCorals
          });

          remainingCorals -= unitCorals;
        }
      });
    },
    saveNursery() {
      if (this.isFormValid) {
        const nurseryData = {
          type: this.nurseryType,
          coralsPerStructure: this.coralsPerStructure,
          structuresPerUnit: this.structuresPerUnit,
          totalCorals: this.totalCorals,
          coralsPerMotherColony: this.coralsPerMotherColony,
          nurseryPlan: this.nurseryPlan,
          motherColoniesCount: this.motherColoniesCount
        };
        console.log(nurseryData);
      }
    },
    cancel() {
      this.resetNurseryDesigner();
    },
    resetNurseryDesigner() {
      this.nurseryType = 'rope';
      this.coralsPerStructure = 40;
      this.structuresPerUnit = 15;
      this.totalCorals = 10000;
      this.coralsPerMotherColony = 12;
      this.nurseryPlan = [];
    },
    triggerAnimation() {
      this.isAnimating = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          this.isAnimating = false;
          resolve();
        }, 1500); // Animation duration + some extra time
      });
    },
    updateCardDimensions() {
      const card = this.$el.querySelector('.bg-black');
      if (card) {
        this.cardWidth = card.offsetWidth;
        this.cardHeight = card.offsetHeight;
      }
    }
  },
  mounted() {
    this.updateCardDimensions();
    window.addEventListener('resize', this.updateCardDimensions);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCardDimensions);
  }
}
</script>

<style scoped>
.structures-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}
</style>